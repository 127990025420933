import React, { useState, useEffect, useContext } from 'react';
import { Rules } from 'components/rules/rules.component';
import { UserStateContext } from 'context/user-state-context';
import { makeStyles } from '@mui/styles';
import { getCheckFieldNodeMetadata } from 'shared/common.api';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import NearbyErrorOutlinedIcon from '@mui/icons-material/NearbyErrorOutlined';
import { Dialog, Grid, IconButton, Typography, DialogTitle, DialogContent, DialogActions, Button, InputLabel, MenuItem, Select } from '@mui/material';
const useStyles = makeStyles({
  checkField: {
    marginTop: '1.6rem',
    overflowX: 'auto',
    maxHeight: '300px',
    paddingTop: '10px'
  }
});
export const CustomerScope = {
  LIFETIME: 'lifetime',
  SESSION: 'session'
};

/** I just used the example from MUI's docs here,
 * since apparently customizing the inside of the tooltip (title)
 * is way more complicated than it should be and it defaults to grey
 * and I totally wasn't losing my mind trying to make it a white background */
const LightTooltip = styled(({
  className,
  ...props
}) => <Tooltip {...props} classes={{
  popper: className
}} />)(({
  theme
}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: '312px'
  }
}));

// TODO might need translation for survey_invite, get feedback
export const ConditionCheckField = ({
  data: {
    query,
    session
  },
  node,
  readOnly,
  resize,
  update
}) => {
  const {
    companyFields,
    flags
  } = useContext(UserStateContext);

  // component control
  const [sessionConfig, setSessionConfig] = useState(session);
  const [nodeMetaData, setNodeMetaData] = useState();
  const [fieldModels, setFieldModels] = useState([]);
  const [sessionTypes, setSessionTypes] = useState();
  const [selectedSessionType, setSelectedSessionType] = useState(session?.type ? session.type : '');

  // modal state
  const [openTriggerTileWarning, setOpenTriggerTileWarning] = useState(false);
  const [openSegmentWarning, setOpenSegmentWarning] = useState(false);
  const [openSessionTypeChoice, setOpenSessionTypeChoice] = useState(false);
  const [warned, setWarned] = useState(!!session);

  // styling
  const classes = useStyles();
  useEffect(() => {
    const retrieveNodeMetadata = async () => {
      if (nodeMetaData) {
        return nodeMetaData;
      }
      const response = await getCheckFieldNodeMetadata(node.id);
      setNodeMetaData(response.data);
      return response.data;
    };
    const configureNode = async () => {
      if (sessionConfig) {
        const metadata = await retrieveNodeMetadata();
        const metaSessionTypes = metadata.session.types;
        const metaOverview = metadata.session.overview;
        if (metaOverview.exists === false) {
          setOpenTriggerTileWarning(true);
          revertToLifetime();
          return;
        }
        if (metaOverview.misconfigured === true) {
          setOpenSegmentWarning(true);
          revertToLifetime();
          return;
        }
        setSessionTypes(metaSessionTypes);

        // This handles setting defaults on a newly introduced session type configuration
        if (!selectedSessionType) {
          const defaultType = Object.keys(metaSessionTypes)[0];
          setSelectedSessionType(defaultType);
          setFieldModels(filterSessionModels(metaSessionTypes[defaultType]));
          if (Object.keys(metaSessionTypes).length > 1) {
            setOpenSessionTypeChoice(true);
          }
          updateAndSetSessionConfig(defaultType);
        } else {
          setFieldModels(filterSessionModels(metaSessionTypes[selectedSessionType]));
        }
      } else {
        setFieldModels(filterLifetimeModels());
        setSessionTypes({});
      }
    };
    configureNode();
  }, [sessionConfig]);
  const revertToLifetime = () => {
    setSelectedSessionType('');
    setFieldModels(filterLifetimeModels());
    update({
      session: null
    });
    setSessionConfig(null);
  };

  /* If the user starts changing the scope, just
     default everything to null until they've actually
     selected a session */
  const changeScope = event => {
    const newScope = event.target.value;
    if (newScope === CustomerScope.SESSION) {
      if (!selectedSessionType) {
        updateAndSetSessionConfig(null);
      }
    } else {
      revertToLifetime();
    }
  };

  /* Changes the selected node that will be
     driving the models selected / session type */
  const handleSessionChange = event => {
    const sessionType = event.target.value;
    setFieldModels(filterSessionModels(sessionTypes[sessionType]));
    setSelectedSessionType(sessionType);
    updateAndSetSessionConfig(sessionType);
  };
  const updateAndSetSessionConfig = sessionType => {
    const newSessionConfig = {
      session: {
        type: sessionType
      }
    };
    setSessionConfig(newSessionConfig);
    update(newSessionConfig);
  };
  const filterSessionModels = triggerNodeModels => {
    return companyFields.filter(field => {
      if (!triggerNodeModels?.includes(field.id) || field.id === 'location') {
        return false;
      }
      return true;
    });
  };
  const filterLifetimeModels = () => {
    return companyFields.filter(field => field.id !== 'location');
  };
  const translateSessionTypeToHappyName = model => {
    if (model === 'survey_invite') {
      model = 'survey';
    }
    return model.charAt(0).toUpperCase() + model.slice(1);
  };
  return <>
      <div className={classes.checkField}>
        {flags.check_field_session && <Grid container>
            <Grid item>
              <FormControl sx={{
            marginLeft: '12px'
          }}>
                <RadioGroup sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }} value={sessionConfig ? CustomerScope.SESSION : CustomerScope.LIFETIME} onChange={changeScope}>
                  <FormControlLabel value={CustomerScope.LIFETIME} control={<Radio size="medium" />} label="Customer Lifetime" labelPlacement="end" />
                  <FormControlLabel value={CustomerScope.SESSION} control={<Radio size="medium" />} label="Customer Session" labelPlacement="end" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <LightTooltip title={<Grid container>
                    <Grid item>
                      <Typography variant="caption" sx={{
                fontWeight: 'bold'
              }}>
                        Lifetime vs Session
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        Selecting Customer Session will limit the customer fields that are available to check to only those applicable to
                        this journey.
                      </Typography>
                      <br />
                      <br />
                      <Typography variant="caption" sx={{
                fontStyle: 'italic'
              }}>
                        i.e. the specific transaction or schedule that triggered the customer to enter this journey
                      </Typography>
                    </Grid>
                  </Grid>} placement="top-start">
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </LightTooltip>
            </Grid>
            {sessionConfig && <Grid item style={{
          marginLeft: '20px'
        }}>
                <FormControl variant="outlined" sx={{
            minWidth: '300px'
          }}>
                  <InputLabel>Session Type</InputLabel>
                  <Select value={selectedSessionType} label="Choose Session Type" onChange={handleSessionChange}>
                    {sessionTypes && Object.keys(sessionTypes).map(type => {
                return <MenuItem key={type} value={type} label={translateSessionTypeToHappyName(type)}>
                            {translateSessionTypeToHappyName(type)}
                          </MenuItem>;
              })}
                  </Select>
                </FormControl>
              </Grid>}
          </Grid>}
        <Rules fieldModels={fieldModels} query={query} onChange={query => update({
        query
      })} />
      </div>
      <Dialog open={openTriggerTileWarning} PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} sx={{
      maxWidth: '312px',
      margin: 'auto'
    }}>
        <DialogTitle alignContent="center">
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <WarningAmberIcon sx={{
              color: 'red'
            }} />
            </Grid>
            <Grid item>
              <Typography variant="h4">Trigger tile required</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            A Trigger Tile is required to use Customer Session data. Please start your Journey with a Trigger Tile.
          </Typography>
        </DialogContent>
        <DialogActions alignContent="right">
          <Button sx={{
          textTransform: 'none'
        }} onClick={() => {
          setOpenTriggerTileWarning(false);
        }}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSegmentWarning} PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} sx={{
      maxWidth: '312px',
      margin: 'auto'
    }}>
        <DialogTitle alignContent="center">
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <WarningAmberIcon sx={{
              color: 'red'
            }} />
            </Grid>
            <Grid item>
              <Typography variant="h4">Segment Required</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            The "Time Trigger tiles" or "Segment trigger tile" requires a Segment to be selected to use Customer Session data. Please select
            a segment in the correct tile.
          </Typography>
        </DialogContent>
        <DialogActions alignContent="right">
          <Button sx={{
          textTransform: 'none'
        }} onClick={() => {
          setOpenSegmentWarning(false);
        }}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      {/** TODO expiriment with max with on these modals.  Figma claims 312px but it really doesn't look right in that configuration. */}
      {/** TODO Fix the icon so it's actually the figma one (if readily available) */}
      <Dialog open={openSessionTypeChoice && !warned} PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} sx={{
      maxWidth: '400px',
      margin: 'auto'
    }}>
        <DialogTitle alignContent="center">
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <NearbyErrorOutlinedIcon sx={{
              color: 'orange'
            }} />
            </Grid>
            <Grid item>
              <Typography variant="h4">Multiple session types</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Your journey contains multiple triggers and multiple session types. You will need to specify which session data type to check
            before creating any rules in this Check Data Field tile.
          </Typography>
        </DialogContent>
        <DialogActions alignContent="right">
          <Button sx={{
          textTransform: 'none'
        }} onClick={() => {
          setOpenSessionTypeChoice(false);
          setWarned(true);
        }}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>;
};