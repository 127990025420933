import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import 'rc-tooltip/assets/bootstrap_white.css';
import './styles/main.scss';
import Root from './root.component';
import CONSTANTS from 'shared/constants';
if (CONSTANTS.ENV != 'LOCAL') {
  {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: VERSION,
      integrations: [Sentry.replayIntegration({
        maskAllText: false
      })],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0,
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      beforeSend: event => {
        if (event?.extra?.__serialized__?.message === 'canceled') {
          // This is a weird unexplained error message.  I'm not sure what it means.
          // It might be bad to surpress it.  Let Chris know if you have any thoughts
          return null;
        }
        if (event.exception && window.CIErrorBoundary) {
          window.CIErrorBoundary(event);
        }
        return event;
      }
    });
  }
}
(function (apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w) (function (m) {
      o[m] = o[m] || function () {
        o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
      };
    })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
})(PENDO_API);

// may be unecessary
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function (registration) {
      // console.log('Firebase service worker registered:', registration);
      // You can now use Firebase messaging features.
    }).catch(function (error) {
      console.log('Error registering Firebase service worker:', error);
    });
  });
}
ReactDOM.render(<Root />, document.querySelector('#app'));