import React, { useEffect, useState, useContext } from 'react';
import validator from 'validator';
import { Button, ModalDialog, DynamicTypeAhead } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { getPhoneNumbers, spoofReply } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { v4 as uuid } from 'uuid';
export const ModalToolSMSReplier = ({
  open,
  onClose
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [message, setMessage] = useState('');
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const [loading, setLoading] = useState(false);
  const sendMessage = () => {
    setLoading(true);
    const SmsSid = 'SM' + uuid().replaceAll('-', '');
    const AccountSid = 'AC' + uuid().replaceAll('-', '');
    const body = {
      'ToCountry': null,
      'ToState': null,
      'SmsMessageSid': SmsSid,
      'NumMedia': '0',
      'ToCity': null,
      'FromZip': null,
      'SmsSid': SmsSid,
      'FromState': null,
      'SmsStatus': 'received',
      'FromCity': null,
      'Body': message,
      'FromCountry': null,
      'To': to.phone,
      'ToZip': null,
      'NumSegments': '1',
      // TODO we could convert this to actually count the number of segments from the body
      'MessagingServiceSid': to.service,
      'MessageSid': SmsSid,
      'AccountSid': AccountSid,
      'From': from,
      'ApiVersion': '2010-04-01'
    };
    spoofReply(body).then(() => {
      snackbarService.popup({
        type: 'success',
        message: `Message sent`
      });
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: `Unable to send message`
      });
    }).then(() => {
      setLoading(false);
    });
  };
  return <ModalDialog open={open} title="SMS Replier" cancelText="Close" onClose={onClose} allowBackdropClick type="scroll">
    <div className="w-100">
      <p> This tool provides an easy way for developers to spoof an incoming SMS message to a phone number.
      </p>
      <div className="modal__body">
        <div className="form-group">
          <label>From Phone Number</label>
          <input className="form-control" placeholder="Enter From Phone Number" onChange={e => setFrom(e.target.value)} value={from} />
          <small>use international format +1##########</small>
        </div>
        <div className="form-group">
          <label>To Phone Number</label>
          <DynamicTypeAhead getItems={getPhoneNumbers} getItemsFilters={{
            company: asCompany.id
          }} placeholder="Select a Phone Number" displayProperty="name" keyProperty="id" value={to?.id || null} onChange={to => setTo(to)} />
        </div>
        <div className="form-group">
          <label>Text message body</label>
          <textarea style={{
            height: '6em'
          }} className="form-control" placeholder="Enter Message" onChange={e => setMessage(e.target.value)} value={message} />
        </div>
        <div className="form-group">
          <Button disabled={loading} actionType="primary" onClick={sendMessage}>Send Message</Button>
        </div>
      </div>
    </div>
  </ModalDialog>;
};