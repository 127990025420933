import * as React from 'react';
const Icon = props => <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
    <g id="app icon">
      <g id="Group 969" fill="#fff">
        <path id="insert_chart" d="M1.8 17q-.75 0-1.275-.525A1.74 1.74 0 0 1 0 15.2V1.8Q0 1.05.525.525T1.8 0h13.4q.75 0 1.275.525T17 1.8v13.4q0 .75-.525 1.275T15.2 17zm0-1.5h13.4a.3.3 0 0 0 .213-.087.3.3 0 0 0 .087-.213V1.8a.3.3 0 0 0-.087-.212.3.3 0 0 0-.213-.088H1.8a.29.29 0 0 0-.212.088.29.29 0 0 0-.088.212v13.4q0 .125.088.213a.3.3 0 0 0 .212.087"></path>
        <path id="insert_chart_2" d="M6.672 0v17h-1.5V0z"></path>
        <path id="insert_chart_3" d="M11.844 0v17h-1.5V0z"></path>
        <path id="insert_chart_4" d="M17 6.672H0v-1.5h17z"></path>
        <path id="insert_chart_5" d="M17 11.844H0v-1.5h17z"></path>
      </g>
    </g>
  </svg>;
export default Icon;