import React, { Component } from 'react';
import { array, func, object, shape, string } from 'prop-types';
import { Button } from 'components/button/button.component';
import { Loader } from 'components/loader/loader.component';
import { toasterService } from 'components/toaster/toaster-service';
import { testBulkSQL } from '../../../../shared/common.api';
import { TableFields } from 'components/table-fields/table-fields.component';
import { UserStateContext } from 'context/user-state-context';
import { cloneDeep } from 'lodash';
export class ConditionWizard extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      query: string,
      name: string,
      description: string,
      fields: array,
      values: object,
      target_database: string
    })
  };
  state = {
    loading: false,
    isSuperUser: this.context.hasPermission('journey.manage_private_nodes')
  };
  static defaultProps = {
    query: '',
    name: 'Wizard Node',
    description: '',
    fields: [],
    values: {},
    target_database: 'postgres'
  };
  verifySql() {
    this.setState({
      loading: true
    });
    testBulkSQL(this.props.data.query, this.props.data.values, this.props.data.target_database).then(() => {
      toasterService.success('Query validated successfully');
    }).catch(e => {
      toasterService.error(e.response.data.sql);
    }).then(() => {
      this.setState({
        loading: false
      });
    });
  }
  updatePath(e, index) {
    const events = cloneDeep(this.props.data.events);
    events[index][e.target.name] = e.target.value;
    this.props.update({
      ...this.props.data,
      events
    });
  }
  removePath(index) {
    if (index > 0) {
      this.props.update({
        ...this.props.data,
        events: this.props.data.events.slice(0, index).concat(this.props.data.events.slice(index + 1))
      });
      return;
    }
  }
  addPath() {
    this.props.update({
      ...this.props.data,
      events: [...this.props.data.events, {
        id: '',
        name: ''
      }]
    });
  }
  render() {
    if (!this.props || !this.props.data) return null;
    const {
      query,
      name,
      description,
      values,
      fields,
      events = [{
        id: 'yes',
        name: 'Yes'
      }, {
        id: 'no',
        name: 'No'
      }],
      target_database
    } = this.props.data;
    const {
      loading,
      isSuperUser
    } = this.state;
    return <div>
        {isSuperUser && <div className="form-group">
            <label>Name</label>
            <input className="form-control" onChange={e => {
          this.props.update({
            name: e.target.value
          });
        }} value={name || ''} />
          </div>}
        <div className="form-group">
          {isSuperUser ? <>
              <label>Node Description</label>
              <textarea style={{
            height: '50px'
          }} className="form-control" onChange={e => {
            this.props.update({
              description: e.target.value
            });
          }} value={description || ''} />
            </> : <p>{description}</p>}
        </div>
        {isSuperUser && <>
            <div className="form-group">
              <label>Query</label>
              <textarea style={{
            height: '100px'
          }} className="form-control" onChange={e => {
            this.props.update({
              query: e.target.value
            });
          }} value={query || ''} />
            </div>

            <div className="form-group">
              <label>Target Database</label>
              <select required className="form-control mr-5" onChange={e => {
            this.props.update({
              target_database: e.target.value
            });
          }} value={target_database || ''}>
                <option value="postgres">Postgres</option>
                <option value="snowflake">Snowflake</option>
              </select>
            </div>

            <div className="form-group">
              <label>
                Paths
                <Button actionType="primary" className="ml-3" onClick={() => this.addPath()}>
                  Add Path
                </Button>
              </label>
              <table className="w-100">
                <thead>
                  <tr>
                    <th style={{
                  width: '15%'
                }}>ID</th>
                    <th style={{
                  width: '15%'
                }}>Label</th>
                    <th style={{
                  width: '40px'
                }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((path, index) => <tr key={`path-${index}`}>
                      <td className="pr-2">
                        <input className="form-control" value={path.id} name="id" onChange={e => this.updatePath(e, index)} />
                      </td>
                      <td className="pr-2">
                        <input className="form-control" name="name" value={path.name} onChange={e => this.updatePath(e, index)} />
                      </td>
                      <td>
                        <Button icon="fa-regular-times" small actionType="flat" onClick={() => this.removePath(index)} />
                      </td>
                    </tr>)}
                </tbody>
              </table>
            </div>
          </>}
        <div className="form-group">
          <TableFields isSuperUser={isSuperUser} fields={fields} values={values} onChange={(fields, values) => {
          this.props.update({
            fields,
            values
          });
        }} />
        </div>
        {isSuperUser && <div className="d-flex">
            <select required className="form-control mr-5" onChange={e => {
          this.props.update({
            query: `${query || ''}any(%(${e.target.value})s)`
          });
        }} value="">
              <option value="" disabled={true}>
                Insert Variable Data
              </option>
              <option value="customer_ids">Customer IDs</option>
              <option value="transaction_ids">Transaction IDs</option>
              <option value="possession_ids">Possession IDs</option>
              <option value="schedule_ids">Schedule IDs</option>
              <option value="cart_ids">Cart IDs</option>
              <option value="session_ids">Session IDs</option>
            </select>
            <Button actionType="primary" onClick={() => this.verifySql()} disabled={loading}>
              Validate Query
              {loading && <Loader className="ml-3" size="sm" />}
            </Button>
          </div>}
      </div>;
  }
}