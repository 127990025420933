/**
 * Might be useful as part of an api class, but for now,
 * this just helps us set default values for the POST request
 */
import dayjs from 'dayjs';
export class ManagedBroadcastRequestBuilder {
  company_id = null;
  children_companies = [];
  segment = null;
  email_template = null;
  drip_end = null;
  schedule_when = null;
  schedule_timezone = null;
  distinct_emails = null;
  google_campaign = null;
  window_start = null;
  window_end = null;
  window_days = [];
  setCompanyId = companyId => {
    this.company_id = companyId;
    return this;
  };

  /**
   * @param {String} child
   * @returns ManagedBroadcastRequestBuilder
   */
  addChildCompany = child => {
    this.children_companies.push(child);
    return this;
  };

  /**
   * @param {Array<String>} child
   * @returns
   */
  setChildrenCompanies = child => {
    this.children_companies = child;
    return this;
  };

  /**
   * @param {String} segment
   * @returns ManagedBroadcastRequestBuilder
   */
  setSegment = segment => {
    this.segment = segment;
    return this;
  };

  /**
   * @param {String} emailTemplate
   * returns ManagedBroadcastRequestBuilder
   */
  setEmailTemplate = emailTemplate => {
    this.email_template = emailTemplate;
    return this;
  };
  setDripEnd = dripEnd => {
    this.drip_end = dripEnd;
    return this;
  };

  /**
   * @param {String} scheduleWhen
   * @returns ManagedBroadcastRequestBuilder
   */
  setScheduleWhen = scheduleWhen => {
    this.schedule_when = scheduleWhen;
    return this;
  };

  /**
   * @param {String} scheduleTimezone
   * @returns
   */
  setScheduleTimezone = scheduleTimezone => {
    this.schedule_timezone = scheduleTimezone;
    return this;
  };

  /**
   * @param {Boolean} distinctEmails
   * @returns ManagedBroadcastRequestBuilder
   */
  setDistinctEmails = distinctEmails => {
    this.distinct_emails = distinctEmails;
    return this;
  };

  /**
   * @param {String} googleCampaign
   * @returns ManagedBroadcastRequestBuilder
   */
  setGoogleCampaign = googleCampaign => {
    this.google_campaign = googleCampaign;
    return this;
  };

  /**
   * @param {String} windowStart
   * @returns ManagedBroadcastRequestBuilder
   */
  setWindowStart = windowStart => {
    this.window_start = windowStart;
    return this;
  };

  /**
   * @param {String} windowEnd
   * @returns ManagedBroadcastRequestBuilder
   */
  setWindowEnd = windowEnd => {
    this.window_end = windowEnd;
    return this;
  };

  /**
   * @param {Number} windowDay
   * @returns ManagedBroadcastRequestBuilder
   */
  addWindowDay = windowDay => {
    this.window_days.push(windowDay);
    return this;
  };

  /**
   * @param {Array<Number>} windowDays
   * @returns
   */
  setWindowDays = windowDays => {
    this.window_days = windowDays;
    return this;
  };
  build() {
    const payload = {
      company: this.company_id,
      children_companies: this.children_companies,
      segment: this.segment,
      email_template: this.email_template,
      schedule_when: dayjs(this.schedule_when).format('YYYY-MM-DDTHH:mm:ss'),
      schedule_timezone: this.schedule_timezone,
      drip_end: this.drip_end,
      window_start: this.window_start,
      window_end: this.window_end,
      window_days: this.window_days,
      distinct_emails: this.distinct_emails,
      google_campaign: this.google_campaign
    };
    return payload;
  }
}