import React from 'react';
import { Modal, Typography, Stack, Box, IconButton, Divider, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { CinchActionButtonText } from '../buttons/cinch-action-button-text.component';
import { CloseIcon } from '../../../components/mui/icon';

/**
 * CinchModal component used to prevent user interaction with the application until a certain action is completed.
 *
 * @param {string} title - The title of the modal.
 *
 * @param {string} bodyText - The body of the modal.
 *
 * @param {array} actions - The actions of the modal.
 *
 * @param {function} onCancel - The action that the cancel button will perform on click.
 *
 * @param {function} onConfirm - The action that the confirm button will perform on click.
 *
 * @param {boolean} open - The open condition state of the component
 *
 * @param {boolean} hideBackdrop - The condition to hide the backdrop of the modal.
 *
 *
 */

export const CinchModal = props => {
  return <Modal BackdropProps={{
    onClick: e => e.stopPropagation(),
    sx: {
      backgroundColor: props.hideBackdrop ? 'rgba(0, 0, 0, 0)' : null
    }
  }} id={`${props.title}-cinch-modal`} open={props.open} onClose={props.onCancel} sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
      <Paper sx={{
      borderRadius: '28px',
      pt: '20px',
      px: '20px',
      pb: props?.bodyText?.length > 300 ? '20px' : null,
      width: '400px',
      height: 'auto',
      minHeight: '200px'
    }}>
        <Stack>
          {/* title */}
          <Stack direction={'row'} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pb: '10px'
        }}>
            <Box>
              <Typography id={`${props.title}-title-text`} variant="h5">
                {props.title || 'Confirmation Needed'}
              </Typography>
            </Box>
            <Box>
              <IconButton aria-label="close" id={`${props.title}-close-button`} onClick={props.onCancel}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
          <Box></Box>
          <Box sx={{
          pb: '20px'
        }}>
            <Divider sx={{
            mr: '-20px',
            ml: '-20px'
          }} />
          </Box>
          {/* body */}
          <Box sx={{
          pb: '20px'
        }}>
            <Typography id="body-text"> {props.bodyText} </Typography>
          </Box>
          <Box sx={{
          pb: '20px'
        }}>
            <Divider sx={{
            mr: '-20px',
            ml: '-20px'
          }} />
          </Box>
          {/* actions */}
          <Box id="actions-box-container" sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          pb: '15px'
        }}>
            {props.actions ? <Box id={`${props.title}-action-custom`}>{props.actions}</Box> : <Box>
                <CinchActionButtonText id={`${props.title}-action-cancel`} text="Cancel" onClick={props.onCancel} />
                <CinchActionButtonText id={`${props.title}-action-confirm`} text="Confirm" onClick={props.onConfirm} />
              </Box>}
          </Box>
        </Stack>
      </Paper>
    </Modal>;
};
CinchModal.propTypes = {
  /**
   * Visibility of the backdrop area around the modal.
   */
  hideBackdrop: PropTypes.bool,
  /**
   * The title of the modal.
   */
  title: PropTypes.string,
  /**
   * The body of the modal.
   */
  bodyText: PropTypes.string,
  /**
   * The override actions of the modal which will replace the confirm/cancel buttons.
   */
  actions: PropTypes.array,
  /**
   * The action that the cancel button will perform on click.
   */
  onCancel: PropTypes.func,
  /**
   * The action that the confirm button will perform on click.
   */
  onConfirm: PropTypes.func,
  /**
   * The open condition state of the component
   */
  open: PropTypes.bool.isRequired
};
CinchModal.defaultProps = {
  title: 'Confirmation Needed',
  actions: null
};