import { api } from './api';
import { buildFormData } from './utils';

/**
 * Status
 * --------------
 */
export function getStatus() {
  return api.get(`/noauth-status`);
}

/**
 * BRANDS
 * --------------
 */
export function getBrands(params) {
  return api.get(`/brands`, {
    params
  });
}

/**
 * CUSTOMERS
 * --------------
 */

export function getCustomers(params) {
  return api.get(`/customers`, {
    params
  });
}
export function getCustomersTotals(params) {
  return api.get(`/customers/totals`, {
    params
  });
}
export function getCustomer(id) {
  return api.get(`/customers/${id}`);
}
export function getCustomerDetails(id) {
  return api.get(`/customers/${id}/details`);
}
export function getCustomerMessages(id, params) {
  // We're appending an abort controller to the promise. This allows us to cancel the request.
  const controller = new AbortController();
  const promise = api.get(`/customers/${id}/messages`, {
    params,
    signal: controller.signal
  });
  promise.controller = controller;
  return promise;
}
export function sendCustomerMessage(id, data) {
  return api.post(`/customers/${id}/messages`, data);
}
export function getCustomerActions(id, params) {
  return api.get(`/customers/${id}/actions`, {
    params
  });
}
export function unsubscribeEmail(company, email, email_optstatus_id, status) {
  if (email_optstatus_id) {
    return api.patch(`/unsubscribes/${email_optstatus_id}`, {
      status
    });
  }
  return api.post(`/unsubscribes`, {
    company,
    email,
    status
  });
}
export function updateSMSStatus(company, phone, sms_optstatus_id, status) {
  if (sms_optstatus_id) {
    return api.patch(`/sms-optstatuses/${sms_optstatus_id}`, {
      status
    });
  }
  return api.post(`/sms-optstatuses`, {
    company,
    phone,
    status
  });
}
export function getOptStatusSMS(params) {
  return api.get(`/sms-optstatuses`, {
    params
  });
}
export function unsubscribeEmailDelete(unsubscribe_id) {
  return api.delete(`/unsubscribes/${unsubscribe_id}`);
}
export function getCustomerRefGroups(params) {
  return api.get(`/customer-ref-groups`, {
    params
  });
}
export function createCustomerRefGroups(data) {
  return api.post(`/customer-ref-groups`, data);
}
export function editCustomerRefGroup(id, data) {
  return api.patch(`customer-ref-groups/${id}`, data);
}
export function editCustomer(id, data) {
  return api.patch(`/customers/${id}`, data);
}
export function getCustomerScheduleTypes(id, params) {
  return api.get(`/customers/${id}/schedules`, {
    params
  });
}
export function createNewCustomerRef(data) {
  return api.post(`/customer-refs`, data);
}
export function getCustomerRef(params) {
  return api.get(`/customer-refs`, {
    params
  });
}
export function deleteCustomerRefsVehicle(id) {
  return api.delete(`/customer-refs/vehicles/${id}`);
}
export function getCustomerSubscriptions(id, params) {
  return api.get(`/customers/${id}/subscriptions`, {
    params
  });
}
export function getCustomerRewards(id, params) {
  return api.get(`/customers/${id}/rewards`, {
    params
  });
}

/**
 * NAICS
 * --------------
 */
export function getNAICS(params) {
  return api.get(`/naics`, {
    params
  });
}

/**
 * USERS
 * --------------
 */
export function getUsers(params) {
  return api.get(`/users`, {
    params
  });
}
export function getUser(id, params) {
  return api.get(`/users/${id}`, {
    params
  });
}
export function updateUser(id, data) {
  return api.patch(`/users/${id}`, data);
}
export function inviteUser(data) {
  return api.post(`/users/invite`, data);
}
export function editUser(id, body) {
  return api.patch(`/users/${id}`, body);
}
export function getUserLocations(params) {
  return api.get(`/users/locations`, {
    params
  });
}
export function replaceUserToLocation(data) {
  return api.post(`/users/to_location/bulk-replace`, data);
}
export function replaceUserToMarket(data) {
  return api.post(`/users/to_market/bulk-replace`, data);
}

/**
 * NotificationContact (Notificaiton Lists)
 */
export function getNotificationContact(id, params) {
  return api.get(`/notification_contacts/${id}`, {
    params
  });
}
export function getNotificationContacts(params) {
  return api.get(`/notification_contacts`, {
    params
  });
}
export function createNotificationContact(data) {
  return api.post(`/notification_contacts`, data);
}
export function updateNotificationContact(id, data) {
  return api.patch(`/notification_contacts/${id}`, data);
}
export function deleteNotificationContact(id) {
  return api.delete(`/notification_contacts/${id}`);
}

/*
NotificationContactScope (a subset of objects under NotificationContact)
*/
export function getNotificationContactScope(contactId, id, params) {
  return api.get(`/notification_contacts/${contactId}/scope/${id}`, {
    params
  });
}
export function getNotificationContactScopes(contactId, params) {
  return api.get(`/notification_contacts/${contactId}/scope`, {
    params
  });
}

// TODO struggled to get django to play nice with using the contactId in the URL so contactId
// must be passed into the URL as well as the request body, sorry.
export function createNotificationContactScope(contactId, data) {
  return api.post(`/notification_contacts/${contactId}/scope`, data);
}
export function updateNotificationContactScope(contactId, id, data) {
  return api.patch(`/notification_contacts/${contactId}/scope/${id}`, data);
}
export function deleteNotificationContactScope(contactId, id) {
  return api.delete(`/notification_contacts/${contactId}/scope/${id}`);
}

/**
 * ANALYTICS
 * --------------
 */

export function getAnalytics(params) {
  return api.get(`/analytics/views`, {
    params: {
      ordering: 'display_name',
      ...params
    }
  });
}

/**
 * COMPANIES
 * --------------
 */
export function getCompanies(params) {
  return api.get(`/companies`, {
    params: {
      ordering: 'name',
      ...params
    }
  });
}
export function getCompany(id, params) {
  return api.get(`/companies/${id}`, {
    params
  });
}
export function getCompanyFields(id) {
  return api.get(`/companies/${id}/fields`);
}
export function getCompanyFlags(id) {
  return api.get(`/companies/${id}/flags`);
}
export function getCompanyPhoneCount(id) {
  return api.get(`/companies/${id}/phone_count`);
}
export function refreshAllCompanyFields(id) {
  return api.post(`/companies/${id}/refresh_fields`);
}
export function getAllCompanyFields(id) {
  return api.get(`/companies/${id}/all_fields`);
}
export function postCompany(data) {
  return api.post('/companies', data);
}
export function patchCompany(id, data) {
  return api.patch(`/companies/${id}`, data);
}
export function offboardCompany(id) {
  return api.delete(`/companies/${id}`);
}
export function getCompanyFiles(id) {
  return api.get(`/company-files/${id}/files`);
}
export function createCompanyFilesFolder(id, folder) {
  return api.post(`/company-files/${id}/folder`, {
    folder
  });
}
export function deleteCompanyFile(id, key) {
  return api.delete(`/company-files/${id}/key?key=${encodeURIComponent(key)}`);
}
export function renameCompanyFile(id, old_key, new_key) {
  return api.post(`/company-files/${id}/rename`, {
    old_key,
    new_key
  });
}
export function downloadCompanyFile(id, key) {
  return api.get(`/company-files/download/${id}?key=${encodeURIComponent(key)}`);
}
export function checkCompanyFields(id, data) {
  return api.post(`/companies/${id}/field_check`, data);
}
export function getCompanyStatuses(params) {
  return api.get(`/companies/statuses`, {
    params
  });
}
export function getCompanyCancelReasons(params) {
  return api.get(`/companies/cancel-reasons`, {
    params
  });
}
export function getAccounts(params) {
  return api.get(`/accounts`, {
    params
  });
}
export function duplicateCompany(data, id) {
  return api.post(`/companies/${id}/duplicate`, data);
}
export function getApiKeys(companyId) {
  return api.get(`companies/${companyId}/api-keys`);
}
export function createApiKey(companyId, data) {
  return api.post(`companies/${companyId}/api-keys`, data);
}
export function deleteApiKey(companyId, apiKeyId) {
  return api.delete(`companies/${companyId}/api-keys/${apiKeyId}`);
}
export function getCompanyDataDict(companyId, model, field) {
  return api.get(`companies/${companyId}/data-dicts/${model}/${field}`);
}

/**
 * JOURNEYS
 * --------------
 */
export function getJourneys(params) {
  return api.get(`/journeys`, {
    params
  });
}
export function getJourneyTemplates(params) {
  return api.get(`/journeys/templates`, {
    params
  });
}
export function getJourney(journeyId) {
  return api.get(`/journeys/${journeyId}`);
}
export function getJourneyRevisions(journeyId, params) {
  return api.get(`/journeys/${journeyId}/revisions`, {
    params
  });
}
export function getJourneyNodes(revisionId) {
  // We're appending an abort controller to the promise. This allows us to cancel the request.
  const controller = new AbortController();
  const promise = api.get(`/journeys/revisions/${revisionId}`, {
    signal: controller.signal
  });
  promise.controller = controller;
  return promise;
}
export function getNodeTemplates(params) {
  return api.get(`/journeys/nodes/templates`, {
    params
  });
}
export function createNodeTemplate(data) {
  return api.post(`/journeys/nodes/templates`, data);
}
export function getJourneysTags(companyId) {
  return api.get(`/journeys/tags/${companyId}`);
}
export function getJourneyReasons(companyId) {
  return api.get(`/journeys/reasons/${companyId}`);
}
export function getJourneyEndStats(journeyId) {
  return api.get(`/journeys/${journeyId}/end-stats`);
}
export function getJourneySummary(journeyId, params) {
  return api.get(`/journeys/${journeyId}/summary`, {
    params
  });
}
export function createJourney(journey) {
  return api.post(`/journeys`, journey);
}
export function createJourneyFromTemplate(templateId, companyId) {
  return api.post(`/journeys/from_template/${templateId}/to_company/${companyId}`);
}
export function deleteJourney(journeyId) {
  return api.delete(`/journeys/${journeyId}`);
}
export function patchJourney(journeyId, body) {
  return api.patch(`/journeys/${journeyId}`, body);
}
export function patchRevision(revisionId, body) {
  return api.patch(`/journeys/revisions/${revisionId}`, body);
}
export function deleteRevision(revisionId) {
  return api.delete(`/journeys/revisions/${revisionId}`);
}
export function getRevisionAnalytics(revisionId, params) {
  // We're appending an abort controller to the promise.  We may want to always do this but I'm not sure.
  const controller = new AbortController();
  const promise = api.get(`/journeys/revisions/${revisionId}/analytics`, {
    params,
    signal: controller.signal
  });
  promise.controller = controller;
  return promise;
}
export function duplicateJourney(journeyId, company_ids) {
  return api.post(`/journeys/${journeyId}/duplicate`, {
    company_ids
  });
}
export function revertJourney(journeyId) {
  return api.post(`/journeys/${journeyId}/revert`);
}
export function getJourneyTestRuns(params) {
  return api.get(`/journeys/test-runs`, {
    params
  });
}
export function getJourneyTestRun(id) {
  return api.get(`/journeys/test-runs/${id}`);
}
export function createJourneyTestRun(body) {
  return api.post(`/journeys/test-runs`, body);
}

/**
 * NODES
 * --------------
 */
export function createNode(node_type, name, template, x, y, revision, parameters) {
  return api.post(`/journeys/nodes`, {
    node_type,
    name,
    template,
    x,
    y,
    revision,
    parameters
  });
}
export function removeNode(nodeId) {
  return api.delete(`/journeys/nodes/${nodeId}`);
}
export function updateNode(nodeId, body) {
  return api.patch(`/journeys/nodes/${nodeId}`, body);
}
export function testSQL(sql) {
  return api.post(`/journeys/nodes/test-sql`, {
    sql
  });
}
export function testBulkSQL(sql, values, target_database) {
  return api.post(`/journeys/nodes/test-bulk-sql`, {
    sql,
    values,
    target_database
  });
}
export function testWebhookSQL(sql, values) {
  return api.post(`/journeys/nodes/test-webhook-sql`, {
    sql,
    values
  });
}
export function getNodeCustomers(nodeId, params) {
  return api.get(`/journeys/nodes/${nodeId}/customers`, {
    params
  });
}
export function getNodeCustomersSQL(nodeId, params) {
  return api.get(`/journeys/nodes/${nodeId}/customersSQL`, {
    params
  });
}
export function createNodeCustomersSegment(nodeId, params) {
  return api.post(`/journeys/nodes/${nodeId}/to-segment`, {
    params
  });
}
export function getCheckFieldNodeMetadata(nodeId) {
  return api.get(`/journeys/nodes/${nodeId}/check-field/metadata`);
}
export function getJourneyTimeline(params) {
  return api.get(`/journeys/timeline`, {
    params
  });
}
export function getSegmentNodes(params) {
  return api.get(`/journeys/segment-nodes`, {
    params
  });
}

/**
 * JOURNEY LINKS
 * --------------
 */
export function createLink(from_node, to_node, on_event) {
  return api.post(`/journeys/links`, {
    from_node,
    to_node,
    on_event
  });
}
export function updateLink(id, body) {
  return api.patch(`/journeys/links/${id}`, body);
}
export function removeLink(linkId) {
  return api.delete(`/journeys/links/${linkId}`);
}

/**
 * SEGMENTS
 * --------------
 */

export function createCustomerSegment(customerSegment) {
  return api.post('/customer-segments', customerSegment);
}
export function deleteCustomerSegment(id) {
  return api.delete('/customer-segments/' + id);
}
export function segmentConvertToQuery(params) {
  return api.post(`/segments/convert-query`, params);
}
export function getSegments(params) {
  return api.get(`/segments`, {
    params: {
      archived: false,
      ordering: 'name',
      ...params
    }
  });
}
export function getSegment(id, params) {
  return api.get(`/segments/${id}`, {
    params
  });
}
export function getSegmentCustomers(id, params) {
  return api.get(`/segments/${id}/customers`, {
    params
  });
}
export function getSegmentExportCustomers(id, selectedFields) {
  return api.post(`/segments/${id}/customers/export`, {
    selectedFields
  });
}

/**
 * Duplicate Segment
 *
 * Create a new segment using this segment as the source
 *
 * @param {str} id           Segment to use for duplicating
 * @param {list} company_ids Optional. Target companies to put new segment in.
 * @returns Promise
 */
export function duplicateSegment(id, company_ids) {
  return api.post(`/segments/${id}/duplicate`, {
    company_ids
  });
}
export function getSegmentCount(id, params) {
  // We're appending an aprt controller to the promise.  We may want to always do this but I'm not sure.
  const controller = new AbortController();
  const promise = api.get(`/segments/${id}/counts`, {
    params,
    signal: controller.signal
  });
  promise.controller = controller;
  return promise;
}
export function newSegment(segment) {
  return api.post('/segments', segment);
}
export function editSegment(id, segment) {
  return api.patch(`/segments/${id}`, segment);
}
export function copySegment(segment) {
  return api.post('/segments', segment);
}
export function deleteSegment(id) {
  return api.delete(`/segments/${id}`);
}
export function getSegmentExport(id) {
  return api.get(`/segments/${id}/customers.csv`, {
    responseType: 'blob'
  });
}
export function createSegmentFromTemplate(segmentId, companyId) {
  return api.post(`/segments/from-template/${segmentId}/to-company/${companyId}`);
}
export function testSegment(segment) {
  return api.post(`/segments/test-count`, segment);
}
export function getSegmentsTags(companyId) {
  return api.get(`/segments/tags/${companyId}`);
}
export function getSegmentCreatedByUsers(companyId) {
  return api.get(`/segments/created-by/${companyId}`);
}
export function runCustomPicklistSegmentQuery(query) {
  return api.post(`/segments/picklistSQL`, query);
}

/**
 * SEGMENT TEMPLATES
 * --------------
 */
export function getSegmentTemplates(params) {
  return api.get(`/segment-templates`, {
    params
  });
}

/**
 * LOCATIONS
 * --------------
 */
export function getLocations(params) {
  return api.get(`/locations`, {
    params
  });
}
export function getLocation(id, params) {
  return api.get(`/locations/${id}`, {
    params
  });
}
export function editLocation(id, data) {
  return api.patch(`/locations/${id}`, data);
}
export function getTransactionStats(companyId) {
  return api.get(`locations/transactions/stats?company=${companyId}`);
}
export function exportLocationToCSV(data, params) {
  return api.post(`/locations/export`, data, {
    params
  });
}
export function getLocationsExport(params) {
  return api.get(`/locations.csv`, {
    params
  });
}
export function importLocationsCSV(data) {
  return api.post(`/locations/import`, data);
}
export function bulkUpdateLocations(data) {
  return api.patch(`/locations/bulk`, data);
}

/**
 * LOCATION_REFS
 * --------------
 */
export function bulkUpsertLocationRefs(data) {
  return api.patch(`/location_refs/bulk`, data);
}
export function getLocationRefs(params) {
  return api.get(`/location_refs`, {
    params
  });
}
export function getLocationRef(id, params) {
  return api.get(`/location_refs/${id}`, {
    params
  });
}
export function editLocationRef(id, data) {
  return api.patch(`/location_refs/${id}`, data);
}

/**
 * MARKETS
 * --------------
 */
export function getMarkets(params) {
  return api.get(`/markets`, {
    params
  });
}
export function getMarket(id, params) {
  return api.get(`/markets/${id}`, {
    params
  });
}
export function createMarket(params) {
  return api.post('/markets', params);
}
export function updateMarket(id, params) {
  return api.patch(`/markets/${id}`, params);
}
export function deleteMarket(id) {
  return api.delete(`/markets/${id}`);
}

/**
 * COUPONS
 * --------------
 */
export function getCoupons(params) {
  return api.get(`/coupons`, {
    params
  });
}
export function getCoupon(id, params) {
  return api.get(`/coupons/${id}`, {
    params
  });
}
export function newCoupon(data) {
  return api.post('/coupons', data);
}
export function getCouponBatches(couponId) {
  return api.get(`/coupons/${couponId}/batches`);
}
export function downloadCouponBatch(couponId, batchNumber) {
  return api.get(`/coupons/${couponId}/download-batch/${batchNumber}`);
}
export function createCoupon(data) {
  return api.post('/coupons', data);
}
export function createCouponBatch(id, data) {
  return api.post(`/coupons/${id}/generate-batch`, data);
}
export function editCoupon(id, data) {
  return api.patch(`/coupons/${id}`, data);
}
export function copyCoupon(body) {
  return api.post('/coupons', buildFormData(body), {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
export function deleteCoupon(id) {
  return api.delete(`/coupons/${id}`);
}

/**
 * ROLES
 * --------------
 */
export function getRoles(params) {
  return api.get(`/roles`, {
    params
  });
}
export function getRole(id) {
  return api.get(`/roles/${id}`);
}
export function updateRole(id, role) {
  return api.patch(`/roles/${id}`, role);
}
export function createRole(role) {
  return api.post(`/roles`, role);
}
export function deleteRole(id) {
  return api.delete(`/roles/${id}`);
}

/**
 * USER COMPANIES
 * --------------
 */
export function getUserCompanies(id, params) {
  return api.get(`/users/${id}/companies`, {
    id,
    params
  });
}
export function deleteUserCompany(id) {
  return api.delete(`/users/companies/${id}`);
}
export function updateUserCompany(id, data) {
  return api.patch(`/users/companies/${id}`, data);
}
export function createUserCompany(data) {
  return api.post(`/users/companies`, data);
}

/**
 * TEMPLATES
 * --------------
 */

//CLEAN THIS AVOID HARD CODE PARAMS
export function getTemplates(params) {
  return api.get(`/templates`, {
    params: {
      ordering: 'name',
      ...params
    }
  });
}
//CLEAN THIS AVOID HARD CODE PARAMS
export function getEmailTemplates(params) {
  return api.get(`templates/emails`, {
    params: {
      ordering: 'name',
      ...params
    }
  });
}
//CLEAN THIS AVOID HARD CODE PARAMS
export function getPrintTemplates(params) {
  return api.get(`templates/prints`, {
    params: {
      ordering: 'name',
      ...params
    }
  });
}
export function getTemplatesTags(companyId) {
  return api.get(`/templates/tags/${companyId}`);
}
export function getSmsTemplates(params) {
  return api.get(`templates/sms`, {
    params: {
      ordering: 'name',
      ...params
    }
  });
}
export function getTemplateCreatedByUsers(companyId) {
  return api.get(`/templates/created-by/${companyId}`);
}

/**
 * TEMPLATES - EMAILS
 * --------------
 */

export function getEmail(id) {
  return api.get(`templates/emails/${id}`);
}
export function postEmail(body) {
  return api.post(`/templates/emails`, body);
}
export function getEmailRevision(id) {
  return api.get(`templates/email-revisions/${id}`);
}
export function createEmailRevision(body) {
  return api.post(`/templates/email-revisions`, body);
}
export function updateEmailRevision(id, body) {
  return api.patch(`/templates/email-revisions/${id}`, body);
}
export function patchEmail(id, body) {
  return api.patch(`/templates/emails/${id}`, body);
}
export function deleteEmail(id) {
  return api.delete(`/templates/emails/${id}`);
}
export function bulkUpdateEmailNameAddress(email, name, company_id) {
  return api.post(`/templates/emails/bulk-update-name-address`, {
    email,
    name,
    company_id
  });
}
export function getEmailRevisionLogs(params) {
  return api.get(`/email-revision-logs`, {
    params
  });
}

/**
 * Duplicate Email
 *
 * Create a new email using this email as the source
 *
 * @param {str} id           Email to use for duplicating
 * @param {list} company_ids Optional. Target companies to put new email in.
 * @returns Promise
 */
export function duplicateEmail(id, company_ids) {
  return api.post(`/templates/emails/${id}/duplicate`, {
    company_ids
  });
}
export function createEmailFromTemplate(templateId, companyId) {
  return api.post(`/templates/emails/from-template/${templateId}/to-company/${companyId}`);
}

/**
 * TEMPLATES - EMAIL TEMPLATES
 * --------------
 */
export function getEmailTemplateTemplates(params) {
  return api.get(`/email-templates`, {
    params: {
      ordering: 'email__name',
      ...params
    }
  });
}
export function getPremadeBlocksTemplate(params) {
  return api.get(`/email-blocks/get`, {
    params
  });
}
export function createPremadeBlocksTemplate(block) {
  return api.post(`/email-blocks`, block);
}
export function updatePremadeBlocksTemplate(id, savedBlock) {
  return api.patch(`/email-blocks/${id}`, savedBlock);
}
export function deletePremadeBlocksTemplate(id, savedBlock) {
  return api.delete(`/email-blocks/${id}`, savedBlock);
}

/**
 * TEMPLATES - MAILS
 * --------------
 */
export function getMail(id) {
  return api.get(`templates/prints/${id}`);
}
export function postPrints(body) {
  return api.post(`/templates/prints`, body);
}
export function patchPrints(id, body, params) {
  return api.patch(`/templates/prints/${id}`, body, params);
}
export function getPrintRevision(id) {
  return api.get(`templates/print-revisions/${id}`);
}
export function createPrintRevision(body) {
  return api.post(`/templates/print-revisions`, body);
}
export function createPrintRevisionFromDraft(id) {
  return api.post(`/templates/print-revisions/${id}/duplicate`);
}
export function updatePrintRevision(id, body) {
  return api.patch(`/templates/print-revisions/${id}`, body);
}
export function deleteMail(id) {
  return api.delete(`/templates/prints/${id}`);
}
export function duplicateMail(id, company_ids) {
  return api.post(`/templates/prints/${id}/duplicate`, {
    company_ids
  });
}
export function createPrintFromTemplate(templateId, companyId) {
  return api.post(`/templates/prints/from-template/${templateId}/to-company/${companyId}`);
}
export function getMailBoxDetails(id) {
  return api.get(`mail/details/${id}`);
}
export function populatePreviewData(id, params) {
  return api.get(`/templates/print-revisions/${id}/preview`, {
    params
  });
}

/**
 * TEMPLATES - PRINT TEMPLATES
 * --------------
 */
export function getPrintTemplateTemplates(params) {
  return api.get(`/print-templates`, {
    params: {
      ordering: 'name',
      ...params
    }
  });
}

/**
 * TEMPLATES - SMS
 * --------------
 */

export function getSms(id) {
  return api.get(`templates/sms/${id}`);
}
export function postSms(body) {
  return api.post(`/templates/sms`, body);
}
export function getSmsRevision(id) {
  return api.get(`templates/sms-revisions/${id}`);
}
export function createSmsRevision(body) {
  return api.post(`/templates/sms-revisions`, body);
}
export function updateSmsRevision(id, body) {
  return api.patch(`/templates/sms-revisions/${id}`, body);
}
export function patchSms(id, body) {
  return api.patch(`/templates/sms/${id}`, body);
}
export function deleteSms(id) {
  return api.delete(`/templates/sms/${id}`);
}
export function duplicateSms(id, company_ids) {
  return api.post(`/templates/sms/${id}/duplicate`, {
    company_ids
  });
}
export function getSmsTemplateTemplates(params) {
  return api.get(`/sms-templates`, {
    params: {
      ordering: 'sms__name',
      ...params
    }
  });
}
export function sendTestSmsMessage(data) {
  return api.post(`/templates/sms-revisions/test-sms`, data);
}
export function generatePreview(data) {
  return api.post(`/templates/sms-revisions/generate-preview`, data);
}
export function createSmsFromTemplate(templateId, companyId) {
  return api.post(`/templates/sms/from-template/${templateId}/to-company/${companyId}`);
}
/**
 * CHARGEBEE
 */
export function getChargeBeeCheckoutURL(companyId) {
  return api.get(`/chargebee/hosted/${companyId}/url`);
}
export function getChargeBeeSubscriptionURL(companyId) {
  return api.get(`/chargebee/hosted/${companyId}/portal`);
}
export function createChargeBeeSubscription(data) {
  return api.post(`/chargebee/subscription`, data);
}
export function getChargeBeeSubscription(company) {
  return api.get('/chargebee/subscription', {
    params: {
      company
    }
  });
}
export function getChargeBeeAddons(company) {
  return api.get(`/chargebee/company/${company}/addons`);
}
export function getChargeBeePlan(company) {
  return api.get(`/chargebee/company/${company}/plan`);
}

/**
 * MANAGED EMAIL BROADCASTS
 *
 * Where applicable, these endpoints leverage the
 * ManagedBroadcastRequestBuilder to construct the params
 */
export function getManagedBroadcasts(params) {
  return api.get(`/broadcasts/managed`, {
    params
  });
}
export function createManagedBroadcast(requestBody) {
  return api.post(`/broadcasts/managed`, requestBody);
}
export function deleteManagedBroadcast(id) {
  return api.delete(`/broadcasts/managed/${id}`);
}
export function updateManagedBroadcast(id, requestBody) {
  return api.patch(`/broadcasts/managed/${id}`, requestBody);
}

/*
 * EMAIL BROADCAST
 */
export function getBroadcasts(params) {
  return api.get(`/broadcasts`, {
    params
  });
}
export function createBroadcast(body) {
  return api.post(`/broadcasts`, body);
}
export function updateBroadcast(id, body) {
  return api.patch(`/broadcasts/${id}`, body);
}
export function deleteBroadcast(id) {
  return api.delete(`/broadcasts/${id}`);
}

/*
 * SMS BROADCAST
 */
export function getSmsBroadcasts(params) {
  return api.get(`/communication/sms/broadcasts`, {
    params
  });
}
export function createSmsBroadcast(body) {
  return api.post(`/communication/sms/broadcasts`, body);
}
export function updateSmsBroadcast(id, body) {
  return api.patch(`/communication/sms/broadcasts/${id}`, body);
}
export function deleteSmsBroadcast(id) {
  return api.delete(`/communication/sms/broadcasts/${id}`);
}

/**
 * SMS
 */
export function getPhoneNumbers(params) {
  return api.get(`/communication/phone-number`, {
    params
  });
}
export function postHostNumber(data) {
  return api.post(`/communication/phone-number/host`, data);
}
export function getAvailablePhoneNumbers(params) {
  return api.get(`/communication/phone-number/available`, {
    params
  });
}
export function refreshPhoneRegistration(company) {
  return api.get(`/communication/phone-number/refresh`, {
    params: {
      company
    }
  });
}
export function getPhoneRegistration(company) {
  return api.get(`/communication/phone-number/registration`, {
    params: {
      company
    }
  });
}
export function updateBusinessInformation(data) {
  return api.post(`/communication/phone-number/business_information`, data);
}
export function getPossibleUseCases(company) {
  return api.get(`/communication/phone-number/use_cases`, {
    params: {
      company
    }
  });
}
export function createMessageCampaign(data) {
  return api.post(`/communication/phone-number/campaign`, data);
}
export function updateBusinessAddress(data) {
  return api.post(`/communication/phone-number/business_address`, data);
}
export function updateAuthorizedRepresentative(data) {
  return api.post(`/communication/phone-number/authorized_representative`, data);
}
export function updateA2PBusinessInformation(data) {
  return api.post(`/communication/phone-number/a2p_business_information`, data);
}
export function verifyCustomerProfile(company) {
  return api.get(`/communication/phone-number/verify`, {
    params: {
      company
    }
  });
}
export function submitCustomerProfile(company) {
  return api.post(`/communication/phone-number/submit`, {
    company
  });
}
export function submitBrand(company) {
  return api.post(`/communication/phone-number/submit_brand`, {
    company
  });
}
export function verifyA2P(company) {
  return api.get(`/communication/phone-number/verify_a2p`, {
    params: {
      company
    }
  });
}
export function submitA2P(company) {
  return api.post(`/communication/phone-number/submit_a2p`, {
    company
  });
}
export function updatePhoneNumber(id, body) {
  return api.patch(`/communication/phone-number/${id}`, body);
}
export function deletePhoneNumber(id) {
  return api.delete(`/communication/phone-number/${id}`);
}
export function purchasePhoneNumber(data) {
  return api.post(`/communication/phone-number/purchase`, data);
}
export function getMessaging(params) {
  // We're appending an abort controller to the promise. This allows us to cancel the request.
  const controller = new AbortController();
  const promise = api.get(`/communication/sms`, {
    params,
    signal: controller.signal
  });
  promise.controller = controller;
  return promise;
}
export function spoofReply(data) {
  return api.post(`/communication/sms/twilio-reply`, data);
}
export function getSMSLog(id, params) {
  return api.get(`/communication/sms/${id}/timeline`, {
    params
  });
}
export function registerDeviceToFirebase(data) {
  return api.post(`/fcm`, data);
}

/**
 * DOMAINS
 */
export function getDomains(params) {
  return api.get(`/email/domains`, {
    params
  });
}
export function createDomains(body) {
  return api.post(`/email/domains`, body);
}
export function verifyDomain(id) {
  return api.post(`/email/domains/${id}/verify`);
}
export function deleteDomain(id) {
  return api.delete(`/email/domains/${id}`);
}

/**
 * EMAIL ADDRESSES
 */
export function getAddresses(params) {
  return api.get(`/email/addresses`, {
    params
  });
}
export function getAddress(id) {
  return api.get(`/email/addresses/${id}`);
}
export function createAddress(body) {
  return api.post(`/email/addresses`, body);
}
export function verifyAddress(id, hash) {
  return api.post(`/email/addresses/${id}/verify/${hash}`);
}
export function resendVerificationEmail(id) {
  return api.post(`/email/addresses/${id}/resend`);
}
export function rejectAddress(id) {
  return api.post(`/email/addresses/${id}/reject`);
}
export function deleteAddress(id) {
  return api.delete(`/email/addresses/${id}`);
}

// EMAIL METRICS

export function getEmailMetrics(node_id, days) {
  return api.get(`/email/journeys/metrics?node_id=${node_id}${days ? `&days=${days}` : ''}`);
}
export function getEmailLog(id) {
  return api.get(`/email/logs/${id}`);
}

/**
 * CSV Imports
 */

export function getImports(params) {
  return api.get(`/integration/csvimport`, {
    params
  });
}
export function getCSVImportSources(params) {
  return api.get(`/integration/csvimport/sources`, {
    params
  });
}
export function importCSV(data) {
  return api.post(`/integration/csvimport`, data);
}
export function previewCSV(data) {
  return api.post('/integration/csvimport/preview', data);
}
export function deleteImport(id) {
  return api.delete(`/integration/csvimport/${id}`);
}
export function stopImport(id, data) {
  return api.patch(`/integration/csvimport/${id}`, data);
}
export function getAICSVMapping(params) {
  return api.get(`/integration/ai-csvimport`, {
    params
  });
}
export function createAICSVMapping(data) {
  return api.post(`/integration/ai-csvimport`, data);
}
export function updateAICSVMapping(id, data) {
  return api.patch(`/integration/ai-csvimport/${id}`, data);
}
export function generateAICSVMapping(data) {
  return api.post(`/integration/ai-csvimport/generate`, data);
}

/**
 * Surveys
 */
export function getSurveys(params) {
  return api.get(`/surveys`, {
    params: {
      archived: false,
      ...params
    }
  });
}
export function getSurvey(id) {
  return api.get(`/surveys/${id}`);
}
export function createSurvey(body) {
  return api.post(`/surveys`, body);
}
export function updateSurvey(id, body) {
  return api.patch(`/surveys/${id}`, body);
}
export function getSurveysTags(companyId) {
  return api.get(`/surveys/tags/${companyId}`);
}
export function deleteSurvey(id) {
  return api.delete(`/surveys/${id}`);
}
export function duplicateSurvey(id, company_ids) {
  return api.post(`/surveys/${id}/duplicate`, {
    company_ids
  });
}
export function getSurveyRevisions(params) {
  return api.get(`/surveys/revisions`, {
    params
  });
}
export function createSurveyRevision(body) {
  return api.post(`/surveys/revisions`, body);
}
export function getSurveyRevision(id) {
  return api.get(`/surveys/revisions/${id}`);
}
export function getSurveyRevisionTotal(id, params) {
  return api.get(`/surveys/revisions/${id}/total`, {
    params
  });
}
export function updateSurveyRevision(id, body) {
  return api.patch(`/surveys/revisions/${id}`, body);
}
export function deleteSurveyRevision(id) {
  return api.delete(`/surveys/revisions/${id}`);
}
export function getSurveyQuestions(params) {
  return api.get(`/surveys/questions`, {
    params
  });
}
export function createSurveyQuestion(body) {
  return api.post(`/surveys/questions`, body);
}
export function getSurveyQuestion(id) {
  return api.get(`/surveys/questions/${id}`);
}
export function updateSurveyQuestion(id, body) {
  return api.patch(`/surveys/questions/${id}`, body);
}
export function deleteSurveyQuestion(id) {
  return api.delete(`/surveys/questions/${id}`);
}
export function getSurveyCustomers(params) {
  return api.get(`/surveys/customers`, {
    params
  });
}
export function getSurveyCustomerAnswers(params) {
  return api.get(`/surveys/customeranswers`, {
    params
  });
}
export function getSurveyTimeline(params) {
  return api.get(`/surveys/customeranswers/timeline`, {
    params
  });
}
export function getRevisionQuestionsSummary(id, params) {
  return api.get(`/surveys/revisions/${id}/summary`, {
    params
  });
}
export function getSurveyInvite(id) {
  return api.get(`/surveys/invites/${id}`);
}
export function getSurveyInviteCustomers(params) {
  return api.get(`/surveys/invites`, {
    params
  });
}

/**
 * Folders
 */

export function getCompanyFolders(params) {
  return api.get(`/folder`, {
    params
  });
}
export function getFolder(id) {
  return api.get(`/folder/${id}`);
}
export function createNewFolder(body) {
  return api.post(`/folder`, body);
}
export function updateFolder(id, body) {
  return api.patch(`/folder/${id}`, body);
}
export function deleteFolder(id) {
  return api.delete(`/folder/${id}`);
}

/**
 * Reviews
 */

export function getReviews(params) {
  return api.get(`/reviews`, {
    params: {
      is_latest: true,
      ...params
    }
  });
}
export function editReviews(id, data) {
  return api.patch(`/reviews/${id}`, data);
}
export function sendReviewReply(id, data) {
  return api.post(`/reviews/${id}/reply`, data);
}
export function getReviewsLocations(params) {
  return api.get(`/reviews/locations`, {
    params
  });
}
export function getReviewAiReply(id) {
  return api.post(`/reviews/${id}/ai_reply`);
}
export function getReviewsExport(params) {
  return api.get(`/reviews.csv`, {
    params
  });
}
export function getReviewDetails(id, params) {
  return api.get(`/reviews/${id}/timeline`, {
    params
  });
}
export function exportReviewToCSV(data, params) {
  return api.post(`/reviews/export`, data, {
    params
  });
}

/**
 * Transactions
 */

export function getTransactions(params) {
  return api.get(`/transactions`, {
    params
  });
}
export function getTransactionsTotals(params) {
  return api.get(`/transactions/totals`, {
    params
  });
}

/**
 * Integration Logs
 */

export function getIntegrationLogs(params) {
  return api.get(`/integration/logs`, {
    params
  });
}

/**
 * Billing
 */
export function getInvoices(params) {
  return api.get(`/billing/invoice`, {
    params
  });
}
export function getQBCustomers(params) {
  return api.get(`/quickbooks/customers`, {
    params
  });
}

/**
 * Convrrt
 */

export function authenticateConvrrt(data) {
  return api.post(`/integration/convrrt/authenticate`, data);
}
export function getConvrrtPages(params) {
  return api.get(`/integration/convrrt/pages`, {
    params
  });
}

/**
 * Repeat Transaction Calculator
 */

export function getRTCRules(params) {
  return api.get(`/transaction_calculator/rules`, {
    params
  });
}
export function RunRTCCalculator(id, data) {
  return api.post(`/transaction_calculator/rules/${id}/run`, data);
}
export function createRTCRule(rule) {
  return api.post(`/transaction_calculator/rules`, rule);
}
export function getRTCRule(id, params) {
  return api.get(`/transaction_calculator/rules/${id}`, {
    params
  });
}
export function updateRTCRule(id, data) {
  return api.patch(`/transaction_calculator/rules/${id}`, data);
}
export function deleteRTCRule(id) {
  return api.delete(`/transaction_calculator/rules/${id}`);
}
export function getRTCAggregate(rule) {
  return api.post(`/transaction_calculator/rules/aggregate`, rule);
}
export function getRTCSample(rule) {
  return api.post(`/transaction_calculator/rules/sample`, rule);
}
export function getRTCResults(params) {
  return api.get(`/transaction_calculator/results`, {
    params
  });
}
export function duplicateRTCRule(id) {
  return api.post(`/transaction_calculator/rules/${id}/duplicate`);
}

/**
 * Motor OEM Recommendations
 */
export function getMotorOemRecommendation(id) {
  return api.get(`/motor_oem_recommendations/recommendations/${id}`);
}
export function getMotorOemRecommendations(params) {
  return api.get(`/motor_oem_recommendations/recommendations`, {
    params
  });
}
export function getMotorOemConfig(id) {
  return api.get(`/motor_oem_recommendations/config/${id}`);
}
export function createMotorOemConfig(body) {
  return api.post(`/motor_oem_recommendations/config`, body);
}
export function updateMotorOemConfig(id, body) {
  return api.patch(`/motor_oem_recommendations/config/${id}`, body);
}
export function finalizeMotorOemConfig(id) {
  return api.post(`/motor_oem_recommendations/config/${id}/finalize`);
}
export function deleteMotorOemConfig(id) {
  return api.delete(`/motor_oem_recommendations/config/${id}`);
}
export function getMotorOemConfigs(params) {
  return api.get(`/motor_oem_recommendations/config`, {
    params
  });
}
export function getMotorOemConfigDetails(params) {
  return api.get(`/motor_oem_recommendations/config/details`, {
    params
  });
}
export function createMotorOemConfigDetail(body) {
  return api.post(`/motor_oem_recommendations/config/details`, body);
}
export function deleteMotorOemConfigDetail(id) {
  return api.delete(`/motor_oem_recommendations/config/details/${id}`);
}
export function updateMotorOemConfigDetail(id, body) {
  return api.patch(`/motor_oem_recommendations/config/details/${id}`, body);
}

/**
 * ProductBoard
 */

export function authenticateProductBoard(data) {
  return api.post(`/integration/productboard/authenticate`, data);
}

/**
 * Launch Darkly
 */

export function addUserToLaunchDarklySegment(data) {
  return api.post(`/integration/launchdarkly/add-user-to-segment`, data);
}
export function removeUserFromLaunchDarklySegment(data) {
  return api.post(`/integration/launchdarkly/remove-user-from-segment`, data);
}
export function isUserInLaunchDarklySegment(params) {
  return api.get(`/integration/launchdarkly/is-user-in-segment`, {
    params
  });
}

/**
 * Action Items
 */

export function getActionItems(params) {
  return api.get(`/integration/actionitems/action-items`, {
    params
  });
}

/**
 * Prebuilts
 */

export function getPrebuilts(params) {
  return api.get(`/prebuilt/list`, {
    params
  });
}

/**
 * Prebuilt Segments
 */

export function getPrebuiltSegment(id, params) {
  return api.get(`/prebuilt/segments/${id}`, {
    params
  });
}
export function getPrebuiltSegments(params) {
  return api.get(`/prebuilt/segments`, {
    params
  });
}
export function createPrebuiltSegmentFromSegment(data) {
  return api.post(`/prebuilt/segments/copy`, data);
}
export function createPrebuiltSegmentVersionFromSegment(data) {
  return api.post(`/prebuilt/segments/versions/copy`, data);
}
export function patchPrebuiltSegment(id, data) {
  return api.patch(`/prebuilt/segments/${id}`, data);
}
export function getPrebuiltSegmentVersion(id, params) {
  return api.get(`/prebuilt/segments/versions/${id}`, {
    params
  });
}
export function getPrebuiltSegmentVersions(params) {
  return api.get(`/prebuilt/segments/versions`, {
    params
  });
}
export function patchPrebuiltSegmentVersion(id, data) {
  return api.patch(`/prebuilt/segments/versions/${id}`, data);
}
export function replacePrebuiltSegmentVersionAvailability(data) {
  return api.post(`/prebuilt/segments/availability/bulk/replace`, data);
}
export function getPrebuiltSegmentsTags() {
  return api.get(`/prebuilt/segments/tags`);
}
export function getPrebuiltSegmentCreatedByUsers() {
  return api.get(`/prebuilt/segments/created-by`);
}
export function getPrebuiltSegmentMetadata(id) {
  return api.get(`/prebuilt/segments/${id}/metadata`);
}
export function releasePrebuiltSegmentVersion(id) {
  return api.post(`/prebuilt/segments/versions/${id}/release`);
}
export function archivePrebuiltSegment(id) {
  return api.delete(`/prebuilt/segments/${id}`);
}
export function getPrebuiltSegmentInstalls(params) {
  return api.get(`/prebuilt/segments/installs`, {
    params
  });
}
export function createPrebuiltSegmentInstall(id, data) {
  return api.post(`/prebuilt/segments/versions/${id}/install`, data);
}

/**
 * Prebuilt Email
 */

export function getPrebuiltEmail(id, params) {
  return api.get(`/prebuilt/emails/${id}`, {
    params
  });
}
export function getPrebuiltEmails(params) {
  return api.get(`/prebuilt/emails`, {
    params
  });
}
export function createPrebuiltEmailFromEmailRevision(data) {
  return api.post(`/prebuilt/emails/copy`, data);
}
export function createPrebuiltEmailVersionFromEmailRevision(data) {
  return api.post(`/prebuilt/emails/versions/copy`, data);
}
export function patchPrebuiltEmail(id, data) {
  return api.patch(`/prebuilt/emails/${id}`, data);
}
export function getPrebuiltEmailMetadata(id) {
  return api.get(`/prebuilt/emails/${id}/metadata`);
}
export function getPrebuiltEmailVersion(id, params) {
  return api.get(`/prebuilt/emails/versions/${id}`, {
    params
  });
}
export function getPrebuiltEmailVersions(params) {
  return api.get(`/prebuilt/emails/versions`, {
    params
  });
}
export function patchPrebuiltEmailVersion(id, data) {
  return api.patch(`/prebuilt/emails/versions/${id}`, data);
}
export function replacePrebuiltEmailVersionAvailability(data) {
  return api.post(`/prebuilt/emails/availability/bulk/replace`, data);
}
export function getPrebuiltEmailsTags() {
  return api.get(`/prebuilt/emails/tags`);
}
export function getPrebuiltEmailCreatedByUsers() {
  return api.get(`/prebuilt/emails/created-by`);
}
export function releasePrebuiltEmailVersion(id) {
  return api.post(`/prebuilt/emails/versions/${id}/release`);
}
export function archivePrebuiltEmail(id) {
  return api.delete(`/prebuilt/emails/${id}`);
}
export function getPrebuiltEmailInstalls(params) {
  return api.get(`/prebuilt/emails/installs`, {
    params
  });
}
export function createPrebuiltEmailInstall(id, data) {
  return api.post(`/prebuilt/emails/versions/${id}/install`, data);
}

/**
 * Prebuilt Sms
 */

export function getPrebuiltSms(id, params) {
  return api.get(`/prebuilt/sms/${id}`, {
    params
  });
}
export function getPrebuiltSmsList(params) {
  return api.get(`/prebuilt/sms`, {
    params
  });
}
export function createPrebuiltSmsFromSmsRevision(data) {
  return api.post(`/prebuilt/sms/copy`, data);
}
export function createPrebuiltSmsVersionFromSmsRevision(data) {
  return api.post(`/prebuilt/sms/versions/copy`, data);
}
export function patchPrebuiltSms(id, data) {
  return api.patch(`/prebuilt/sms/${id}`, data);
}
export function getPrebuiltSmsMetadata(id) {
  return api.get(`/prebuilt/sms/${id}/metadata`);
}
export function getPrebuiltSmsVersion(id, params) {
  return api.get(`/prebuilt/sms/versions/${id}`, {
    params
  });
}
export function getPrebuiltSmsVersions(params) {
  return api.get(`/prebuilt/sms/versions`, {
    params
  });
}
export function patchPrebuiltSmsVersion(id, data) {
  return api.patch(`/prebuilt/sms/versions/${id}`, data);
}
export function replacePrebuiltSmsVersionAvailability(data) {
  return api.post(`/prebuilt/sms/availability/bulk/replace`, data);
}
export function getPrebuiltSmsTags() {
  return api.get(`/prebuilt/sms/tags`);
}
export function getPrebuiltSmsCreatedByUsers() {
  return api.get(`/prebuilt/sms/created-by`);
}
export function releasePrebuiltSmsVersion(id) {
  return api.post(`/prebuilt/sms/versions/${id}/release`);
}
export function archivePrebuiltSms(id) {
  return api.delete(`/prebuilt/sms/${id}`);
}
export function getPrebuiltSmsInstalls(params) {
  return api.get(`/prebuilt/sms/installs`, {
    params
  });
}
export function createPrebuiltSmsInstall(id, data) {
  return api.post(`/prebuilt/sms/versions/${id}/install`, data);
}

/**
 * Prebuilt Print
 */

export function getPrebuiltPrint(id, params) {
  return api.get(`/prebuilt/prints/${id}`, {
    params
  });
}
export function getPrebuiltPrints(params) {
  return api.get(`/prebuilt/prints`, {
    params
  });
}
export function createPrebuiltPrintFromPrintRevision(data) {
  return api.post(`/prebuilt/prints/copy`, data);
}
export function createPrebuiltPrintVersionFromPrintRevision(data) {
  return api.post(`/prebuilt/prints/versions/copy`, data);
}
export function patchPrebuiltPrint(id, data) {
  return api.patch(`/prebuilt/prints/${id}`, data);
}
export function getPrebuiltPrintMetadata(id) {
  return api.get(`/prebuilt/prints/${id}/metadata`);
}
export function getPrebuiltPrintVersion(id, params) {
  return api.get(`/prebuilt/prints/versions/${id}`, {
    params
  });
}
export function getPrebuiltPrintVersions(params) {
  return api.get(`/prebuilt/prints/versions`, {
    params
  });
}
export function patchPrebuiltPrintVersion(id, data) {
  return api.patch(`/prebuilt/prints/versions/${id}`, data);
}
export function replacePrebuiltPrintVersionAvailability(data) {
  return api.post(`/prebuilt/prints/availability/bulk/replace`, data);
}
export function getPrebuiltPrintsTags() {
  return api.get(`/prebuilt/prints/tags`);
}
export function getPrebuiltPrintCreatedByUsers() {
  return api.get(`/prebuilt/prints/created-by`);
}
export function releasePrebuiltPrintVersion(id) {
  return api.post(`/prebuilt/prints/versions/${id}/release`);
}
export function archivePrebuiltPrint(id) {
  return api.delete(`/prebuilt/prints/${id}`);
}
export function getPrebuiltPrintInstalls(params) {
  return api.get(`/prebuilt/prints/installs`, {
    params
  });
}
export function createPrebuiltPrintInstall(id, data) {
  return api.post(`/prebuilt/prints/versions/${id}/install`, data);
}

/**
 * Prebuilt Survey
 */

export function getPrebuiltSurvey(id, params) {
  return api.get(`/prebuilt/surveys/${id}`, {
    params
  });
}
export function getPrebuiltSurveys(params) {
  return api.get(`/prebuilt/surveys`, {
    params
  });
}
export function createPrebuiltSurveyFromSurveyRevision(data) {
  return api.post(`/prebuilt/surveys/copy`, data);
}
export function createPrebuiltSurveyVersionFromSurveyRevision(data) {
  return api.post(`/prebuilt/surveys/versions/copy`, data);
}
export function patchPrebuiltSurvey(id, data) {
  return api.patch(`/prebuilt/surveys/${id}`, data);
}
export function getPrebuiltSurveyMetadata(id) {
  return api.get(`/prebuilt/surveys/${id}/metadata`);
}
export function getPrebuiltSurveyVersion(id, params) {
  return api.get(`/prebuilt/surveys/versions/${id}`, {
    params
  });
}
export function getPrebuiltSurveyVersions(params) {
  return api.get(`/prebuilt/surveys/versions`, {
    params
  });
}
export function patchPrebuiltSurveyVersion(id, data) {
  return api.patch(`/prebuilt/surveys/versions/${id}`, data);
}
export function replacePrebuiltSurveyVersionAvailability(data) {
  return api.post(`/prebuilt/surveys/availability/bulk/replace`, data);
}
export function getPrebuiltSurveysTags() {
  return api.get(`/prebuilt/surveys/tags`);
}
export function getPrebuiltSurveyCreatedByUsers() {
  return api.get(`/prebuilt/surveys/created-by`);
}
export function releasePrebuiltSurveyVersion(id) {
  return api.post(`/prebuilt/surveys/versions/${id}/release`);
}
export function archivePrebuiltSurvey(id) {
  return api.delete(`/prebuilt/surveys/${id}`);
}
export function getPrebuiltSurveyInstalls(params) {
  return api.get(`/prebuilt/surveys/installs`, {
    params
  });
}
export function createPrebuiltSurveyInstall(id, data) {
  return api.post(`/prebuilt/surveys/versions/${id}/install`, data);
}

/**
 * Prebuilt Journey
 */

export function getPrebuiltJourney(id, params) {
  return api.get(`/prebuilt/journeys/${id}`, {
    params
  });
}
export function getPrebuiltJourneys(params) {
  return api.get(`/prebuilt/journeys`, {
    params
  });
}
export function createPrebuiltJourneyFromJourneyRevision(data) {
  return api.post(`/prebuilt/journeys/copy`, data);
}
export function createPrebuiltJourneyVersionFromJourneyRevision(data) {
  return api.post(`/prebuilt/journeys/versions/copy`, data);
}
export function patchPrebuiltJourney(id, data) {
  return api.patch(`/prebuilt/journeys/${id}`, data);
}
export function getPrebuiltJourneyVersion(id, params) {
  return api.get(`/prebuilt/journeys/versions/${id}`, {
    params
  });
}
export function getPrebuiltJourneyVersions(params) {
  return api.get(`/prebuilt/journeys/versions`, {
    params
  });
}
export function patchPrebuiltJourneyVersion(id, data) {
  return api.patch(`/prebuilt/journeys/versions/${id}`, data);
}
export function replacePrebuiltJourneyVersionAvailability(data) {
  return api.post(`/prebuilt/journeys/availability/bulk/replace`, data);
}
export function getPrebuiltJourneysTags() {
  return api.get(`/prebuilt/journeys/tags`);
}
export function getPrebuiltJourneyCreatedByUsers() {
  return api.get(`/prebuilt/journeys/created-by`);
}
export function releasePrebuiltJourneyVersion(id) {
  return api.post(`/prebuilt/journeys/versions/${id}/release`);
}
export function archivePrebuiltJourney(id) {
  return api.delete(`/prebuilt/journeys/${id}`);
}
export function getPrebuiltJourneyInstalls(params) {
  return api.get(`/prebuilt/journeys/installs`, {
    params
  });
}
export function createPrebuiltJourneyInstall(id, data) {
  return api.post(`/prebuilt/journeys/versions/${id}/install`, data);
}
export function getPrebuiltJourneyVersionMetaData(id) {
  return api.get(`/prebuilt/journeys/versions/${id}/node/metadata`);
}

/**
 * Widgets
 */

export function getWidgetInstalls(params) {
  return api.get(`/widgets/installs`, {
    params
  });
}
export function getAvailableWidgets(params) {
  return api.get(`/widgets/availability`, {
    params
  });
}
export function createWidgetInstall(data) {
  return api.post(`/widgets/installs`, data);
}
export function getJourneyVolumesWidget(companyId) {
  return api.get(`/widgets/journey-volumes/${companyId}`);
}
export function getEmailStatsWidget(companyId) {
  return api.get(`/widgets/email-stats/${companyId}`);
}
export function getReviewStatsWidget(companyId) {
  return api.get(`/widgets/review-stats/${companyId}`);
}
export function deleteWidgetInstall(id) {
  return api.delete(`/widgets/installs/${id}`);
}
export function patchWidgetInstall(id, data) {
  return api.patch(`/widgets/installs/${id}`, data);
}

/**
 * AI Segment Integration
 */
export function generateAiSegment(data) {
  return api.post(`/integration/ai-segment/generate`, data);
}

/**
 * Carfax Reviews Integration
 */

export function getCarfaxReviewLocations() {
  return api.get(`/integration/carfax-reviews/locations`);
}

/**
 * Release Notes
 */

export function getReleaseNotes(params) {
  return api.get(`/releasenotes`, {
    params
  });
}
export function createReleaseNote(data) {
  return api.post(`/releasenotes`, data);
}
export function patchReleaseNote(id, data) {
  return api.patch(`/releasenotes/${id}`, data);
}
export function deleteReleaseNote(id) {
  return api.delete(`/releasenotes/${id}`);
}
export function createReleaseNoteLog(data) {
  return api.post(`/releasenotes/log`, data);
}
export function processPestPacCsv(data) {
  return api.post(`/integration/pestpac/csvimport`, data);
}

/**
 * Integration Data Fields
 */

export function setDataFieldsFromIntegration(integration_id, company_id, method) {
  /**
   * @param {int} integration_id
   * @param {int} company_id
   * @param {string} method - merge or replace
   *
   * merge - combine the data fields from the integration with the existing data fields for the company
   * replace - replace the existing data fields for the company with the data fields from the integration
   */

  return api.post(`/integration/integrations/${integration_id}/datafields`, {
    company_id,
    method
  });
}