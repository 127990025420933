import React, { useEffect, useState, useContext } from 'react';
import { Modal, ModalDialog, Button, TimeSelector } from 'components';
import { getNodeCustomers, createNodeCustomersSegment, getNodeCustomersSQL } from 'shared/common.api';
import { Calendar } from 'components/calendar/calendar.component';
import moment from 'moment-timezone';
import { toasterService } from '../../components/toaster/toaster-service';
import { Loader } from '../../components/loader/loader.component';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
import utils from '../../shared/utils';
import { Calendar30DaysIcon, CustomerIcon, ReviewIcon, ShoppingCartIcon, SubscriptionsIcon, SurveyResponsesIcon, TransactionIcon, VehicleIcon } from 'components/mui';
import { Stack, Tooltip, Typography, Box } from '@mui/material';
export const CustomersListModalSQL = props => {
  const [customers, setCustomers] = useState([]);
  const [startTime, setStartTime] = useState(moment(new Date()).subtract(7, 'days'));
  const [timePending, setTimePending] = useState();
  const [endTime, setEndTime] = useState(moment(new Date()).add(10, 'minutes'));
  const [saveStaticSegmentModal, showSaveStaticSegmentModal] = useState(false);
  const [calendarModalDataValid, setCalendarModalDataValid] = useState(true);
  const [segmentName, setSegmentName] = useState('');
  const [segmentDescription, setSegmentDescription] = useState('');
  const [calendarModalType, showCalendarModalType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalHeight, setModalHeight] = useState('md');
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  useEffect(() => {
    if (!props.nodeId) {
      return;
    }
    const contextListener = event => event.preventDefault();
    document.addEventListener('contextmenu', contextListener);

    // Call the API to get the data.
    setModalHeight('md');
    setIsLoading(true);
    getNodeCustomersSQL(props.nodeId, {
      start_time: startTime,
      end_time: endTime
    }).then(({
      data
    }) => {
      //console.log(data, 'data');
      setCustomers(data.results);
      setModalHeight('none');
      setIsLoading(false);
    });
    return () => document.removeEventListener('contextmenu', contextListener);
  }, [props.nodeId, startTime, endTime]);
  const updateTime = date => {
    var d = moment(date);
    d.set({
      hour: timePending.get('hour'),
      minute: timePending.get('minute'),
      ampm: timePending.get('ampm')
    });
    return d;
  };
  const updateDate = time => {
    var d = moment(timePending);
    const [thour, tminute, tsecond] = time.split(':');
    d.set({
      hour: thour,
      minute: tminute,
      second: tsecond
    });
    return d;
  };
  const utcTime = time => {
    return moment(time).format('MM/DD/YYYY h:mm a');
  };
  const getSessionData = customer => {
    if (customer?.session_inclusion == 'Transaction') {
      return <Tooltip title="Transaction" placement="left">
          <Stack direction="row" spacing="8px ">
            <TransactionIcon />

            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography>{customer?.session_data}</Typography>
            </Box>
          </Stack>
        </Tooltip>;
    }
    if (customer?.session_inclusion == 'Survey') {
      return <Tooltip title="Survey" placement="left">
          <Stack direction="row" spacing="8px ">
            <SurveyResponsesIcon />

            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography>{customer.session_data}</Typography>
            </Box>
          </Stack>
        </Tooltip>;
    }
    if (customer?.session_inclusion == 'Schedule') {
      return <Tooltip title="Schedule" placement="left">
          <Stack direction="row" spacing="8px ">
            <Calendar30DaysIcon />
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography>{customer?.session_data}</Typography>
            </Box>
          </Stack>
        </Tooltip>;
    }
    if (customer?.session_inclusion == 'Review') {
      return <Tooltip title="Review" placement="left">
          <Stack direction="row" spacing="8px ">
            <ReviewIcon />
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography>{customer?.session_data}</Typography>
            </Box>
          </Stack>
        </Tooltip>;
    }
    if (customer?.session_inclusion == 'Cart') {
      return <Tooltip title="Cart" placement="left">
          <Stack direction="row" spacing="8px ">
            <ShoppingCartIcon />
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography>{customer?.session_data}</Typography>
            </Box>
          </Stack>
        </Tooltip>;
    }
    if (customer?.session_inclusion == 'Possession') {
      if (customer?.session_data.includes('Status')) {
        return <Tooltip title="Subscription" placement="left">
            <Stack direction="row" spacing="8px ">
              <SubscriptionsIcon />
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>{customer?.session_data}</Typography>
              </Box>
            </Stack>
          </Tooltip>;
      }
      return <Tooltip title="Vehicle" placement="left">
          <Stack direction="row" spacing="8px ">
            <VehicleIcon />
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography>{customer?.session_data}</Typography>
            </Box>
          </Stack>
        </Tooltip>;
    }
    return <Tooltip title="Customer Only" placement="left">
        <Stack direction="row" spacing="8px ">
          <CustomerIcon />
          <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
            <Typography>Customer Only</Typography>
          </Box>
        </Stack>
      </Tooltip>;
  };
  return <>
      <Modal open={!!props.nodeId} onClose={props.onClose} size="xlvw" type="scroll" height={modalHeight} onSubmit={() => {}}>
        <div>
          <div className="modal__title d-flex space-between">
            <div>
              <Tooltip title="SQL">Customer List </Tooltip>
            </div>

            <div className="d-flex space-between">
              <div>
                <Button disabled={isLoading} block onClick={() => {
                setTimePending(startTime);
                showCalendarModalType('start');
              }}>
                  {startTime ? startTime.format('MM/DD/YYYY h:mm a') : 'Select Date'}
                </Button>
              </div>

              <div className="px-3 text-sm pt-1">to</div>

              <div className="pr-xl mr-xl">
                <Button disabled={isLoading} block onClick={() => {
                setTimePending(endTime);
                showCalendarModalType('end');
              }}>
                  {endTime ? endTime.format('MM/DD/YYYY h:mm a') : 'Select Date'}
                </Button>
              </div>

              <div className="pr-xl pl-xl ml-xl">
                <Button className="btn--primary" disabled={isLoading || !customers?.length} onClick={() => showSaveStaticSegmentModal(true)}>
                  {customers?.length >= 49 ? 'Save complete list' : 'Save this list'}
                </Button>
              </div>
            </div>
          </div>

          <div className="wrapper-scroll journey-detail p-md">
            {isLoading ? <Loader /> : <div className="modal__body">
                {!isLoading && !customers?.length && <tr>
                    <td>No customers found in this date range.</td>
                  </tr>}
                {!isLoading && !!customers?.length && <div className="segments-list">
                    {customers?.length >= 49 && <em>Limited to the most recent 50 customers. Save to view complete list.</em>}
                    <table className="table-list mt-2 mb-5">
                      <thead>
                        <tr>
                          <th>Time Entered</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Session Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers?.map((customer, i) => <tr key={i} onClick={() => {
                    openCustomerDrawer(customer?.customer_id);
                    props.onClose();
                  }}>
                            <td>{utcTime(customer?.entered_when)}</td>
                            <td>{customer?.customer_name}</td>

                            <td>{utils.formatPhoneNumber(customer?.phone)}</td>
                            <td>{customer?.email}</td>
                            <td>{getSessionData(customer)}</td>
                          </tr>)}
                      </tbody>
                    </table>
                  </div>}
              </div>}
          </div>

          <ModalDialog open={!!calendarModalType} onClose={() => showCalendarModalType(false)} allowBackdropClick size="sm" title={`Select ${calendarModalType === 'start' ? 'Start' : 'End'} Date and Time`} submitDisabled={!calendarModalDataValid} onSubmit={() => {
          if (calendarModalType === 'start') {
            setStartTime(timePending);
          } else {
            setEndTime(timePending);
          }
          showCalendarModalType(false);
        }}>
            <Calendar minDate={null} maxDate={new Date()} value={timePending ? new Date(timePending) : null} onChange={date => {
            setTimePending(updateTime(date));
            if (calendarModalType === 'start') {
              if (updateTime(date) < endTime) {
                setCalendarModalDataValid(true);
              } else {
                toasterService.error('Start Date cannot be after End Date');
                setCalendarModalDataValid(false);
              }
            } else {
              if (updateTime(date) < startTime) {
                toasterService.error('End Date cannot be before Start Date');
                setCalendarModalDataValid(false);
              } else {
                setCalendarModalDataValid(true);
              }
            }
          }} />
            <TimeSelector value={new Date(timePending)} className="py-3" onChange={time => {
            setTimePending(updateDate(time));
            if (calendarModalType === 'start') {
              if (updateDate(time) < endTime) {
                setCalendarModalDataValid(true);
              } else {
                toasterService.error('Start Date cannot be after End Date');
                setCalendarModalDataValid(false);
              }
            } else {
              if (updateDate(time) < startTime) {
                toasterService.error('End Date cannot be before Start Date');
                setCalendarModalDataValid(false);
              } else {
                setCalendarModalDataValid(true);
              }
            }
          }} />
          </ModalDialog>

          <ModalDialog open={!!saveStaticSegmentModal} onClose={() => showSaveStaticSegmentModal(false)} allowBackdropClick size="sm" title="Create New Static Segment" submitDisabled={!segmentName} onSubmit={() => {
          createNodeCustomersSegment(props.nodeId, {
            start_time: startTime,
            end_time: endTime,
            segment_title: segmentName,
            segment_description: segmentDescription
          }).then(() => {
            showSaveStaticSegmentModal(false);
            props.onClose();
          });
        }}>
            <div className="form-group">
              <label>Segment Name</label>
              <input onChange={e => {
              setSegmentName(e.target.value);
            }} className="form-control" type="text" value={segmentName} />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea onChange={e => {
              setSegmentDescription(e.target.value);
            }} className="form-control" type="text" style={{
              height: '90px'
            }} value={segmentDescription} />
            </div>
          </ModalDialog>
        </div>
      </Modal>
    </>;
};