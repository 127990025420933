import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import styles from './phone-numbers.styles.scss';
import { Loader } from '../../components/loader/loader.component';
import { Button } from '../../components/button/button.component';
import { ButtonGroup } from '../../components/button/button-group.component';
import { Modal } from '../../components/modal/modal.component';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { getPhoneNumbers, deletePhoneNumber, updatePhoneNumber, getCompanyPhoneCount, refreshPhoneRegistration, getPhoneRegistration } from '../../shared/common.api';
import utils from '../../shared/utils';
import { IconButton, Tooltip, Box, Stack, Typography, Divider, Paper, Popper, Button as MUIButton, CircularProgress } from '@mui/material';
import { PhoneNumberModal } from './phone-number-modal.component';
import { Icon, PageHeaderMui } from 'components';
import { UserStateContext } from 'context/user-state-context';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { BlockIcon, CallMadeIcon, CancelOutlinedIcon, CheckCircleIcon, DeleteIcon, EditSquareIcon, PendingIcon, RefreshIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../root.component';
import moment from 'moment-timezone';
export function PhoneNumbers() {
  const {
    hasPermission,
    asCompany
  } = useContext(UserStateContext);
  const [deletePhoneModal, setDeletePhoneModal] = useState();
  const [renamePhoneModal, setRenamePhoneModal] = useState();
  const [loading, setLoading] = useState(true);
  const [ordering, setOrdering] = useState('name');
  const [paginationParams, setPaginationParams] = useState({});
  const [phoneNumberPagination, setPhoneNumberPagination] = useState({});
  const [customerCount, setCustomerCount] = useState(0);
  const [timeToSend, setTimeToSend] = useState(60);
  const [SMSMPM, setSMSMPM] = useState(60);
  const [MMSMPM, setMMSMPM] = useState(30);
  const [sendType, setSendType] = useState('sms');
  const [phoneNumbersNeeded, setPhoneNumbersNeeded] = useState(0);
  const [showCalcTool, setShowCalcTool] = useState(false);
  const [phoneNumberModal, setPhoneNumberModal] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [profile, setProfile] = useState();
  const [helperPopper, setHelperPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [friendlyDate, setFriendlyDate] = useState();
  const [renderIteration, setRenderIteration] = useState(0);

  // checks for the 10DLC registration status
  useEffect(() => {
    setLoading(true);
    if (asCompany) {
      Promise.resolve(getPhoneRegistration(asCompany.id)).then(({
        data: profile
      }) => {
        setProfile(profile);
      }).catch(() => {});
    }
    setLoading(false);
  }, [asCompany]);

  // calculates the number of phone numbers needed based on the number of unique phone numbers, time to send, and message type
  useEffect(() => {
    setPhoneNumbersNeeded(Math.ceil(customerCount / (timeToSend * (sendType === 'sms' ? SMSMPM : MMSMPM))));
  }, [customerCount, timeToSend, SMSMPM, MMSMPM, sendType]);
  const loadData = useCallback(() => {
    if (!asCompany) return;
    return getPhoneNumbers({
      company: asCompany.id,
      ordering,
      ...paginationParams
    }).then(({
      data
    }) => {
      setPhoneNumberPagination(data);
      setLoading(false);
      if (history?.location?.state?.openNew) {
        createCustomerProfileHandler();
      }
    });
  }, [setLoading, setPhoneNumberPagination, ordering, paginationParams, asCompany?.id]);

  // loads the phone numbers and the number of phone numbers
  useEffect(() => {
    if (!asCompany) return;
    loadData();
    getCompanyPhoneCount(asCompany.id).then(({
      data
    }) => {
      setCustomerCount(data);
    });
  }, [loadData, asCompany?.id]);

  // formats updated_when to a friendly date for display
  useEffect(() => {
    setLoading(true);
    if (profile?.updated_when) {
      new Date().getTimezoneOffset();
      const date = moment(profile.updated_when).tz(moment.tz.guess());
      let friendlyDate;
      if (date.isSame(moment(), 'day')) {
        friendlyDate = 'Today ' + date.format('h:mm a');
      } else if (date.isSame(moment().subtract(1, 'days'), 'day')) {
        friendlyDate = 'Yesterday ' + date.format('h:mm a');
      } else {
        friendlyDate = date.format('MMMM D, YYYY h:mm a');
      }
      setFriendlyDate(friendlyDate);
    }
    setLoading(false);
  }, [profile]);
  const createCustomerProfileHandler = () => {
    setPhoneNumberModal(true);
  };
  const renameNumber = number => {
    updatePhoneNumber(number.id, {
      name: number.name
    }).then(() => loadData()).then(() => {
      setRenderIteration(r => r + 1);
      toasterService.success('Phone number successfully updated');
    }).catch(() => {
      toasterService.error('Unable to update phone number. Please try again.');
    });
  };
  const releaseNumber = number => {
    deletePhoneNumber(number.id).then(() => loadData()).then(() => {
      setRenderIteration(r => r + 1);
      toasterService.success('Phone number successfully released');
    }).catch(() => {
      toasterService.error('Unable to release phone number. Please try again.');
    });
  };
  const headCells = [{
    key: 'title',
    label: 'Name',
    width: '25%',
    displayComponent: ({
      data: phoneNumber
    }) => <>{utils.formatPhoneNumber(phoneNumber.name)}</>
  }, {
    key: 'phone',
    label: 'Phone',
    width: '25%',
    displayComponent: ({
      data: phoneNumber
    }) => <>{utils.formatPhoneNumber(phoneNumber.phone)}</>
  }, {
    key: 'locality',
    label: 'Location',
    width: '25%',
    displayComponent: ({
      data: phoneNumber
    }) => <Stack direction="row" spacing="10px">
          <> {phoneNumber.locality}</>
          <> {phoneNumber.region} </>
          <>{phoneNumber.postal_code} </>
        </Stack>
  }, {
    key: 'status',
    label: 'Status',
    width: '25%',
    displayComponent: ({
      data: phoneNumber
    }) => <>{utils.formatTwilioStatus(phoneNumber.status)}</>
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: phoneNumber,
      onHover
    }) => {
      if (phoneNumber.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-18px',
            justifyContent: 'flex-end'
          }}>
                {hasPermission('communication.change_phonenumber') && <Tooltip title="Rename Phone Number">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setRenamePhoneModal(phoneNumber);
              }}>
                      <EditSquareIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('communication.delete_phonenumber') && <Tooltip title="Release Phone Number">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setDeletePhoneModal(phoneNumber);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  const handleRowClick = row => {
    setRenamePhoneModal(row);
  };
  const refresh = () => {
    setIsRefreshing(true);
    refreshPhoneRegistration(asCompany.id).then(({
      data
    }) => {
      setProfile(original => ({
        ...original,
        ...data
      }));
      setIsRefreshing(false);
    });
  };
  const statusHandler = status => {
    if (!status) {
      return 'Not Started';
    }
    if (status === 'PENDING') {
      return 'Pending';
    }
    if (status === 'IN_REVIEW') {
      return 'In Review';
    }
    if (status === 'FAILED') {
      return 'Failed';
    }
    if (status === 'APPROVED') {
      return 'Approved';
    }
    if (status === 'VERIFIED') {
      return 'Verified';
    }
    return 'Pending';
  };
  if (loading) return <Loader overlay />;
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type={`Phone Numbers ${phoneNumberPagination.count ? `(${utils.commaize(phoneNumberPagination.count)})` : ''}`} icon={<Icon name="custom-call" size={34} />} />
        <div className="mui-wrapper">
          <Stack sx={{
          pt: 3
        }}>
            <Box sx={{
            px: 3
          }}>
              <Paper sx={{
              height: '65px'
            }}>
                <Stack sx={{
                height: '100%',
                py: '10px'
              }} className="d-flex justify-content-between align-items-center w-100" direction="row">
                  <>
                    <Stack sx={{
                    pl: '24px',
                    display: 'flex',
                    alignItems: 'center'
                  }} spacing="20px" direction="row">
                      <Typography fontSize="20px">10DLC Registration</Typography>
                      <Divider orientation="vertical" sx={{
                      height: '24px'
                    }} />

                      <Stack spacing="10px" sx={{
                      cursor: 'default',
                      display: 'flex',
                      alignItems: 'center'
                    }} direction="row">
                        <Typography variant="tableHeader"> Brand: </Typography>
                        <Box sx={{
                        width: profile?.brand_status === 'APPROVED' ? '123px' : profile?.brand_status === 'PENDING' ? '112px' : profile?.brand_status === 'FAILED' ? '97px' : '135px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '8px',
                        backgroundColor: profile?.brand_status === 'APPROVED' ? 'rgba(62, 184, 123, 0.3)' : profile?.brand_status === 'PENDING' ? 'rgba(255,184,43,0.3)' : profile?.brand_status === 'FAILED' ? 'rgba(243, 15, 15)' : 'rgba(243, 15, 15, 0.3)'
                      }}>
                          <Box sx={{
                          px: '8px'
                        }}>
                            {profile?.brand_status === 'APPROVED' ? <CheckCircleIcon fill={'#3EB87B'} /> : profile?.brand_status === 'PENDING' ? <PendingIcon fill={'#FFB82B'} /> : profile?.brand_status === 'FAILED' ? <CancelOutlinedIcon fill={'#FFF'} /> : <BlockIcon fill={'#F30F0F'} />}
                          </Box>
                          <Box>
                            <Typography sx={{
                            color: profile?.brand_status === 'APPROVED' ? '#3EB87B' : profile?.brand_status === 'PENDING' ? '#FFB82B' : profile?.brand_status === 'FAILED' ? '#FFF' : '#F30F0F',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                              {statusHandler(profile?.brand_status)}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                      {!profile?.brand_status || profile?.brand_status === 'FAILED' ? <Stack spacing="10px" sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }} direction="row">
                          <MUIButton variant="text" sx={{
                        height: '32px',
                        borderRadius: '28px',
                        textTransform: 'none',
                        color: '#3898D9',
                        whiteSpace: 'nowrap'
                      }} onClick={() => {
                        if (profile?.brand_status === 'FAILED' || !profile?.brand_status) {
                          createCustomerProfileHandler();
                          return;
                        }
                        return;
                      }}>
                            <Typography sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#3898D9',
                          fontWeight: 700
                        }}>
                              {profile?.brand_status === 'FAILED' ? 'Resolve' : 'Get Started'}
                              {!profile?.brand_status || profile?.brand_status === 'FAILED' ? <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                  <CallMadeIcon fill={'#3898D9'} />
                                </Box> : null}
                            </Typography>
                          </MUIButton>
                        </Stack> : null}

                      <Divider orientation="vertical" sx={{
                      height: '24px'
                    }} />
                      <Stack spacing="10px" sx={{
                      cursor: 'default',
                      display: 'flex',
                      alignItems: 'center'
                    }} direction="row">
                        <Typography variant="tableHeader"> Campaign: </Typography>
                        <Box sx={{
                        width: profile?.campaign_status === 'VERIFIED' ? '109px' : profile?.campaign_status === 'IN_PROGRESS' ? '112px' : profile?.campaign_status === 'FAILED' ? '97px' : '135px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '8px',
                        backgroundColor: profile?.campaign_status === 'VERIFIED' ? 'rgba(62, 184, 123, 0.3)' : profile?.campaign_status === 'IN_PROGRESS' ? 'rgba(255,184,43,0.3)' : profile?.campaign_status === 'FAILED' ? 'rgba(243, 15, 15)' : 'rgba(243, 15, 15, 0.3)'
                      }}>
                          <Box sx={{
                          px: '8px'
                        }}>
                            {profile?.campaign_status === 'VERIFIED' ? <CheckCircleIcon fill={'#3EB87B'} /> : profile?.campaign_status === 'IN_PROGRESS' ? <PendingIcon fill={'#FFB82B'} /> : profile?.campaign_status === 'FAILED' ? <CancelOutlinedIcon fill={'#FFF'} /> : <BlockIcon fill={'#F30F0F'} />}
                          </Box>
                          <Box>
                            <Typography sx={{
                            color: profile?.campaign_status === 'VERIFIED' ? '#3EB87B' : profile?.campaign_status === 'IN_PROGRESS' ? '#FFB82B' : profile?.campaign_status === 'FAILED' ? '#FFF' : '#F30F0F',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                              {' '}
                              {statusHandler(profile?.campaign_status)}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                      {!profile?.campaign_status || profile.campaign_status === 'FAILED' ? <Stack spacing="10px" sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }} direction="row">
                          <MUIButton variant="text" sx={{
                        height: '32px',
                        borderRadius: '28px',
                        textTransform: 'none',
                        color: '#3898D9',
                        whiteSpace: 'nowrap'
                      }} onClick={e => {
                        if (profile?.brand_status === 'APPROVED') {
                          createCustomerProfileHandler();
                          return;
                        } else {
                          setHelperPopper(true);
                          setAnchorEl(e.currentTarget);
                        }
                      }}>
                            <Typography sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: profile?.brand_status !== 'APPROVED' ? 'rgba(56,152,217,0.4)' : '#3898D9',
                          fontWeight: 700
                        }}>
                              {profile?.campaign_status === 'FAILED' ? 'Resolve' : 'Get Started'}
                              {!profile?.campaign_status || profile?.campaign_status === 'FAILED' ? <CallMadeIcon fill={profile?.brand_status !== 'APPROVED' ? 'rgba(56,152,217,0.4)' : '#3898D9'} /> : null}
                            </Typography>
                          </MUIButton>
                        </Stack> : null}

                      <Popper anchorEl={anchorEl} sx={{
                      zIndex: 101,
                      cursor: 'default'
                    }} open={!!helperPopper} onClose={() => setHelperPopper(false)} placement="bottom-start">
                        <Paper elevation={0} sx={{
                        borderRadius: '16px',
                        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
                        width: '305px',
                        height: '188px',
                        flexShrink: 0,
                        borderRadius: '16px'
                      }}>
                          <Stack direction="column">
                            <Typography sx={{
                            pt: '12px',
                            pb: '4px',
                            pl: '16px',
                            pr: '25px',
                            fontWeight: 700
                          }}>
                              10DLC Campaign Status
                            </Typography>
                            <Typography sx={{
                            px: '16px',
                            pb: '20px',
                            fontWeight: 300
                          }}>
                              Before you can complete campaign registration, brand registration is required. Once brand registration is
                              approved, you can start the campaign verification process.
                            </Typography>
                            <Box sx={{
                            pl: '22px',
                            pt: '18px',
                            display: 'flex',
                            alignContent: 'center'
                          }}>
                              <MUIButton onClick={() => setHelperPopper(false)}>
                                <Typography sx={{
                                textTransform: 'none',
                                color: '#3898D9'
                              }}>
                                  Got it
                                </Typography>
                              </MUIButton>
                            </Box>
                          </Stack>
                        </Paper>
                      </Popper>
                    </Stack>
                    <Stack sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row">
                      <Typography sx={{
                      pr: '18px',
                      fontSize: '12px',
                      textAlign: 'right',
                      fontWeight: '300'
                    }} variant="tableHeader">
                        {isRefreshing ? 'Updating...' : 'Updated ' + (profile?.updated_when ? friendlyDate : 'never')}
                      </Typography>
                      <Tooltip title="Refresh">
                        <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                          <MUIButton sx={{
                          width: '24px',
                          height: '24px'
                        }} onClick={refresh}>
                            {isRefreshing ? <CircularProgress sx={{
                            color: '#FFB82B'
                          }} size={'20px'} /> : <RefreshIcon />}
                          </MUIButton>
                        </Box>
                      </Tooltip>
                    </Stack>
                  </>
                </Stack>
              </Paper>
            </Box>
          </Stack>
          <EnhancedTable type="Phone Numbers" headCells={headCells} api={getPhoneNumbers} defaultSort={'-updated_when'} renderIteration={renderIteration} handleRowClick={row => {
          if (hasPermission('communication.change_phonenumber')) {
            handleRowClick(row);
          } else {
            snackbarService.popup({
              type: 'error',
              message: 'You do not have permission to edit this phone number. You need the Manage Phone Number permission on the Roles page'
            });
          }
        }} actions={hasPermission('communication.add_phonenumber') && <NewButton onClick={e => createCustomerProfileHandler()} />} />
        </div>

        <Modal open={showCalcTool} onClose={() => setShowCalcTool(false)} allowBackdropClick title="Phone Number Calculation Tool">
          <div className="modal__body">
            <div className="form-group">
              <label>Message Type</label>
              <ButtonGroup>
                <Button className={classnames({
                active: sendType === 'sms'
              })} actionType="white" onClick={() => setSendType('sms')}>
                  SMS
                </Button>
                <Button className={classnames({
                active: sendType === 'mms'
              })} actionType="white" onClick={() => setSendType('mms')}>
                  MMS
                </Button>
              </ButtonGroup>
            </div>
            <div className="form-group">
              <label>Number of Unique Phone Numbers</label>
              <input className="form-control" name="name" type="number" onChange={e => setCustomerCount(parseFloat(e.target.value))} value={customerCount} />
            </div>
            <div className="form-group">
              <label>Time to Send</label>
              <input className="form-control" name="name" type="number" onChange={e => setTimeToSend(parseFloat(e.target.value))} value={timeToSend} />
              <div className="text-sm mt-sm">Number of minutes to send a message to all of your customers</div>
            </div>
            <div className="form-group">
              <label>Max Message Per Minute SMS</label>
              <input className="form-control" name="name" type="number" onChange={e => setSMSMPM(parseFloat(e.target.value))} value={SMSMPM} />
              <div className="text-sm mt-sm">Number of messages each phone number can send per minute</div>
            </div>
            <div className="form-group">
              <label>Max Message Per Minute MMS</label>
              <input className="form-control" name="name" type="number" onChange={e => setMMSMPM(parseFloat(e.target.value))} value={MMSMPM} />
              <div className="text-sm mt-sm">Number of messages each phone number can send per minute</div>
            </div>
            <hr />
            <div className="form-group">
              <label>Number of Phone Numbers Needed</label>
              <input className="form-control" name="name" type="number" disabled={true} value={phoneNumbersNeeded} />
            </div>
          </div>
          <div className="modal__actions">
            <Button onClick={() => setShowCalcTool(false)}>Done</Button>
          </div>
        </Modal>
        <ModalDialog open={!!deletePhoneModal} title="Release Phone Number" submitText="Release" onSubmit={() => releaseNumber(deletePhoneModal)} onClose={() => setDeletePhoneModal(null)} actionType="warning" allowBackdropClick>
          <p>
            <strong>{!!deletePhoneModal && deletePhoneModal.phone}</strong>
          </p>
          <p>Are you sure you want to release this phone number? This action cannot be undone.</p>
        </ModalDialog>
        <ModalDialog open={!!renamePhoneModal} title="Rename Phone Number" onSubmit={() => renameNumber(renamePhoneModal)} onClose={() => setRenamePhoneModal(null)} allowBackdropClick>
          <p>This is a friendly name for the phone number and only impacts how the number is displayed in Cinch.</p>
          <div className="form-group">
            <label>New name for phone number</label>
            <input className="form-control" name="name" value={renamePhoneModal && renamePhoneModal.name || ''} onChange={e => setRenamePhoneModal({
            ...renamePhoneModal,
            name: e.target.value
          })} />
          </div>
        </ModalDialog>
        <PhoneNumberModal profile={profile} open={phoneNumberModal} loadData={loadData} onClose={() => {
        setPhoneNumberModal(false);
        setRenderIteration(r => r + 1);
      }} />
      </div>
    </Scoped>;
}