import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { createFacebookInstall, createFacebookReviewInstall, createInstall, getFacebookInstalls, getFacebookReviewAccountInstalls, getFacebookTokens, getInstalls, getIntegrations } from '../integrations/integrations.resource';
import { Icon, PageHeaderMui, Toggle } from 'components';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton, InputAdornment, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Chip, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { sortBy } from 'lodash';
import { AddIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, LandscapeDisabledIcon, OpenInNewWindowIcon, ShieldPersonIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
import { history } from '../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `16px`
    }
  }
})(TextField);
const FACEBOOKICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII=';
export const MarketplaceList = () => {
  const {
    user,
    asCompany,
    hasPermission,
    enabledIntegrationChange,
    flags
  } = useContext(UserStateContext);
  const [installedIntegrations, setInstalledIntegrations] = useState([]);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [allIntegrations, setAllIntegrations] = useState([]);
  const [isInstalling, setIsInstalling] = useState(null);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setLoading(true);
    const promises = [getIntegrations({
      ...filters,
      limit: 1000
    }), getInstalls({
      ...filters,
      company: asCompany.id,
      limit: 1000
    }), getFacebookInstalls({
      company: asCompany.id
    }), getFacebookReviewAccountInstalls({
      company: asCompany.id
    })];
    Promise.all(promises).then(([integrations, installs, facebookInstalls, facebookReviewInstalls]) => {
      const publicIntegrations = integrations?.results.filter(i => i.status !== 'dev' && (hasPermission('integration.manage_private_integrations') || i.status !== 'private'));

      //add in facebook
      publicIntegrations.push({
        key: 'facebook',
        name: 'Facebook',
        icon: FACEBOOKICON
      });
      if (flags.facebook_reviews) {
        publicIntegrations.push({
          key: 'facebook_reviews',
          name: 'Facebook Reviews',
          icon: FACEBOOKICON
        });
      }

      /*   const resultsWithFacebook = installs?.results.filter(
        (i) =>
          i.integration_status !== 'dev' &&
          (hasPermission('integration.manage_private_integrations') || i.integration_status !== 'private'),
      );
       if (
        facebookInstalls?.results?.length > 0 &&
        (search == null || search == '' || 'facebook'.includes(search?.toLowerCase())) &&
        (filters.type == null || filters.type == '' || filters.type == 'all' || filters.type == 'product')
      ) {
        resultsWithFacebook.push({
          id: 'facebook',
          integration: 'facebook',
          integration_name: 'Facebook',
          status: facebookInstalls?.results.some((i) => i.status == 'configured') ? 'configured' : 'not_configured',
          integration_icon: FACEBOOKICON,
        });
      }
       if (flags.facebook_reviews) {
        if (facebookReviewInstalls.results.length > 0) {
          resultsWithFacebook.push({
            id: 'facebook_reviews',
            integration: 'facebook_reviews',
            integration_name: 'Facebook Reviews',
            status: facebookInstalls?.results.some((i) => i.status == 'configured') ? 'configured' : 'not_configured',
            integration_icon: FACEBOOKICON,
          });
        }
      }
       resultsWithFacebook.map((result) => {
        result.sortBy = result?.integration_name?.toLowerCase();
      });
       setInstalledIntegrations(sortBy(resultsWithFacebook, 'sortBy', 'desc')); */

      const path = window.location.pathname.split('/');
      const onlyShowInstalls = path[path.length - 1] === 'installed' ? true : false;

      //append installs to all integrations list

      const filtered = publicIntegrations?.filter(i => {
        if (onlyShowInstalls) {
          const install = installs?.results?.find(inst => inst.integration === i.key);
          if (install) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })?.map(integration => {
        const install = installs?.results.filter(i => i.integration_status !== 'dev' && (hasPermission('integration.manage_private_integrations') || i.integration_status !== 'private'))?.find(i => i.integration === integration.key);
        if (install) {
          integration.install = install;
        }
        integration.sortBy = integration?.name?.toLowerCase();
        return integration;
      });
      setAllIntegrations(sortBy(filtered, 'sortBy', 'desc'));
      setLoading(false);
    });
  }, [hasPermission, asCompany?.id, filters, window?.location?.pathname]);
  useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filters,
        search: search
      });
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  /*
  const onSubmit = () => {
    const promises = [];
    selected.forEach((integration) => {
      if (integration.key != 'facebook' && integration.key != 'facebook_reviews') {
        promises.push(
          createInstall({
            integration: integration.key,
            company: asCompany.id,
          }),
        );
      }
    });
     Promise.all(promises).then(() => {
      if (selected.find((i) => i.key === 'facebook')) {
        createFacebookInstall().then((data) => {
          history.push('/integrations', { newInstalls: selected });
        });
      } else if (selected.find((i) => i.key === 'facebook_reviews')) {
        createFacebookReviewInstall({ company: asCompany.id }).then(() => {
          history.push('/integrations', { newInstalls: selected });
        });
      } else {
        history.push('/integrations', { newInstalls: selected });
      }
    });
  }; */

  const onInstall = integration => {
    setIsInstalling(integration.key);
    createInstall({
      integration: integration.key,
      company: asCompany.id
    }).then(install => {
      const newIntegrations = allIntegrations.map(i => {
        if (i.key === integration.key) {
          i.install = install;
        }
        return i;
      });
      setAllIntegrations(newIntegrations);
      setIsInstalling(null);
      enabledIntegrationChange();
      snackbarService.popup({
        type: 'success',
        message: integration?.name + 'installed. You may now launch the integration.'
      });
    });
  };
  return <>
      <div className="wrapper" style={{
      height: !!loading.length ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Marketplace" icon={<Icon name="custom-auto_graph" size={34} />} />
        <Divider />
        <Paper sx={{}}>
          <Stack sx={{
          p: '20px'
        }}>
            <Stack sx={{
            px: '28px'
          }} direction="row" spacing="24px">
              <Box>
                <CustomTextField variant="outlined" sx={{
                width: '500px'
              }} placeholder="Search" autoComplete="new-password" InputProps={{
                startAdornment: <InputAdornment position="start">
                        <Icon name="custom-search" />
                      </InputAdornment>,
                endAdornment: search != '' && <InputAdornment onClick={e => {
                  setSearch('');
                }} position="start" sx={{
                  cursor: 'pointer'
                }}>
                        <CloseIcon />
                      </InputAdornment>
              }} value={search || ''} onChange={e => {
                setSearch(e.target.value);
              }} />
              </Box>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Stack direction="row">
                  <Box>
                    {!!filters?.type ? <Stack sx={{
                    backgroundColor: '#C2E7FF',
                    borderRadius: '20px'
                  }} direction="row" spacing="4px">
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pl: '16px',
                      py: '9px'
                    }}>
                          <Typography sx={{
                        color: '#3898D9',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        textTransform: 'capitalize'
                      }}>
                            {filters?.type}
                          </Typography>
                        </Box>
                        <IconButton onClick={e => {
                      setFilters({
                        ...filters,
                        type: '',
                        integration__type: ''
                      });
                    }}>
                          <CloseIcon size="sm" fill="#3898D9" />
                        </IconButton>
                      </Stack> : <FormControl size="small" sx={{
                    width: '150px'
                  }}>
                        <InputLabel id="integration-type-label">Type</InputLabel>
                        <Select sx={{
                      borderRadius: '24px'
                    }} id="integration-type" labelId="integration-type-label" input={<OutlinedInput label="Type" />} label="type" value={filters?.type || ''} onChange={e => {
                      setFilters({
                        ...filters,
                        type: e.target.value,
                        integration__type: e.target.value
                      });
                    }}>
                          <MenuItem value={'data'}>Data</MenuItem>
                          <MenuItem value={'feature'}>Feature</MenuItem>
                          <MenuItem value={'product'}>Product</MenuItem>
                        </Select>
                      </FormControl>}
                  </Box>
                </Stack>
              </Box>
            </Stack>

            <Stack spacing={'20px'} sx={{
            width: '100%'
          }}>
              {loading ? <Box sx={{
              p: '20px'
            }}>
                  <Grid sx={{
                px: '8px'
              }} container spacing={{
                xs: 2,
                sm: 2,
                md: 3,
                lg: 3
              }} columns={{
                xs: 4,
                sm: 12,
                md: 12,
                lg: 12
              }}>
                    {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                          <Box sx={{
                    p: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #E8EAED',
                    borderRadius: '8px',
                    width: '315px',
                    backgroundColor: '#fff'
                  }}>
                            <Stack direction="row" spacing={'16px'}>
                              <Box>
                                <DefaultIntegrationIcon />
                              </Box>
                              <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                                <Skeleton variant="rounded" width={210} height={20} />
                              </Box>
                            </Stack>
                          </Box>
                        </Grid>)}
                  </Grid>
                </Box> : <Box sx={{
              p: '20px'
            }}>
                  <Grid sx={{
                px: '8px',
                maxWidth: '85vw'
              }} container spacing={{
                xs: 2,
                sm: 2,
                md: 3,
                lg: 3
              }} columns={{
                xs: 4,
                sm: 12,
                md: 12,
                lg: 12
              }}>
                    {allIntegrations.length > 0 ? <>
                        {allIntegrations.map((integration, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index + integration?.key}>
                            <Box sx={{
                      pt: '32px',
                      pb: '16px',
                      display: 'flex',
                      border: '1px solid #E2E2E2',
                      borderRadius: '16px',
                      width: '300px'
                      //height:'250px',
                    }}>
                              <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}>
                                <Stack spacing={'16px'} sx={{
                          px: '32px',
                          pb: '16px'
                        }}>
                                  <Box>
                                    {integration?.icon ? <img src={integration?.icon} height="40" width="40" alt={integration?.name} /> : <DefaultIntegrationIcon />}
                                  </Box>

                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <Typography sx={{
                              fontSize: '20px',
                              fontWeight: '600',
                              lineHeight: '30px'
                            }}>
                                      {integration.name}
                                    </Typography>
                                  </Box>

                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: '60px'
                          }}>
                                    <Typography sx={{
                              fontSize: '10px'
                            }}> {integration?.description} </Typography>
                                  </Box>

                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    {integration?.type ? <Chip sx={{
                              background: '#C2E7FF'
                            }} label={String(integration.type).charAt(0).toUpperCase() + String(integration.type).slice(1)} /> : <Box sx={{
                              height: '32px'
                            }} />}
                                  </Box>
                                </Stack>
                                <Stack spacing={'16px'}>
                                  <Divider />
                                  <Box sx={{
                            px: '32px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                            alignContent: 'center'

                            //
                          }}>
                                    {integration?.install ? <Button onClick={e => {
                              history.push(`/marketplace/manage/${integration.install.id}`);
                            }} variant="contained" color="primary" sx={{
                              backgroundColor: '#3898D9',
                              '&:hover': {
                                backgroundColor: '#3898D9'
                              }
                            }} startIcon={<OpenInNewWindowIcon fill="#fff" />}>
                                        <Box sx={{
                                py: '5px'
                              }}>
                                          <Typography sx={{
                                  color: '#fff'
                                }}> Launch</Typography>
                                        </Box>
                                      </Button> : <Button onClick={e => {
                              onInstall(integration);
                            }} variant="contained" color="primary" disable={isInstalling == integration.key} sx={{
                              backgroundColor: isInstalling == integration.key ? '#c0c0c0' : '#3898D9',
                              '&:hover': {
                                backgroundColor: '#3898D9'
                              }
                            }} startIcon={isInstalling == integration.key ? <CircularProgress sx={{
                              color: '#FFF'
                            }} size={'20px'} /> : <AddIcon fill="#fff" />}>
                                        <Box sx={{
                                py: '5px'
                              }}>
                                          <Typography sx={{
                                  color: '#fff'
                                }}>Install</Typography>
                                        </Box>
                                      </Button>}

                                    <Button sx={{
                              borderColor: '#0994F2',
                              backgroundColor: '#fff',
                              '&:hover': {
                                backgroundColor: '#F3F3F4'
                              }
                            }} onClick={e => {}} variant="outlined">
                                      <Box sx={{
                                py: '5px'
                              }}>
                                        <Typography sx={{
                                  color: '#0994F2'
                                }}>Learn More </Typography>
                                      </Box>
                                    </Button>
                                  </Box>
                                </Stack>
                              </Box>
                            </Box>
                          </Grid>)}
                      </> : <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pl: '24px'
                }}>
                        <Typography> No results</Typography>
                      </Box>}
                  </Grid>
                </Box>}
            </Stack>
          </Stack>
        </Paper>
      </div>
    </>;
};