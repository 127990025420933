import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import logo from '../assets/img/cinch-logo.svg';
import { api } from '../shared/api';
import { Loader } from '../components/loader/loader.component';
import { Box, Checkbox, Stack, Paper, Button, CircularProgress } from '@mui/material';
export class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    const done = props?.match?.params?.id === 'done';
    this.state = {
      done,
      loading: !done,
      companyName: '',
      unsubscribeReasons: [],
      toggleUnsubscribe: false
    };
    this.handleChecked = this.handleChecked.bind(this);
  }
  componentDidMount() {
    if (!this.state.done) {
      const {
        match
      } = this.props;
      api.get(`/sendgrid/unsubscribe/${match?.params?.id}`).then(({
        data
      }) => {
        let state = {
          loading: false
        };
        if (data.company && data.company.name) {
          state.companyName = data.company.name;
        }
        this.setState(state);
      }).catch(err => {
        this.setState({
          loading: false
        });
        console.log(err);
      });
    }
  }
  unsubscribe = () => {
    const {
      match
    } = this.props;
    this.setState({
      loading: true
    });
    const reasonsString = this.state.unsubscribeReasons.map(obj => obj.reason).join(', ');
    api.post(`/sendgrid/unsubscribe/${match?.params?.id}`, {
      reason: reasonsString
    }).then(() => {
      // simulate loading for user reassurance purposes
      setTimeout(() => {
        this.setState({
          loading: false,
          toggleUnsubscribe: true
        });
      }, 1000);
    }).catch(err => {
      console.log(err);
      this.setState({
        loading: false
      });
    });
  };
  handleResubscribe = () => {
    const {
      match
    } = this.props;
    this.setState({
      loading: true
    });
    api.post(`/sendgrid/subscribe/${match?.params?.id}`).then(() => {
      // simulate loading for user reassurance purposes
      this.setState({
        loading: false,
        done: true
      }), () => {
        // this.setState({ toggleUnsubscribe: false });
      };
    }).catch(err => {
      this.setState({
        loading: false
      });
      console.log(err);
    });
  };
  handleChecked = checked => {
    let {
      unsubscribeReasons
    } = this.state;
    let reasonsArray = [];

    // check if the reason is already there
    if (unsubscribeReasons.length > 0 && unsubscribeReasons[0].reason) {
      reasonsArray = unsubscribeReasons[0].reason.split(', ');
    }
    const reasonIndex = reasonsArray.indexOf(checked);
    if (reasonIndex > -1) {
      reasonsArray.splice(reasonIndex, 1);
    } else {
      reasonsArray.push(checked);
    }
    const updatedReasonsString = reasonsArray.join(', ');
    unsubscribeReasons = updatedReasonsString ? [{
      reason: updatedReasonsString
    }] : [];
    this.setState({
      unsubscribeReasons
    });
  };
  render() {
    const {
      done,
      loading,
      companyName,
      unsubscribeReasons,
      toggleUnsubscribe
    } = this.state;
    const {
      handleChecked
    } = this;
    return <Scoped css={css}>
        <div className="unsubscribe">
          <CircularProgress color="primary" style={{
          display: loading ? 'block' : 'none'
        }} />

          {loading ? <Loader overlay /> : !done ? <div className="unsubscribe__child">
              <>
                <Paper elevation={3} style={{
              padding: 20,
              borderRadius: '24px'
            }}>
                  <div style={{
                padding: 10
              }}>{!toggleUnsubscribe ? 'Unsubscribe from' : 'Subscribe to'}</div>
                  <div style={{
                padding: 20
              }}>
                    <h2 className="company-name">{companyName}</h2>
                  </div>

                  {!toggleUnsubscribe ? <>
                      <label style={{
                  fontSize: '1.2rem',
                  color: '#a3a3a3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                        If you have a moment, please share with us the reason you are unsubscribing. This will help us improve our service.
                        It is entirely optional and you can skip this step if you would like.
                      </label>

                      <Stack direction="column" sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center'
                }}>
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                          <Checkbox color="primary" onChange={e => {
                      handleChecked('I received too many emails in general');
                    }} sx={{
                      color: '#1D252D',
                      '&.Mui-checked': {
                        color: '#53A6D6'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 22
                      }
                    }} />
                          <label style={{
                      margin: 0
                    }}> I received too many emails in general</label>
                        </Box>
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                          <Checkbox color="primary" onChange={e => {
                      handleChecked('The content is no longer relevant to me');
                    }} sx={{
                      color: '#1D252D',
                      '&.Mui-checked': {
                        color: '#53A6D6'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 22
                      }
                    }} />
                          <label style={{
                      margin: 0
                    }}> The content is no longer relevant to me </label>
                        </Box>
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                          <Checkbox color="primary" onChange={e => {
                      handleChecked(`I receive too many emails from ${companyName}`);
                    }} sx={{
                      color: '#1D252D',
                      '&.Mui-checked': {
                        color: '#53A6D6'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 22
                      }
                    }} />
                          <label style={{
                      margin: 0
                    }}> I receive too many emails from {companyName}</label>
                        </Box>
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                          <Checkbox color="primary" onChange={e => {
                      handleChecked("I don't remember signing up");
                    }} sx={{
                      color: '#1D252D',
                      '&.Mui-checked': {
                        color: '#53A6D6'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 22
                      }
                    }} />
                          <label style={{
                      margin: 0
                    }}>I don't remember signing up </label>
                        </Box>
                      </Stack>
                      <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pt: '5px',
                  pb: '12px'
                }}>
                        <Button variant="contained" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '320px'
                  }} onClick={this.unsubscribe}>
                          Unsubscribe
                        </Button>
                      </Box>
                    </> : <>
                      <div style={{
                  py: '8px'
                }}>
                        <label style={{
                    fontSize: '1.2rem',
                    color: '#a3a3a3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                          If you would like to resubscribe, or you unsubscribed by mistake, please click the resubscribe button to receive
                          emails from {companyName} again.
                        </label>
                      </div>
                      <div>
                        <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: '25px',
                    pb: '12px'
                  }}>
                          <Button variant="contained" sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '320px'
                    }} onClick={this.handleResubscribe}>
                            Resubscribe
                          </Button>
                        </Box>
                      </div>
                    </>}
                </Paper>
              </>

              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pt: '5px'
          }}>
                <div className="powered-by">
                  <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>Powered by</div>{' '}
                  <span className="logo" dangerouslySetInnerHTML={{
                __html: logo
              }} />
                </div>
              </Box>
            </div> : <>
              <Paper elevation={3} style={{
            padding: 20,
            borderRadius: '24px'
          }}>
                <Stack direction="column" sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  <h2>Subscribed successfully!</h2>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '25px'
              }}>
                    <div style={{
                  py: '8px'
                }}>
                      <label style={{
                    fontSize: '1.2rem',
                    color: '#a3a3a3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                        You have successfully subscribed to {companyName}.
                      </label>
                    </div>
                  </Box>
                </Stack>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pt: '5px'
            }}>
                  <div className="powered-by">
                    <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>Powered by</div>{' '}
                    <span className="logo" dangerouslySetInnerHTML={{
                  __html: logo
                }} />
                  </div>
                </Box>
              </Paper>
            </>}
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i1"] body,body[kremling="i1"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i1"] .unsubscribe,[kremling="i1"].unsubscribe {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

[kremling="i1"] .powered-by,[kremling="i1"].powered-by {
  display: flex;
  align-items: center;
  color: #a3a3a3;
  font-size: 1.2rem;
}

[kremling="i1"] .are-you-sure,[kremling="i1"].are-you-sure {
  margin-bottom: 1.6rem;
  font-size: 2rem;
}

[kremling="i1"] .logo,[kremling="i1"].logo {
  width: 5rem;
  margin-bottom: 0.7rem;
  margin-left: 0.6rem;
  fill: #a3a3a3;
}

[kremling="i1"] .unsubscribe__child,[kremling="i1"].unsubscribe__child {
  width: 36rem;
  height: 20rem;
}`,
  id: 'i1',
  namespace: 'kremling'
};