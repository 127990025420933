import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { getField } from 'components/rules/rules.utils';
import { filterNode, findRules, validateRules } from './node-utils';
import { getFacebookAudiences, getCallToolsDisposition, getCallToolsToken, getFacebookAudiencesNoUnWrap, getMailboxPowerContactGroups } from '../../integrations/integrations.resource';
import { getSegment, getMail, getEmail, getMarket, getLocation, getSurvey } from 'shared/common.api';

// params
import { TriggerTransaction } from './parameters/trigger-transaction';
import { TriggerAbandonedCart } from './parameters/trigger-abandoned-cart';
import { TriggerAddToSchedule } from './parameters/trigger-add-to-schedule.component';
import { TriggerRecurring } from './parameters/trigger-recurring/trigger-recurring.component';
import { TriggerTime } from './parameters/trigger-time.component';
import { TriggerSegment } from './parameters/trigger-segment.component';
import { TriggerWebForm } from './parameters/trigger-webForm.component';
import { TriggerFastSensorZone } from './parameters/trigger-fast-sensor-zone';
import { TriggerSMSIncoming } from './parameters/trigger-sms-incoming.component';
import { TriggerSMSOptin } from './parameters/trigger-sms-optin.component';
import { ActionAB } from './parameters/action-ab';
import { ActionABTesting } from './parameters/action-ab-testing';
import { ActionAddToSegment } from './parameters/action-add-to-segment.component';
import { ActionContest } from './parameters/action-contest.component';
import { ActionContestRecurring } from './parameters/trigger-recurring/action-contest-recurring.component';
import { ActionRemoveFromSegment } from './parameters/action-remove-from-segment.component';
import { ActionEmail } from './parameters/action-email.component';
import { ActionFacebookAudience } from './parameters/action-facebook-audience.component';
import { ActionAdwordsAudience } from './parameters/action-adwords-audience.component';
import { ActionMail } from './parameters/action-mail.component';
import { ActionSendToCallTools } from './parameters/action-send-to-calltools.component';
import { ActionRateLimit } from './parameters/action-rate-limit.component';
import ActionWebhook from './parameters/action-webhook.component';
import { ActionGiveOffer } from './parameters/action-give-offer';
import { ActionGivePoints } from './parameters/action-give-points.component';
import { ConditionDistanceFromLocation } from './parameters/condition-distance-from-location.component';
import { ConditionEmail } from './parameters/condition-email.component';
import { ConditionCheckField } from './parameters/condition-check-field/condition-check-field.component';
import { ConditionAdvancedSQL } from './parameters/condition-advanced-sql.component';
import { ConditionWizard } from './parameters/condition-wizard.component';
import { ConditionRepeatCustomer } from './parameters/condition-repeat-customer.component';
import { ConditionValueSegment } from './parameters/condition-value-segment';
import { ConditionFastSensorCheck } from './parameters/condition-fast-sensor-check';
import { ConditionInSegment } from './parameters/condition-in-segment';
import { TriggerFastSensorDwellTime } from './parameters/trigger-fast-sensor-dwell-time';
import { ActionNotifications, notificationOptions } from './parameters/action-notifications.component';
import { ActionAudiohook } from './parameters/action-audiohook.component';
import { TriggerTimeSchedule } from './parameters/trigger-time-schedule.component';
import { ConditionHasOffer } from './parameters/condition-has-offer.component';
import { ActionEject } from './parameters/action-eject.component';
import { ActionMailboxPower } from './parameters/action-mailbox_power.component';

// images
import timeImg from '../assets/time.png';
import transactionImg from '../assets/transaction.png';
import emailImg from '../assets/email.png';
import timeDelayImg from '../assets/time-delay.png';
import mailImg from '../assets/mail.png';
import triggerRecurringImg from '../assets/trigger-recurring.png';
import aBImg from '../assets/a-b.png';
import SmsImg from '../assets/sms.png';
import MmsImg from '../assets/mms.png';
import webhookImg from '../assets/webhook.png';
import addSegmentImg from '../assets/segment-add.png';
import removeSegmentImg from '../assets/segment-remove.png';
import checkeredImg from '../assets/checkered.png';
import segmentImg from '../assets/segment.png';
import customerRepeatImg from '../assets/customer-repeat.png';
import pointOnMapImg from '../assets/point-on-map.png';
import dataFieldsImg from '../assets/data-fields.png';
import facebookImg from '../assets/facebook.png';
import googleAdsImg from '../assets/google-ads.png';
import segmentValueImg from '../assets/segment-value.png';
import webFormImg from '../assets/web-form.png';
import fastSensorImg from '../assets/fast-sensors.png';
import banImg from '../assets/ban.png';
import calendarImg from '../assets/calendar.png';
import offerImg from '../assets/price-tag.png';
import expireImg from '../assets/alarm.png';
import pointsImg from '../assets/gift.png';
import surveyImg from '../assets/clipboard.png';
import aircallImg from '../assets/aircall.png';
import callToolsImg from '../assets/calltools.png';
import cartImg from '../assets/cart.png';
import shopifyImg from '../assets/shopify.png';
import trophyImg from '../assets/trophy.png';
import notificationsImg from '../assets/notifications.png';
import zapierImg from '../assets/zapier.png';
import swellImg from '../assets/swell.png';
import userPlusImg from '../assets/user-plus.png';
import cinchImg from '../assets/cinch.png';
import audiohookImg from '../assets/audiohook.png';
import dripdropImg from '../assets/dripdrop.png';
import starImg from '../assets/star-sharp-solid.png';
import replyClockSolid from '../assets/reply-clock-solid.png';
import mailbox_powerImg from '../assets/mailbox_power.png';
import { getForms } from 'shared/forms.api';
import { getSurveys, getEmailTemplates } from 'shared/common.api';
const configureLabel = 'Click to configure';
export const triggerNodes = [{
  subType: 'trigger_transaction',
  order: 1,
  name: 'Transaction',
  img: transactionImg,
  component: TriggerTransaction,
  nodeResource: {
    description: 'This tile will initiate a journey when a customer has a trasnaction as determined by your point of sale. Configure this tile to drill down the trigger of specific locations and markets.',
    idea: 'Use this tile to initate a "Thank you" communication after a customer has made a purchase at your location. This could flow into a Review request'
    // url: '/support/tiles/transaction',
    // img: 'https://images.photowall.com/products/65874/baby-monkey.jpg?h=699',
  },
  parameters: {
    days_after: 4,
    location_ids: [],
    market_ids: []
  }
}, {
  subType: 'trigger_schedule',
  order: 2,
  name: 'Added to Schedule',
  img: calendarImg,
  component: TriggerAddToSchedule,
  nodeResource: {
    description: 'Your customer has completed a transaction. Begin this journey by selecting a specific location or market - or all of them. Your choice!',
    idea: 'Use this tile to send a Thank You message after a customer has made a purchase at your location. There, you can send a review request message to start collecting more five-star reviews.'
  },
  parameters: {
    location_ids: [],
    market_ids: [],
    status_ids: []
  }
}, {
  subType: 'trigger_time_schedule',
  order: 3,
  name: 'Schedule Time Trigger',
  img: calendarImg,
  component: TriggerTimeSchedule,
  nodeResource: {
    description: "This journey's trigger is a scheduled appointment that falls within a specific time frame. You can choose the window of time you want to check for appointments.",
    idea: "Send a reminder message the day before a customer's scheduled appointment. This can help reduce the number of no-show customers."
  },
  parameters: {
    amount: 15,
    unit: 'minutes',
    direction: 'ago'
  },
  validate(params) {
    return !!(!isNaN(parseFloat(params.amount)) && params.unit && params.direction);
  },
  buildLabel({
    params
  }) {
    if (isNaN(parseFloat(params.amount)) || !params.unit || !params.direction) {
      return Promise.resolve(configureLabel);
    } else {
      return Promise.resolve(`${params.amount} ${params.unit} ${params.direction}`);
    }
  }
}, {
  subType: 'trigger_time',
  order: 4,
  name: 'Time Trigger',
  img: timeImg,
  component: TriggerTime,
  nodeResource: {
    description: 'This tile lets you send a group of customers through a journey—all at one time. Choose the customer segment as well as the time to start the journey.',
    idea: 'Send a one-time alert to local customers to share upcoming store closures or new holiday hours.'
  },
  parameters: {
    segment_id: null,
    datetime: null
  },
  validate(params) {
    return !!(params.segment_id && params.datetime);
  },
  buildLabel({
    params
  }) {
    if (params.datetime) {
      const time = DateTime.fromISO(params.datetime);
      return Promise.resolve(time.toFormat('d LLL yyyy - h:mm') + time.toFormat('a').toLowerCase());
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'trigger_recurring',
  order: 5,
  name: 'Recurring Time Trigger',
  img: triggerRecurringImg,
  component: TriggerRecurring,
  nodeResource: {
    description: 'Send customers through a journey more than one time. Choose the customer segment and the number of times you want to send them through the journey.',
    idea: "Send customers a vehicle service reminder 30 days before it's due. "
  },
  parameters: {
    segment_id: null,
    pattern: 'daily',
    patternOption: 'everyDay',
    rrule: null,
    timezone: null
  },
  validate(params) {
    return !!(params.segment_id && params.rrule);
  }
}, {
  subType: 'trigger_segment',
  order: 6,
  name: 'Static Segment Trigger',
  img: segmentImg,
  component: TriggerSegment,
  nodeResource: {
    description: 'Identify a group of customers from a static segment, then send them on a journey. Choose the parameters of the customer segment as well as the journey timing.',
    idea: 'Send a message to all the customers in your database who have not completed a transaction in the last month. Let them know about an upcoming sale or new product.'
  },
  parameters: {
    segment_id: null,
    audience: 'all'
  },
  validate(params) {
    return !!params.segment_id;
  },
  buildLabel({
    params
  }) {
    if (params.segment_id) {
      return getSegment(params.segment_id).then(({
        data
      }) => data.name);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'trigger_web_form',
  order: 7,
  name: 'Web Form',
  img: webFormImg,
  component: TriggerWebForm,
  nodeResource: {
    description: 'This journey is triggered when a customer completes a connected web form. Select a specific web form or landing page.',
    idea: "You've got a lead! Now send them follow-up information to keep them warm."
  },
  buildLabel({
    params,
    asCompany
  }) {
    if (params.use_form) {
      if (params.form_name) {
        return Promise.resolve(params.form_name);
      }
      return getForms({
        company: asCompany.id,
        id: params.formId,
        limit: 1
      }).then(res => {
        const forms = res.data.results;
        return get(forms.find(form => form.id === params.formId), 'name', '');
      });
    }
    if (params.use_landing_page) {
      return Promise.resolve(params.form_name);
    }
    return Promise.resolve(params.name || 'Custom');
  },
  validate(params) {
    return !params.use_form || !!params.formId && !params.form_pending;
  },
  initParams() {
    return {
      name: null,
      use_form: true,
      formId: null,
      uuid: uuid()
    };
  }
}, {
  subType: 'trigger_fast_sensor_zone',
  name: 'FastSensor Zone',
  img: fastSensorImg,
  component: TriggerFastSensorZone,
  parameters: {
    on: null,
    zone_id: null
  },
  buildLabel() {
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'trigger_fast_sensor_dwell_time',
  name: 'FastSensor Dwell Time',
  img: fastSensorImg,
  component: TriggerFastSensorDwellTime,
  parameters: {
    dwell_time: null,
    zone_id: null
  },
  buildLabel() {
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'trigger_abandoned_cart',
  order: 8,
  name: 'Abandoned Cart',
  img: cartImg,
  component: TriggerAbandonedCart,
  nodeResource: {
    description: "Your customer added something to their cart, but it's been two hours, and they haven't completed the checkout process. In this journey, choose the specific location or market you want to include. ",
    idea: 'Nudge customers with an email or text message asking, “Did you forget something in your cart? Complete your purchase now!”'
  },
  parameters: {
    location_ids: [],
    market_ids: []
  }
}, {
  subType: 'trigger_shopify_fulfillment',
  order: 20,
  name: 'Shopify Fulfillment',
  img: shopifyImg,
  component: TriggerTransaction,
  parameters: {
    location_ids: [],
    market_ids: []
  },
  onEventList: [{
    id: 'label_printed',
    name: 'Label Printed'
  }, {
    id: 'label_purchased',
    name: 'Label Purchased'
  }, {
    id: 'ready_for_pickup',
    name: 'Ready For Pickup'
  }, {
    id: 'confirmed',
    name: 'Confirmed'
  }, {
    id: 'in_transit',
    name: 'In Transit'
  }, {
    id: 'out_for_delivery',
    name: 'Out For Delivery'
  }, {
    id: 'delivered',
    name: 'Delivered'
  }, {
    id: 'failure',
    name: 'Failure'
  }]
}, {
  subType: 'trigger_sms_incoming',
  order: 10,
  name: 'Incoming SMS',
  img: SmsImg,
  component: TriggerSMSIncoming,
  nodeResource: {
    description: 'This journey is triggered when a customer sends you a text message with a specific KEYWORD. This allows you to promote a specific product or service and connect it to a keyword that you choose.',
    idea: 'Send a customer a coupon when they text you “COUPON.”'
  },
  parameters: {
    method: 'equals',
    value: null,
    sentTo: null
  },
  buildLabel({
    params
  }) {
    let label = '';
    if (params.method === 'equals') {
      label += 'Exact Match of ';
    }
    if (params.method === 'contains') {
      label += 'Partial Match of ';
    }
    if (params.value) {
      label += params.value;
    }
    return Promise.resolve(label);
  },
  validate(params) {
    return !!params.method && params.method !== '' && params.value && params.value !== '';
  }
}, {
  subType: 'trigger_sms_optin',
  order: 11,
  name: 'Opt-in SMS',
  img: SmsImg,
  component: TriggerSMSOptin,
  nodeResource: {
    description: 'Invite customers to join your store or company text club. This journey begins when a customer sends you the opt-in text message.',
    idea: 'Send your customer a confirmation text and welcome them into your company text club.'
  },
  parameters: {
    sentTo: null,
    sentToLabel: null
  },
  buildLabel({
    params
  }) {
    let label = '';
    if (params.sentToLabel) {
      label += ` Sent to ${params.sentToLabel}`;
    }
    return Promise.resolve(label);
  }
}].map(node => ({
  type: 'trigger',
  connectTo: ['action', 'condition'],
  connectFrom: [],
  color: '3EB87B',
  //colorShade: '3a6636',
  ...node
})).filter(node => {
  return filterNode(node, /fast_sensor/g);
});
export const actionNodes = [{
  subType: 'action_time_delay',
  order: 1,
  name: 'Time Delay',
  img: timeDelayImg,
  color: 'FFB82B',
  nodeResource: {
    description: "This tile holds customers for a specific period of time. Customers won't move to the next step of the journey until the selected time has passed. Choose any period of time from minutes to years. You can also enable day and time options.",
    idea: 'Use this tile to ensure your customers have had enough time to interact with an email.'
  },
  parameters: {
    unit: 'hours',
    amount: 1,
    wait_until: {}
  },
  componentParams: [{
    label: 'Delay',
    type: 'delay',
    key: 'delay'
  }],
  buildLabel({
    params
  }) {
    return Promise.resolve(`${params.amount} ${params.amount > 1 ? params.unit : params.unit.split('').splice(0, params.unit.length - 1).join('')}`);
  }
}, {
  subType: 'action_await_sms_reply',
  order: 2,
  name: 'Await SMS Reply',
  img: replyClockSolid,
  color: 'FFB82B',
  nodeResource: {
    description: 'This action is on hold until your customer responds, or a specific amount of time goes by. Select the period of time and any keywords that you want to trigger the next action.',
    idea: "Use this tile to act as a text bot that responds differently based on the customer's response."
  },
  parameters: {
    keywords: [],
    unit: 'hours',
    amount: 1,
    wait_until: {}
  },
  componentParams: [{
    type: 'from-phone',
    label: 'Replies Sent To',
    key: 'to',
    additionalOptions: [{
      id: '',
      phone: 'Any Phone Number'
    }]
  }, {
    type: 'multi-item',
    label: 'Replies',
    addLabel: 'New Reply',
    key: 'keywords',
    items: [{
      type: 'select',
      options: [{
        key: 'contains',
        val: 'Contains'
      }, {
        key: 'exact',
        val: 'Exact'
      }],
      key: 'type'
    }, {
      type: 'input',
      key: 'keyword'
    }]
  }, {
    type: 'delay',
    label: 'Time To Wait For Response',
    key: 'delay'
  }],
  getEventList({
    node
  }) {
    return Promise.resolve([...(node.parameters.keywords || []).map(keyword => ({
      id: keyword.keyword,
      name: keyword.keyword
    })), {
      id: 'other',
      name: 'Other'
    }, {
      id: 'no reply',
      name: 'No Reply'
    }]);
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(`Wait ${params.amount} ${params.amount > 1 ? params.unit : params.unit.substring(0, params.unit.length - 1)}`);
  }
}, {
  subType: 'action_rate_limit',
  order: 3,
  name: 'Rate Limit',
  img: timeDelayImg,
  component: ActionRateLimit,
  color: 'FFB82B',
  nodeResource: {
    description: 'This tile lets you limit the number of customers who move to the next tile. Set the number over a specific period of time.',
    idea: 'Want to stay on budget with direct mailers? Use this tile to limit the number of mailers you send out each month.'
  },
  parameters: {
    unit: 'hours',
    amount: 1,
    customers: 1000,
    wait_until: {}
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(`${params.customers} every ${params.amount} ${params.amount > 1 ? params.unit : params.unit.split('').splice(0, params.unit.length - 1).join('')}`);
  }
}, {
  subType: 'action_mail',
  order: 7,
  name: 'Send Mailer',
  img: mailImg,
  component: ActionMail,
  nodeResource: {
    description: 'This tile sends a physical mailer to the address listed under each customer (or current resident). Choose from any of your published mailer templates.',
    idea: 'Send your customers postcards in the mail to remind them of upcoming services or new product releases.'
  },
  parameters: {
    template_id: null,
    or_current_resident: true
  },
  onEventList: [{
    id: 'sent',
    name: 'Sent'
  }, {
    id: 'bad_address',
    name: 'Bad Address'
  }, {
    id: 'continue',
    name: 'Continue'
  }],
  validate(params) {
    return !!params.template_id;
  },
  buildLabel({
    params
  }) {
    if (params.template_id) {
      return getMail(params.template_id).then(({
        data
      }) => data.name);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_email',
  order: 4,
  name: 'Send Email',
  img: emailImg,
  component: ActionEmail,
  nodeResource: {
    description: 'Send an email to your customer. Choose from any published email templates.',
    idea: 'With this tile, you can begin building a robust email nurture series—or simply send a single email.'
  },
  onEventList: [{
    id: 'continue',
    name: 'Continue'
  }, {
    id: 'processed',
    name: 'Processed'
  }, {
    id: 'delivered',
    name: 'Delivered'
  }, {
    id: 'bounce',
    name: 'Bounced'
  }, {
    id: 'open',
    name: 'Opened'
  }, {
    id: 'click',
    name: 'Clicked'
  }, {
    id: 'spamreport',
    name: 'Spam Report'
  }, {
    id: 'unsubscribe',
    name: 'Unsubscribed'
  }],
  modalWidth: 600,
  parameters: {
    isDraft: false,
    template_id: null,
    optStatuses: ['subscribed', 'unknown', 'pending']
  },
  validate(params) {
    return !!params.template_id && !params.isDraft;
  },
  buildLabel({
    params
  }) {
    if (params.template_id) {
      return getEmail(params.template_id).then(({
        data
      }) => data.name);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_notification',
  order: 8,
  name: 'Send Notification',
  img: notificationsImg,
  component: ActionNotifications,
  nodeResource: {
    description: 'This tile sends an internal email or text message to one of your contacts. Choose an SMS text message or an email. Just select the recipient and sender. Then insert the message. ',
    idea: 'Want to keep an eye on low-star Google reviews? Use this tile to notify a team member any time a customer leaves a review with fewer than four stars. This ensures someone is immediately reaching out to that customer and helping to resolve any issues.'
  },
  parameters: {
    method: 'sms',
    message_type: 'custom',
    message: null,
    from: null,
    to: null,
    email_template: null,
    notification_option: 'custom',
    alert_level: null
  },
  validate(params) {
    if (!params.method) {
      // We need a method
      return false;
    }
    if (params.notification_option === 'custom') {
      // custom is the old functionality, and needs a 'to'
      return !!params.to;
    } else if (params.notification_option === 'notification_list') {
      // the notification list needs an alert level selected
      return !!params.alert_level;
    }
    if (params.method === 'sms') {
      // If we are an SMS then we need a message and to
      return !!params.message && !!params.to;
    } else if (params.message_type === 'template') {
      // If this is a template email make sure we have a template
      return !!params.email_template && !!params.to && !!params.from;
    } else {
      // If this is a custom email
      return !!params.message && !!params.to && !!params.from;
    }
  },
  buildLabel({
    params
  }) {
    if (params.method === 'email' && params.message_type === 'template') {
      return getEmail(params.email_template).then(({
        data
      }) => data.name);
    }
    return Promise.resolve(params.message ? params.message : configureLabel);
  }
}, {
  subType: 'action_webhook',
  order: 21,
  name: 'Webhook',
  img: webhookImg,
  component: ActionWebhook,
  nodeResource: {
    description: 'Send data to the specified URL. Simply insert a URL, state, and indicate if you would like a single record.',
    idea: 'Send a webhook to connect the information from Cinch to another platform.'
  },
  onEventList: [{
    id: 'continue',
    name: 'Continue'
  }, {
    id: 'alpha',
    name: 'Alpha'
  }, {
    id: 'bravo',
    name: 'Bravo'
  }, {
    id: 'charlie',
    name: 'Charlie'
  }, {
    id: 'delta',
    name: 'Delta'
  }],
  parameters: {
    message: null
  },
  validate(params) {
    return !!params.uri;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(params.uri ? params.uri : configureLabel);
  }
}, {
  subType: 'action_ab',
  order: 10,
  name: 'A/B Split',
  img: aBImg,
  component: ActionAB,
  nodeResource: {
    description: 'This tile splits your customers into two groups so you can easily test messages. You can choose what percentage of customers you want in each group.',
    idea: 'Want to see which email gets the best engagement? Use this tile to send out different versions and fine-tune your content.'
  },
  onEventList: [{
    id: 'group_a',
    name: 'Group A'
  }, {
    id: 'group_b',
    name: 'Group B'
  }],
  modalWidth: 300,
  parameters: {
    group_a: 50
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(`${params.group_a}/${100 - params.group_a}`);
  }
}, {
  subType: 'action_ab_testing',
  order: 11,
  name: 'A/B Testing',
  img: aBImg,
  component: ActionABTesting,
  nodeResource: {
    description: 'This tile splits your customers into multiple groups so you can easily test messages. You can choose what percentage of customers you want in each group.',
    idea: 'Split your customers into various groups to test different messages. This will help you determine which message is most effective.'
  },
  getEventList({
    node
  }) {
    return Promise.resolve(node.parameters.groups.map(group => ({
      id: group.name,
      name: group.name
    })));
  },
  modalWidth: 300,
  parameters: {
    groups: [{
      id: uuid(),
      name: 'Group A',
      percentage: 50
    }, {
      id: uuid(),
      name: 'Group B',
      percentage: 50
    }]
  },
  validate(params) {
    const groups = params.groups || [];
    if (groups.length < 2) {
      return false;
    }
    let groupNames = [];
    for (let i in groups) {
      if (groupNames.includes(groups[i].name)) {
        return false;
      }
      groupNames.push(groups[i].name);
    }
    const sum = groups.reduce((s, group) => s + group.percentage, 0);
    if (sum !== 100) {
      return false;
    }
    return true;
  },
  buildLabel({
    params
  }) {
    if (!params.groups || params.groups.length === 0) {
      return Promise.resolve(configureLabel);
    }
    return Promise.resolve(Object.values(params.groups.map(g => g.percentage)).join('/'));
  }
}, {
  subType: 'action_add_segment',
  order: 12,
  name: 'Add to Static Segment',
  img: addSegmentImg,
  component: ActionAddToSegment,
  nodeResource: {
    description: 'Add a customer to an existing static segment. Select the static segment or create a new one.',
    idea: 'Use this tile to create a list of new customers that you can separate from existing customers. '
  },
  parameters: {
    segment_id: null,
    session: null
  },
  validate(params) {
    return !!params.segment_id;
  },
  buildLabel({
    params
  }) {
    if (params.segment_id) {
      return getSegment(params.segment_id).then(({
        data
      }) => data.name);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_remove_segment',
  order: 13,
  name: 'Remove from Static Segment',
  img: removeSegmentImg,
  component: ActionRemoveFromSegment,
  nodeResource: {
    description: 'This tile removes a customer from a static segment. Select the static segment or create a new one.',
    idea: 'Pair this tile with the Add to Static Segment. This lets you target new customers on a separate list for a certain amount of time, then remove them.'
  },
  parameters: {
    segment_id: null,
    session: null
  },
  validate(params) {
    return !!params.segment_id;
  },
  buildLabel({
    params
  }) {
    if (params.segment_id) {
      return getSegment(params.segment_id).then(({
        data
      }) => data.name);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_add_facebook',
  order: 14,
  name: 'Add to Facebook Audience',
  img: facebookImg,
  component: ActionFacebookAudience,
  nodeResource: {
    description: 'Add a customer to an existing Facebook audience. Just select the audience and remember, this action must be configured in Facebook.',
    idea: 'Place a customer into a Facebook ad group for further targeting.'
  },
  parameters: {
    audience_id: null
  },
  validate(params) {
    return !!params.audience_id;
  },
  buildLabel({
    params,
    asCompany
  }) {
    if (params.audience_id) {
      return getFacebookAudiences({
        company: asCompany.id,
        limit: 1000
      }).then(({
        results
      }) => {
        const audience = results.find(a => a.id === params.audience_id);
        return audience ? audience.name : configureLabel;
      });
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_remove_facebook',
  order: 15,
  name: 'Remove from Facebook Audience',
  img: facebookImg,
  component: ActionFacebookAudience,
  nodeResource: {
    description: 'Remove a customer from an existing Facebook audience. Just select the audience and remember, this action must be configured in Facebook. ',
    idea: 'Remove a non-responsive customer from a Facebook ad group.'
  },
  parameters: {
    audience_id: null
  },
  validate(params) {
    return !!params.audience_id;
  },
  buildLabel({
    params,
    asCompany
  }) {
    if (params.audience_id) {
      return getFacebookAudiences({
        company: asCompany.id,
        limit: 1000
      }).then(({
        results
      }) => {
        const audience = results.find(a => a.id === params.audience_id);
        return audience ? audience.name : configureLabel;
      });
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_add_adwords',
  order: 16,
  name: 'Add to Google Ads Audience',
  img: googleAdsImg,
  component: ActionAdwordsAudience,
  nodeResource: {
    description: 'Add a customer to an existing Google Ads audience. Just select the audience and remember, this action must be configured in Google Ads.',
    idea: 'Place a customer into a Google Ads group for further targeting.'
  },
  parameters: {
    audience_id: null,
    audience_name: null
  },
  validate(params) {
    return !!params.audience_id;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(`${params.audience_name || ''}`);
  }
}, {
  subType: 'action_remove_adwords',
  order: 17,
  name: 'Remove from Google Ads Audience',
  img: googleAdsImg,
  component: ActionAdwordsAudience,
  nodeResource: {
    description: 'Remove a customer from an existing Google Ads audience. Just select the audience and remember, this action must be configured in Google Ads.',
    idea: 'Remove a non-responsive customer from a Google Ads group.'
  },
  parameters: {
    audience_id: null,
    audience_name: null
  },
  validate(params) {
    return !!params.audience_id;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(`${params.audience_name || ''}`);
  }
}, {
  subType: 'action_add_mailbox_power',
  order: 18,
  name: 'Add to Mailbox Power',
  img: mailbox_powerImg,
  component: ActionMailboxPower,
  nodeResource: {
    description: 'Add a customer to an existing Mailbox Power audience. Just select the audience and remember, this action must be configured in Mailbox Power.',
    idea: 'Send a treat or gift to your highest valued customers.'
  },
  parameters: {
    contact_group_id: null
  },
  validate(params) {
    return !!params.contact_group_id;
  },
  buildLabel({
    params,
    asCompany
  }) {
    if (params.contact_group_id) {
      return getMailboxPowerContactGroups({
        company: asCompany.id,
        limit: 1000
      }).then(results => {
        const contact_group = results?.find(a => a.ID === params.contact_group_id);
        return contact_group ? contact_group.NAME : configureLabel;
      });
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_remove_mailbox_power',
  order: 19,
  name: 'Remove from Mailbox Power',
  img: mailbox_powerImg,
  component: ActionMailboxPower,
  nodeResource: {
    description: 'Remove a customer from an existing Mailbox Power audience. Just select the audience and remember, this action must be configured in Mailbox Power.',
    idea: 'Remove a customer from the Mailbox Power group.'
  },
  parameters: {
    contact_group_id: null
  },
  validate(params) {
    return !!params.contact_group_id;
  },
  buildLabel({
    params,
    asCompany
  }) {
    if (params.contact_group_id) {
      return getMailboxPowerContactGroups({
        company: asCompany.id,
        limit: 1000
      }).then(results => {
        const contact_group = results?.find(a => a.ID === params.contact_group_id);
        return contact_group ? contact_group.NAME : configureLabel;
      });
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_give_offer',
  order: 29,
  name: 'Give Offer',
  img: cinchImg,
  component: ActionGiveOffer,
  parameters: {
    coupon_id: null,
    coupon_name: null
  },
  onEventList: [{
    id: 'in_loyalty',
    name: 'In Loyalty'
  }, {
    id: 'not_in_loyalty',
    name: 'Not In Loyalty'
  }, {
    id: 'continue',
    name: 'Continue'
  }],
  validate(params) {
    return !!params.coupon_id;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(`${params.coupon_name || ''}`);
  }
}, {
  subType: 'action_give_points',
  order: 30,
  name: 'Action Give Points',
  img: pointsImg,
  component: ActionGivePoints,
  parameters: {
    action_type: null,
    points: null
  },
  validate(params) {
    return !!params.points;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(`${params.points || ''}`);
  }
}, {
  subType: 'action_send_survey_email',
  order: 20,
  name: 'Send Survey Email',
  img: surveyImg,
  component: ActionEmail,
  nodeResource: {
    description: 'This tile sends an email to a customer with an attached survey. Select the email template that houses the survey link, then select the survey.',
    idea: 'Gather valuable feedback with an email survey.'
  },
  onEventList: [{
    id: 'continue',
    name: 'Continue'
  }, {
    id: 'sent',
    name: 'Sent'
  }, {
    id: 'started',
    name: 'Started'
  }, {
    id: 'completed',
    name: 'Completed'
  }],
  modalWidth: 600,
  parameters: {
    template_id: null,
    survey_id: null,
    optStatuses: ['subscribed', 'unknown', 'pending'],
    resend: ''
  },
  validate(params) {
    return !!params.template_id && !!params.survey_id;
  },
  buildLabel({
    params
  }) {
    if (params.survey_id) {
      return getSurvey(params.survey_id).then(({
        data
      }) => data.title);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'action_eject',
  order: 9,
  name: 'End Journey',
  img: checkeredImg,
  component: ActionEject,
  nodeResource: {
    description: "This tile indicates the end of a customer journey. Select the journey status and the reason you're ending the journey here. These details are used for data purposes and can be viewed on the Details page.",
    idea: "This tile removes customers who don't fit the journey requirements. Cinch also uses this tile to remove company assets from messaging journeys, so a business owner doesn't get an overload of messages for their fleet of company vehicles, for example. "
  },
  parameters: {
    status: null,
    reason: null
  },
  validate(params) {
    return !!params.status;
  },
  buildLabel({
    params
  }) {
    if (params.status) {
      return Promise.resolve(params.status);
    }
    return Promise.resolve(configureLabel);
  },
  noLink: true
}, {
  subType: 'action_ban',
  order: 28,
  name: 'Ban from Journey',
  img: banImg,
  color: 'EF3C34',
  //colorShade: 'b83206',
  component: null,
  noLink: true
}, {
  subType: 'action_send_to_aircall',
  order: 33,
  name: 'Send to Aircall',
  img: aircallImg,
  component: null
}, {
  subType: 'action_send_to_calltools',
  order: 34,
  name: 'Send to CallTools',
  img: callToolsImg,
  component: ActionSendToCallTools,
  parameters: {
    list_id: null,
    list_name: null,
    token_id: null
  },
  validate(params) {
    return !!params.list_id;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(params.list_name || '');
  },
  getEvent(event) {
    if (event === 'continue') {
      return Promise.resolve('Continue');
    }

    // TODO filter by company as well to prevent super user from seeing the wrong thing.
    return getCallToolsDisposition(event).then(disposition => disposition.name);
  },
  getEventList({
    asCompany
  }) {
    return getCallToolsToken({
      company: asCompany.id
    }).then(({
      results
    }) => {
      return results.reduce((events, token) => {
        return events.concat(token.dispositions);
      }, []);
    }).then(events => events.concat([{
      id: 'continue',
      key: 'continue',
      name: 'Continue'
    }]));
  }
}, {
  subType: 'action_contest',
  order: 22,
  name: 'Contest Winners',
  img: trophyImg,
  component: ActionContest,
  nodeResource: {
    description: 'This tile selects a random contest winner. Select the number of winners along with the date and time.',
    idea: 'Create a one-time giveaway to boost customer engagement.'
  },
  onEventList: [{
    id: 'winner_1',
    name: '1st Place'
  }, {
    id: 'winner_2',
    name: '2nd Place'
  }, {
    id: 'winner_3',
    name: '3rd Place'
  }, {
    id: 'loser',
    name: 'Losers'
  }, {
    id: 'arrived_late',
    name: 'Arrived Late'
  }],
  parameters: {
    winners_1: 1,
    winners_2: 0,
    winners_3: 0,
    allow_multiple: false,
    datetime: null
  },
  validate(params) {
    return !!params.datetime;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(params.datetime && moment(params.datetime).format('MM/DD/YYYY h:mm a') || configureLabel);
  }
}, {
  subType: 'action_contest_recurring',
  order: 23,
  name: 'Recurring Contest Winners',
  img: trophyImg,
  component: ActionContestRecurring,
  nodeResource: {
    description: 'This tile selects a random winner on a recurring time schedule. Select the number of winners along with the cadence.',
    idea: 'Create a monthly giveaway to boost customer engagement over the long term.'
  },
  onEventList: [{
    id: 'winner_1',
    name: '1st Place'
  }, {
    id: 'winner_2',
    name: '2nd Place'
  }, {
    id: 'winner_3',
    name: '3rd Place'
  }, {
    id: 'loser',
    name: 'Losers'
  }],
  parameters: {
    winners_1: 1,
    winners_2: 0,
    winners_3: 0,
    allow_multiple: false,
    pattern: 'daily',
    patternOption: 'everyDay',
    rrule: null,
    timezone: null
  }
}, {
  subType: 'action_zapier',
  order: 24,
  name: 'To Zapier',
  img: zapierImg,
  component: null
}, {
  subType: 'action_add_audiohook',
  order: 31,
  name: 'Add to Audiohook',
  img: audiohookImg,
  component: ActionAudiohook,
  validate(params) {
    return !!params.credential_id && !!params.audience_id;
  },
  parameters: {
    credential_id: null,
    audience_id: null
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(params.audience_name || '');
  }
}, {
  subType: 'action_remove_audiohook',
  order: 32,
  name: 'Remove from Audiohook',
  img: audiohookImg,
  component: ActionAudiohook,
  validate(params) {
    return !!params.credential_id && !!params.audience_id;
  },
  parameters: {
    credential_id: null,
    audience_id: null
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(params.audience_name || '');
  }
}, {
  subType: 'action_add_dripdrop',
  order: 25,
  name: 'Add to DripDrop',
  nodeResource: {
    description: 'Add a customer to an existing DripDrop mail campaign. The DripDrop campaign must be created externally.',
    idea: 'Send ultra-high-gloss postcards to customers. These are perfect for break-away gift cards.'
  },
  img: dripdropImg,
  component: null
}, {
  subType: 'action_reply_review',
  order: 26,
  name: 'Reply to Review',
  nodeResource: {
    description: 'This tile posts a public response to the customer New Review tile that triggered the journey. Insert the reply message.',
    idea: "Don't have time to respond to every customer review left on Google? Use this tile to create an automated review response."
  },
  img: starImg,
  componentParams: [{
    label: 'Reply',
    type: 'review-message',
    key: 'message'
  }],
  modalWidth: 400,
  parameters: {
    message: null
  },
  validate(params) {
    return !!params.message;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(params.message ? params.message : configureLabel);
  }
}].map(node => ({
  type: 'action',
  connectTo: ['action', 'condition'],
  connectFrom: ['trigger', 'action', 'condition'],
  color: 'E77A16',
  //: 'bf621b',
  ...node
})).filter(node => {
  return filterNode(node, /action_ban|action_give_points/g);
});
export const conditionNodes = [{
  subType: 'condition_email',
  order: 2,
  name: 'Check Email Status',
  img: emailImg,
  component: ConditionEmail,
  nodeResource: {
    description: 'Check the criteria status of an email. Select the published email you want to check.',
    idea: 'Want to see if a customer has opened an email you recently sent? If the email is still unopened, resend the email.'
  },
  onEventList: [{
    id: 'has_opened',
    name: 'Has Opened'
  }, {
    id: 'has_not_opened',
    name: 'Has Not Opened'
  }, {
    id: 'has_clicked',
    name: 'Has Clicked'
  }, {
    id: 'has_not_clicked',
    name: 'Has Not Clicked'
  }, {
    id: 'has_sent',
    name: 'Has Sent'
  }, {
    id: 'has_not_sent',
    name: 'Has Not Sent'
  }, {
    id: 'bounced',
    name: 'Bounced'
  }],
  parameters: {
    template_id: null
  },
  validate(params) {
    return !!params.template_id;
  },
  buildLabel({
    params
  }) {
    if (params.template_id) {
      return getEmail(params.template_id).then(({
        data
      }) => data.name);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'condition_transaction',
  order: 4,
  name: 'Has Transaction',
  img: transactionImg,
  nodeResource: {
    description: 'See if a customer has completed a transaction since the journey began.',
    idea: 'Want to know if your messages are working? Use this tile to check if a customer has made a transaction since receiving your communications.'
  },
  component: null,
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }]
}, {
  subType: 'condition_repeat',
  order: 3,
  name: 'Repeat Customer',
  img: customerRepeatImg,
  component: ConditionRepeatCustomer,
  nodeResource: {
    description: 'See if a customer is new (made just one transaction) or if they are repeat customers (made two or more transactions). Select a specific location or market, if desired.',
    idea: "It's important to target new and repeat customers differently. Use this tile to send one email to new customers and another email to repeat customers."
  },
  parameters: {
    market_ids: [],
    location_ids: []
  },
  onEventList: [{
    id: 'new',
    name: 'New'
  }, {
    id: 'repeat',
    name: 'Repeat'
  }, {
    id: 'none',
    name: 'None'
  }]
}, {
  subType: 'condition_distance',
  order: 5,
  name: 'Distance from Location',
  img: pointOnMapImg,
  component: ConditionDistanceFromLocation,
  nodeResource: {
    description: 'Check the addresses and proximity of your customers. Determine the distance you want to check based on your store locations.',
    idea: 'Want to send out mailers to customers who live close to your locations? Now you can.'
  },
  parameters: {
    market_ids: [],
    location_ids: [],
    distance: 20
  },
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }, {
    id: 'no_location',
    name: 'No Location'
  }],
  buildLabel({
    params
  }) {
    if (params.market_ids.length === 1) {
      return getMarket(params.market_ids[0]).then(({
        data
      }) => `${params.distance} miles - ${data.name}`);
    } else if (params.market_ids.length > 1) {
      return Promise.resolve(`${params.distance} miles - Multiple markets`);
    } else if (params.location_ids.length === 0) {
      return Promise.resolve(`${params.distance} miles - All locations`);
    } else if (params.location_ids.length === 1) {
      return getLocation(params.location_ids[0]).then(({
        data
      }) => `${params.distance} miles - ${data.name}`);
    } else if (params.location_ids.length > 1) {
      return Promise.resolve(`${params.distance} miles - Multiple locations`);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'condition_check_field',
  order: 1,
  name: 'Check Data Field',
  img: dataFieldsImg,
  component: ConditionCheckField,
  nodeResource: {
    description: 'Create a rule and build a Yes / No path. ',
    idea: 'Use this tile to check if a customer is included within a specified group.'
  },
  modalWidth: 800,
  parameters: {
    query: {
      and: []
    },
    session: null
  },
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }],
  validate(params) {
    let sessionCheck = true;
    const session = params?.session;
    if (session) {
      if ('type' in session) {
        sessionCheck = !!session.type;
      }
    }
    const rules = validateRules(params.query);
    return rules && sessionCheck;
  },
  buildLabel({
    params,
    companyFields
  }) {
    const rules = findRules(params.query);
    const fieldRules = rules.map(r => r.field);
    const modelsRules = rules.map(r => r.model);
    const modelsFiltered = companyFields?.filter(m => modelsRules.includes(m.id));
    return Promise.resolve(rules.length && modelsFiltered ? fieldRules.map(rule => getField(rule, modelsFiltered).name).join(', ') : configureLabel);
  }
}, {
  subType: 'condition_advanced_sql',
  order: 10,
  name: 'Advanced SQL Condition',
  img: dataFieldsImg,
  component: ConditionAdvancedSQL,
  modalWidth: 500,
  parameters: {
    query: null
  },
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }],
  visible({
    hasPermission
  }) {
    return hasPermission('journey.manage_private_nodes');
  },
  validate(params) {
    return !!params.query;
  }
}, {
  subType: 'condition_wizard',
  order: 11,
  img: dataFieldsImg,
  component: ConditionWizard,
  modalWidth: 800,
  parameters: {
    query: null,
    name: 'Wizard',
    description: '',
    fields: [],
    values: {},
    events: [{
      id: 'yes',
      name: 'Yes'
    }, {
      id: 'no',
      name: 'No'
    }],
    target_database: 'postgres'
  },
  getEventList({
    node
  }) {
    return Promise.resolve(node.parameters.events || [{
      id: 'yes',
      name: 'Yes'
    }, {
      id: 'no',
      name: 'No'
    }]);
  },
  visible({
    hasPermission
  }) {
    return hasPermission('journey.manage_private_nodes');
  },
  validate(params) {
    // TODO implement parameters needed
    return !!params.query;
  }
}, {
  subType: 'condition_value_segment',
  order: 12,
  name: 'Value Segment',
  img: segmentValueImg,
  component: ConditionValueSegment,
  parameters: {
    value_segments: []
  },
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }],
  validate(params) {
    return !!params.value_segments.length;
  },
  buildLabel({
    params
  }) {
    if (params.value_segments.length) {
      const labels = {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        no_value: 'None'
      };
      return Promise.resolve(params.value_segments.sort().map(segment => labels[segment]).join(', '));
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'condition_fast_sensor_check',
  name: 'FastSensor Zone Check',
  img: fastSensorImg,
  component: ConditionFastSensorCheck,
  parameters: {
    zone_id: null
  },
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }],
  buildLabel() {
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'condition_survey_satisfaction',
  order: 13,
  name: 'Survey Satisfaction',
  img: surveyImg,
  component: null,
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }]
}, {
  subType: 'condition_in_segment',
  order: 7,
  name: 'In Static Segment',
  img: segmentImg,
  component: ConditionInSegment,
  nodeResource: {
    description: 'See if a customer is currently in a static segment. Select which static segment you want to check.',
    idea: "Use this tile to check if a customer was already messaged in the last 30 days. This helps ensure you don't overload any customers with too many messages within a certain time frame."
  },
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }],
  parameters: {
    segment_id: null
  },
  validate(params) {
    return !!params.segment_id;
  },
  buildLabel({
    params
  }) {
    if (params.segment_id) {
      return getSegment(params.segment_id).then(({
        data
      }) => data.name);
    }
    return Promise.resolve(configureLabel);
  }
}, {
  subType: 'condition_sms_subscription',
  order: 6,
  name: 'SMS Subscription Status',
  img: SmsImg,
  component: null,
  onEventList: [{
    id: 'opt_in',
    name: 'Opt In'
  }, {
    id: 'not_opt_in',
    name: 'Not Opt In'
  }, {
    id: 'opt_out',
    name: 'Opt Out'
  }, {
    id: 'suppressed',
    name: 'Suppressed'
  }, {
    id: 'pending',
    name: 'Pending'
  }, {
    id: 'unknown',
    name: 'Unknown'
  }]
}, {
  subType: 'condition_has_offer',
  order: 8,
  name: 'Has Offer',
  img: offerImg,
  component: ConditionHasOffer,
  nodeResource: {
    description: 'Check the text opt-in status of a customer.',
    idea: 'This tile helps you stay compliant by checking the opt-in status before you send additional text messages.'
  },
  parameters: {
    coupon_id: null,
    coupon_name: null,
    session: 'soft_add'
  },
  onEventList: [{
    id: 'yes',
    name: 'Yes'
  }, {
    id: 'no',
    name: 'No'
  }],
  validate(params) {
    return !!params.coupon_id;
  },
  buildLabel({
    params
  }) {
    return Promise.resolve(`${params.coupon_name || ''}`);
  }
}
/*
{
  subType: 'condition_offer_expiring',
  name: 'Offer Expiring',
  img: expireImg,
  component: ConditionOfferExpiring,
  parameters: {
    coupon_id: null,
    coupon_name: null,
    amount: 1,
    unit: 'days',
    direction: 'from_now',
  },
  onEventList: [
    { id: 'yes', name: 'Yes' },
    { id: 'no', name: 'No' },
  ],
  validate(params) {
    return !!params.coupon_id && !!params.amount && !!params.unit && !!params.direction;
  },
  buildLabel({ params }) {
    const parts = [];
    ['coupon_name', 'amount', 'unit', 'direction'].forEach(key => {
      if (params[key] && params[key] !== '') {
        let val = `${params[key]}`.replace('_', ' ');
        if (key === 'coupon_name') {
          val += '\n';
        }
        if (key === 'unit' && params.amount === 1) {
          val = val.substr(0, val.length - 1)
        }
        parts.push(val);
      }
    })
    return Promise.resolve(parts.join(' '));
  },
},
*/].map(node => ({
  type: 'condition',
  connectTo: ['action', 'condition'],
  connectFrom: ['trigger', 'action', 'condition'],
  color: '107EBE',
  // colorShade: '32557a',
  ...node
})).filter(node => {
  return filterNode(node, /fast_sensor|condition_value_segment|condition_survey_satisfaction/g);
});
export const allNodes = [...triggerNodes, ...actionNodes, ...conditionNodes];