import React, { useState, useEffect, useContext } from 'react';
import { ModalDialog, Button } from 'components';
import { ModalToolRedirectBuilder } from './modal-tool-redirect-builder.component';
import { ModalToolSMSReplier } from './modal-tool-sms-replier.component';
import { history } from '../../root.component';
import { UserStateContext } from 'context/user-state-context';
import { ModalContext } from 'context/modal-context';
const tools = [{
  name: 'Redirect Builder',
  component: ModalToolRedirectBuilder
}, {
  name: 'SMS Replier',
  permission: 'superuser',
  component: ModalToolSMSReplier
}, {
  name: 'Dev Tools',
  permission: 'superuser',
  url: `/dev-tools/`
}, {
  name: 'Integration Logs',
  permission: 'superuser',
  url: `/dev-tools/integration-logs`
}];
export const ModalTools = ({
  open,
  onClose
}) => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);
  const clickModal = modal => {
    if (modal.url) {
      history.push(modal.url);
    } else if (modal.component) {
      show(modal.component).catch(() => {});
    }
  };
  return <>
      <ModalDialog open={open} title="Tools" cancelText="Close" onClose={onClose} allowBackdropClick type="scroll">
        <div className="w-100">
          {tools?.filter(tool => !tool.permission || tool.permission === 'superuser' && hasPermission('integration.manage_private_integrations')).map(tool => <div className="py-2" key={tool.name}>
              <Button actionType="primary" onClick={() => {
            clickModal(tool);
            onClose();
          }}>
                {tool.name}
              </Button>
            </div>)}
        </div>
      </ModalDialog>
    </>;
};