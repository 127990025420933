import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { UserStateContext } from '../../../context/user-state-context.js';
import { getCompanyFolders } from '../../../shared/common.api';
import { FolderIcon } from '../icon';
export default function FolderSelectDialog(props) {
  const {
    asCompany
  } = React.useContext(UserStateContext);
  const [selectedFolder, setSelectedFolder] = React.useState(props?.destination?.id || null);
  const [topLevelFolders, setTopLevelFolders] = React.useState([]);
  const [folders, setFolders] = React.useState([]);
  React.useEffect(() => {
    getCompanyFolders({
      model: props.folderModel,
      company: asCompany.id,
      parent: 'all'
    }).then(({
      data
    }) => {
      const topLevel = [];
      const folderMap = {};
      const foldersFromApi = data.results;
      setFolders(JSON.parse(JSON.stringify(data?.results)));
      let currentIndex = foldersFromApi.length - 1;
      while (currentIndex >= 0 && foldersFromApi.length > 0) {
        const folder = foldersFromApi[currentIndex];
        if (!folder.parent) {
          // Top level!
          topLevel.push(folder);
          folderMap[folder.id] = folder;
          folder.children = [];
          folder.depth = 0;
          foldersFromApi.splice(currentIndex, 1);
          if (currentIndex >= foldersFromApi.length) {
            currentIndex--;
          }
        } else if (folderMap[folder.parent]) {
          // we have the parent
          folderMap[folder.parent].children.push(folder);
          folderMap[folder.id] = folder;
          folder.children = [];
          folder.depth = folderMap[folder.parent].depth + 1;
          foldersFromApi.splice(currentIndex, 1);
          if (currentIndex >= foldersFromApi.length) {
            currentIndex--;
          }
        } else {
          // we don't have the parent
          currentIndex--;
        }
      }
      const folders = addFolders(topLevel, []);
      folders.unshift({
        name: 'Home',
        id: 'home',
        depth: 0
      });
      setTopLevelFolders(folders);
    });
  }, []);
  function addFolders(d, stored) {
    const data = d.sort(dynamicSort('name'));
    for (var i in data) {
      stored.push(data[i]);
      if (data[i].children.length > 0) {
        addFolders(data[i].children, stored);
      }
    }
    return stored;
  }
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  function checkDisabled(folder, check) {
    if (folder.parent) {
      check = checkDisabled(topLevelFolders.find(f => f.id === folder.parent), check);
    }
    if (props.data.find(f => f.id === folder.id)) {
      return true;
    }
    return check;
  }
  function checkCurrent(f) {
    const queryParams = new URLSearchParams(window.location.search);
    const folderId = queryParams.get('folder');
    if (folderId === f.id || !folderId && f.id == 'home') {
      return true;
    }
    return false;
  }
  return <>
      <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '325px'
      }
    }} open={props.data?.length > 0}
    //maxWidth="md"
    onClose={(event, reason) => {
      props.onClose(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'large'
      }}>
          <Typography> Move item(s) to folder</Typography>
          <IconButton aria-label="close" onClick={e => {
          props.onClose(null);
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <Stack component="span">
              <Box component="span" display="flex" flexDirection="column">
                {topLevelFolders.map(folder => <Box component="span" key={folder.id} onClick={() => {
                if (!checkDisabled(folder) && !checkCurrent(folder)) {
                  setSelectedFolder(folder.id);
                }
              }} sx={{
                borderLeft: '2px solid',
                borderColor: selectedFolder === folder.id ? '#53A6D6' : '#FFFFFF'
              }}>
                    <Button disabled={checkDisabled(folder) || checkCurrent(folder)} variant="text" fullWidth sx={{
                  justifyContent: 'flex-start',
                  py: 2
                }}>
                      {Array(folder.depth).fill(null).map((h, index) => <Typography key={index} component="span">
                            {' -- '}
                          </Typography>)}
                      <FolderIcon size="sm" />
                      <Typography component="span" sx={{
                    pl: 1,
                    textTransform: 'none',
                    color: selectedFolder === folder.id ? '#53A6D6' : '#1D252D'
                  }}>
                        {folder.name}
                      </Typography>
                    </Button>
                  </Box>)}
              </Box>
              <Divider component="span" sx={{
              px: 2
            }} />

              <Box sx={{
              py: 2
            }} component="span">
                <Stack component="span">
                  <Typography component="span"> The following items will be moved:</Typography>
                  {props.data.some(i => i._type === 'folder') && <Stack sx={{
                  p: 1
                }} component="span">
                      <Typography component="span">Folders:</Typography>
                      {props.data.filter(o => o._type === 'folder').map(folder => <Box component="span" key={folder.id} sx={{
                    py: 1,
                    pl: 2
                  }}>
                            {folder.name}
                          </Box>)}
                    </Stack>}

                  {props.data.some(i => i._type === 'rowItem') && <Stack sx={{
                  p: 1
                }} component="span">
                      <Typography component="span">Items:</Typography>
                      {props.data.filter(o => o._type === 'rowItem').map(item => <Box sx={{
                    py: 1,
                    pl: 2
                  }} component="span" key={item.id}>
                            {item.name || item.title}
                          </Box>)}
                    </Stack>}
                </Stack>
              </Box>
            </Stack>
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{
        py: 3
      }}>
          <Button onClick={() => {
          props.onClose(null);
        }} sx={{
          borderRadius: '20px',
          color: '#53A6D6',
          width: '125px',
          borderStyle: 'solid',
          borderColor: '#53A6D6',
          borderWidth: '1px'
        }}>
            Cancel
          </Button>
          <Button disabled={!selectedFolder} onClick={() => {
          props.onSubmit(selectedFolder, folders.find(f => f.id === selectedFolder)?.name || 'Home');
        }} variant="contained" sx={{
          borderRadius: '20px',
          mr: 2
          // width: '125px',
        }}>
            Move
          </Button>
        </DialogActions>
      </Dialog>
    </>;
}