import React, { useContext } from 'react';
import { Scoped, m, a } from 'kremling';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import numeral from 'numeral';
import styles from './rules.styles-mui2.scss';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Icon } from 'components/icon/icon.component';
import { Calendar } from 'components/calendar/calendar.component';
import { operatorText, operatorDate, operatorNumber, operatorBoolean, operatorList, getFieldsFromModel, fieldTypeName, operatorUUID } from './rules.utils';
import { getTime, timeSlots } from '../../pages/customer-journeys/node/parameters/trigger-recurring/trigger-recurring.utils';
import { getLocations, getCoupons, getCompanyDataDict, getRTCRules } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Box, Button, ButtonGroup, ClickAwayListener, IconButton, Paper, Popper, Stack, TextField, Typography, Tooltip } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { withStyles } from '@mui/styles';
import { DynamicTypeAheadMui } from 'components/dynamic-type-ahead/dynamic-type-ahead-mui.component';
import { EqualIcon, RemoveCircleIcon, FactCheckIcon, MultiSelect, DuplicateIcon, AddCircleFilledIcon } from 'components/mui';
import moment from 'moment-timezone';
import { StaticTypeAhead } from '../static-type-ahead/static-type-ahead.component';
const FIELD_TEXT = 'text';
const FIELD_NUMBER = 'number';
const FIELD_BOOLEAN = 'boolean';
const FIELD_DATE = 'date';
const FIELD_DATETIME = 'datetime';
const FIELD_LIST = 'list';
const FIELD_UUID = 'uuid';
const QUERY_TYPE_VALUE = 'value';
const QUERY_TYPE_RELATED_FIELD = 'related_field';
const QUERY_TYPE_RELATIVE_TIME = 'relative_time';
const QUERY_TYPE_BETWEEN_RELATIVE_TIMES = 'between_relative_times';
const QUERY_TYPE_BETWEEN_TIME_VALUES = 'between_time_values';
export const Rules = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [loading, setLoading] = React.useState(false);
  const [models, setModels] = React.useState([]);
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = React.useState(null);
  const [dropdownMenuData, setDropdownMenuData] = React.useState(null);
  const [dataDicts, setDataDicts] = React.useState({});
  const GetItem = ({
    item
  }) => {
    let title = 'Loading data...';
    if (!dataDicts[`${item.model}.${item.field}`]) {
      // We haven't loaded this value so we need to hit the API
      getCompanyDataDict(asCompany.id, item.model, encodeURIComponent(item.field)).then(({
        data
      }) => {
        if (data.value_type === 'date') {
          data.values = data.values.map(v => moment(v).local().format('MMM D, YYYY h:mm A'));
        }
        setDataDicts(dict => {
          const newDict = cloneDeep(dict);
          newDict[`${item.model}.${item.field}`] = <div>
                <Typography color="#fff"> Min: {data.values[0]?.value} </Typography> <br />
                <Typography color="#fff"> Max: {data.values[1]?.value || data.values[0]?.value} </Typography>
              </div>;
          return newDict;
        });
      }).catch(() => {
        setDataDicts(data => {
          const newData = cloneDeep(data);
          newData[`${item.model}.${item.field}`] = 'No data available';
          return newData;
        });
      });
    } else {
      title = dataDicts[`${item.model}.${item.field}`];
    }
    return <Tooltip title={title}>
        <Box>
          <FactCheckIcon />
        </Box>
      </Tooltip>;
  };
  const getTypeAheadItems = item => {
    if (!dataDicts[`${item.model}.${item.field}`]) {
      getCompanyDataDict(asCompany.id, item.model, encodeURIComponent(item.field)).then(({
        data
      }) => {
        setDataDicts(dict => {
          const newDict = cloneDeep(dict);
          newDict[`${item.model}.${item.field}`] = data.values;
          return newDict;
        });
      }).catch(() => {
        setDataDicts(data => {
          const newData = cloneDeep(data);
          newData[`${item.model}.${item.field}`] = [];
          return newData;
        });
      });
    } else {
      return dataDicts[`${item.model}.${item.field}`];
    }
    return [];
  };
  React.useEffect(() => {
    setModels(props.fieldModels);
  }, [props.fieldModels]);
  React.useEffect(() => {
    if (props?.isQueryValid) {
      props?.isQueryValid(checkIfQueryValid(props.query));
    }
  }, [props.query]);
  const checkIfQueryValid = query => {
    if (!query) {
      return false;
    }
    const {
      and,
      or
    } = query;
    const rules = and || or;
    if (rules.length === 0) {
      return false;
    }
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];
      if (rule?.and || rule?.or) {
        if (!checkIfQueryValid(rule)) {
          return false;
        } else {
          continue;
        }
      }
      if (!rule.model || !rule.field || !rule.operator) {
        return false;
      }
      if (rule.operator == 'is_empty' || rule.operator == 'is_not_empty' || rule.operator == 'is_null' || rule.operator == 'is_not_null' || rule.operator == 'is_true' || rule.operator == 'is_false') {
        continue;
      }
      if (!rule.query_type) {
        return false;
      }
      if (rule.query_type == 'value') {
        if (!rule.value) {
          return false;
        }
        continue;
      }
      if (rule.query_type === 'between_time_values') {
        if (!Array.isArray(rule.value) || rule.value.length != 2) {
          return false;
        }
        continue;
      }
      if ((!rule.related_model || !rule.related_field) && (!rule.time_type || !rule.time_unit)) {
        return false;
      }
    }
    return true;
  };
  const isRuleValid = rule => {
    if (!rule) {
      return false;
    }
    if (!rule.model || !rule.field || !rule.operator) {
      return false;
    }
    if (rule.operator == 'is_empty' || rule.operator == 'is_not_empty' || rule.operator == 'is_null' || rule.operator == 'is_not_null' || rule.operator == 'is_true' || rule.operator == 'is_false') {
      return true;
    }
    if (!rule.query_type) {
      return false;
    }
    if (rule.query_type == 'value') {
      if (!rule.value) {
        return false;
      } else {
        return true;
      }
    }
    if (rule.query_type = 'between_time_values') {
      if (Array.isArray(rule.value) && rule.value.length === 2) {
        return true;
      }
      return false;
    }
    if ((!rule.related_model || !rule.related_field) && (!rule.time_type || !rule.time_unit)) {
      return false;
    }
    return true;
  };
  const updateCondition = (target, value) => {
    const query = cloneDeep(props.query);
    let builder = query;
    target.forEach(t => builder = builder[t]);
    if (value === 'or' && builder.and) {
      builder.or = cloneDeep(builder.and);
      delete builder.and;
    } else if (value === 'and' && builder.or) {
      builder.and = cloneDeep(builder.or);
      delete builder.or;
    }
    props.onChange(query);
  };
  const addQuery = (target, type) => {
    const query = cloneDeep(props.query);
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    if (type === 'rule') {
      builder.push(props.defaultNewRule || {});
    } else {
      builder.push(props.defaultNewGroup || {
        and: [{}]
      });
    }
    props.onChange(query);
  };
  const duplicateQuery = (target, index) => {
    const query = cloneDeep(props.query);
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    builder.push(cloneDeep(builder[index]));
    props.onChange(query);
  };
  const removeQuery = (target, index) => {
    const query = cloneDeep(props.query);
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    builder.splice(index, 1);
    props.onChange(query);
  };
  const updateRow = (target, index, key, value) => {
    const query = cloneDeep(props.query);
    const valueFlow = ['value'];
    const relatedFieldFlow = ['related_model', 'related_field'];
    const relativeTimeFlow = ['value', 'time_unit', 'time_type'];
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    if (key === 'query_type' && value !== builder[index].query_type) {
      valueFlow.forEach(f => builder[index][f] = '');
      relatedFieldFlow.forEach(f => builder[index][f] = '');
      relativeTimeFlow.forEach(f => builder[index][f] = '');
    }
    if (key === 'value' && !builder[index].query_type) {
      builder[index].query_type = QUERY_TYPE_VALUE;
    }
    builder[index][key] = value;
    let flowEnd = [];
    switch (builder[index].query_type) {
      case QUERY_TYPE_VALUE:
        flowEnd = valueFlow;
        break;
      case QUERY_TYPE_RELATED_FIELD:
        flowEnd = relatedFieldFlow;
        break;
      case QUERY_TYPE_RELATIVE_TIME:
        flowEnd = relativeTimeFlow;
        break;
      case QUERY_TYPE_BETWEEN_RELATIVE_TIMES:
        flowEnd = relativeTimeFlow;
        break;
      case QUERY_TYPE_BETWEEN_TIME_VALUES:
        flowEnd = valueFlow;
        break;
    }
    const flow = ['model', 'field', 'operator', 'query_type', ...flowEnd];
    const mapIndex = flow.findIndex(next => next === key) + 1;
    flow.slice(mapIndex).forEach(next => {
      if (key == 'value' && (next == 'time_type' || next == 'time_unit')) {
        return;
      }
      builder[index][next] = '';
    });
    props.onChange(query);
  };
  const buildGroup = (group, level, condition, groupIndex, oldTarget, backgroundColorOverride) => {
    const target = oldTarget.length ? [...oldTarget, groupIndex, condition] : [condition];
    const conditionTarget = oldTarget.length ? [...oldTarget, groupIndex] : [];
    return <div className="data-field-group " style={{
      backgroundColor: backgroundColorOverride || (level % 2 == 0 ? '#FFFFFF' : '#F3F6FC')
    }}>
        <div className="data-field-group__header data-field-connect top hide">
          <Stack direction="row">
            <Stack direction="row" spacing="1px" sx={{
            alignItems: 'center',
            py: '10px'
          }}>
              <Box sx={{
              width: '48px',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              cursor: 'pointer',
              textTransform: 'uppercase',
              height: '28px',
              borderRadius: '6px 0px 0px 6px',
              color: condition === 'and' ? '#FFFFFF' : '#1D252D80',
              backgroundColor: condition === 'and' ? '#3898D9' : '#D9D9D9',
              '&:hover': {
                backgroundColor: condition === 'and' ? '#3898D9' : '#DAEFFF',
                color: '#1D252D80'
              }
            }} onClick={() => {
              updateCondition(conditionTarget, 'and');
            }}>
                And
              </Box>
              <Box sx={{
              width: '48px',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              cursor: 'pointer',
              textTransform: 'uppercase',
              height: '28px',
              borderRadius: '0px 6px 6px 0px',
              color: condition === 'or' ? '#FFFFFF' : '#1D252D80',
              backgroundColor: condition === 'or' ? '#3898D9' : '#D9D9D9',
              '&:hover': {
                backgroundColor: condition === 'or' ? '#3898D9' : '#DAEFFF',
                color: '#1D252D80'
              }
            }} onClick={() => updateCondition(conditionTarget, 'or')}>
                Or
              </Box>
            </Stack>
          </Stack>

          <div className="data-field-group__actions ">
            <IconButton className={props.hoverOverride || backgroundColorOverride ? '' : 'hide__remove'} sx={{
            bottom: '4px',
            height: '32px',
            borderRadius: '23px',
            color: '#3898D9',
            '&:hover': {
              backgroundColor: '#C2E7FF'
            }
          }} onClick={e => {
            addQuery(target, 'rule');
          }}>
              <AddCircleFilledIcon />
              Rule
            </IconButton>

            <IconButton className={props.hoverOverride || backgroundColorOverride ? '' : 'hide__remove'} sx={{
            bottom: '4px',
            height: '32px',
            borderRadius: '23px',
            color: '#3898D9',
            '&:hover': {
              backgroundColor: '#C2E7FF'
            }
          }} onClick={e => {
            addQuery(target, 'group');
          }}>
              <AddCircleFilledIcon />
              Group
            </IconButton>

            {level != 0 && <div>
                <Tooltip title="Duplicate Group">
                  <IconButton sx={{
                bottom: '4px',
                height: '32px',
                borderRadius: '23px',
                color: '#3898D9',
                '&:hover': {
                  backgroundColor: '#C2E7FF'
                }
              }} className="hide__remove" onClick={() => duplicateQuery(oldTarget, groupIndex)}>
                    <DuplicateIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remove Group">
                  <IconButton sx={{
                bottom: '4px',
                '&:hover': {
                  backgroundColor: '#FFC2C6'
                }
              }} className="hide__remove" onClick={() => removeQuery(oldTarget, groupIndex)}>
                    <RemoveCircleIcon fill={'#EF3C34'} />
                  </IconButton>
                </Tooltip>
              </div>}
          </div>
        </div>
        {iterator(group, level, target)}
      </div>;
  };
  const iterator = (group, level, target) => {
    return group.map((item, index) => {
      return <div key={`${level}${index}`} style={{
        position: 'relative'
      }} className="data-field-connect">
          {item.and || item.or ? buildGroup(item.and || item.or, level + 1, item.and ? 'and' : 'or', index, target, null) : <div className="data-field-row">
              <div className={a('data-field-row__inputs')} style={{
            backgroundColor: (level + 1) % 2 == 0 ? '#F3F6FC' : '#FFFFFF'
          }}>
                {buildModel(item, index, target)}
              </div>
              <Tooltip title="Duplicate Rule">
                <IconButton sx={{
              '&:hover': {
                backgroundColor: '#C2E7FF'
              }
            }} className="data-field-row__remove" onClick={() => duplicateQuery(target, index)}>
                  <DuplicateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove Rule">
                <IconButton sx={{
              '&:hover': {
                backgroundColor: '#FFC2C6'
              }
            }} className="data-field-row__remove" onClick={() => removeQuery(target, index)}>
                  <RemoveCircleIcon fill={'#EF3C34'} />
                </IconButton>
              </Tooltip>
            </div>}
        </div>;
    });
  };
  const buildDropdown = (index, target, selectedModel, type, name, data) => {
    return <>
        {' '}
        <Box sx={{
        height: '28px',
        backgroundColor: '#EAEAEA',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#DAEFFF'
        }
      }} onClick={e => handleDropdownClick(e, {
        data: data,
        target: target,
        index: index,
        type: type,
        selectedModel: selectedModel
      })}>
          <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
          width: '100%'
        }}>
            <Box sx={{
            flexGrow: 5,
            width: '100%',
            alignContent: 'center',
            margin: 'auto',
            alignItems: 'center',
            px: '8px'
          }}>
              <Box sx={{
              display: 'flex',
              alignContent: 'center',
              margin: 'auto',
              alignItems: 'center'
            }}>
                <Typography sx={{
                fontSize: 14,
                textTransform: 'none',
                color: selectedModel ? '#1D252D' : '#1D252D80'
              }}>
                  {selectedModel ? selectedModel.name : 'Choose ' + name}
                </Typography>
              </Box>
            </Box>
            {!!dropdownMenuData && dropdownMenuData.index === index && dropdownMenuData.type == type ? <Box sx={{
            pl: '4px',
            transform: 'rotate(180deg)',
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center'
          }}>
                <Icon fill="#1C1B1F" name="custom-keyboard-arrow-down" size={20} />
              </Box> : <Box sx={{
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center',
            pr: '4px'
          }}>
                <Icon fill="#1C1B1F" name="custom-keyboard-arrow-down" size={20} />
              </Box>}
          </Box>
        </Box>
      </>;
  };
  const buildModel = (item, index, target) => {
    const selectedModel = models?.find(m => m.id === item.model);
    let builder = {};
    if (props.showRuleErrors) {
      const query = cloneDeep(props.query);
      builder = query;
      target.forEach(t => {
        builder = builder[t];
      });
    }
    return <Tooltip open={props.showRuleErrors && !isRuleValid(builder[index])} title={props.showRuleErrors && !isRuleValid(builder[index]) ? 'Incomplete rule' : ''}>
        <Stack direction="row" spacing="10px" sx={{
        p: props.showRuleErrors && !isRuleValid(builder[index]) ? ' 3px' : '0px',
        border: props.showRuleErrors && !isRuleValid(builder[index]) ? '2px solid #EF3C344D' : 'none',
        borderRadius: '14px'
      }}>
          {buildDropdown(index, target, selectedModel, 'model', 'model', models)}
          {!!selectedModel && buildField(item, index, target)}
        </Stack>
      </Tooltip>;
  };
  const buildField = (item, index, target) => {
    const fields = getFieldsFromModel(item.model, models);
    const selectedField = fields.find(f => f.id === item.field);
    return <Stack direction="row" spacing="10px">
        {buildDropdown(index, target, selectedField, 'field', 'field', fields)}
        {!!selectedField && buildOperator(item, index, target, selectedField)}
      </Stack>;
  };
  const buildOperator = (item, index, target, selectedField) => {
    let operators;
    if (selectedField.type === FIELD_BOOLEAN) {
      operators = operatorBoolean;
    } else if (selectedField.type === FIELD_DATE || selectedField.type === FIELD_DATETIME) {
      operators = operatorDate;
    } else if (selectedField.type === FIELD_NUMBER) {
      operators = operatorNumber;
    } else if (selectedField.type === FIELD_LIST) {
      operators = operatorList;
    } else if (selectedField.type === FIELD_UUID) {
      operators = operatorUUID;
    } else {
      operators = operatorText;
    }
    const selectedOperator = operators.find(o => o.id === item.operator);
    return <Stack direction="row" spacing="10px">
        {buildDropdown(index, target, selectedOperator, 'operator', 'operator', operators)}
        {!!selectedOperator && !selectedOperator.end && buildQueryType(item, index, target, selectedField)}
      </Stack>;
  };
  const buildQueryType = (item, index, target, selectedField) => {
    /*
      TODO (if we can find a way) we should consider componetizing query building behavior
      to make this entire flow much easier to read / debug.
      (and way easier to make adjustments to such as adding new operators).
    */
    const isDateType = selectedField.type === FIELD_DATE || selectedField.type === FIELD_DATETIME;
    let queryTypes = [];
    queryTypes.push({
      id: 'value',
      name: fieldTypeName(selectedField.type)
    });
    queryTypes.push({
      id: 'related_field',
      name: 'Related Field'
    });
    if (isDateType) {
      if (item.operator === 'is_between') {
        // not ideal, but works
        queryTypes = [];
        queryTypes.push({
          id: 'between_relative_times',
          name: 'Relative Times'
        });
        queryTypes.push({
          id: 'between_time_values',
          name: 'Dates'
        });
      } else {
        queryTypes.push({
          id: 'relative_time',
          name: 'Relative Time'
        });
      }
    }
    const selectedQueryType = queryTypes.find(qt => qt.id === item.query_type);
    return <>
        {selectedField.type !== FIELD_UUID ? <Stack direction="row" spacing="10px" sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}>
            {buildDropdown(index, target, selectedQueryType, 'query_type', 'query type', queryTypes)}

            {!!selectedQueryType && <>
                <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
                  <EqualIcon />
                </Box>

                {selectedQueryType.id === 'value' && selectedField.type === FIELD_DATE && buildValueDate(item, index, target)}
                {selectedQueryType.id === 'value' && selectedField.type === FIELD_DATETIME && buildValueDate(item, index, target, true)}
                {selectedQueryType.id === 'value' && (selectedField.type === FIELD_TEXT || selectedField.type === FIELD_LIST || !selectedField.type) && buildValueText(item, index, target)}
                {selectedQueryType.id === 'value' && selectedField.type === FIELD_NUMBER && buildValueNumber(item, index, target)}
                {selectedQueryType.id === 'value' && selectedField.type === FIELD_BOOLEAN && buildValueBoolean(item, index, target)}
                {selectedQueryType.id === 'related_field' && buildRelatedModel(item, index, target, selectedField)}
                {selectedQueryType.id === 'relative_time' && buildRelativeTime(item, index, target)}
                {selectedQueryType.id === 'between_relative_times' && buildBetweenRelativeTimes(item, index, target)}
                {selectedQueryType.id === 'between_time_values' && selectedField.type === FIELD_DATE && buildBetweenDates(item, index, target)}
                {selectedQueryType.id === 'between_time_values' && selectedField.type === FIELD_DATETIME && buildBetweenDates(item, index, target, true)}
              </>}
          </Stack> : <Stack direction="row" spacing="10px">
            {buildValueUUID(item, index, target, selectedField)}
          </Stack>}
      </>;
  };
  const buildRelatedModel = (item, index, target, selectedField) => {
    const selectedRelatedModel = models.find(m => m.id === item.related_model);
    return <Stack direction="row" spacing="10px">
        {buildDropdown(index, target, selectedRelatedModel, 'related_model', 'related model', models)}
        {!!selectedRelatedModel && buildRelatedField(item, index, target, selectedField)}
      </Stack>;
  };
  const buildRelatedField = (item, index, target, selectedField) => {
    const fields = getFieldsFromModel(item.related_model, models).filter(f => f.type === selectedField.type);
    const selectedRelatedField = fields.find(f => f.id === item.related_field);
    return <>{buildDropdown(index, target, selectedRelatedField, 'related_field', 'related field', fields)}</>;
  };
  const buildRelativeTime = (item, index, target) => {
    const units = [{
      name: 'days',
      id: 'days'
    }, {
      name: 'weeks',
      id: 'weeks'
    }, {
      name: 'months',
      id: 'months'
    }, {
      name: 'years',
      id: 'years'
    }];
    const types = [{
      name: 'ago',
      id: 'ago'
    }, {
      name: 'from now',
      id: 'from now'
    }];
    return <Stack direction="row" spacing="10px">
        <input style={{
        borderRadius: '8px',
        border: 'solid 1px #BEBEBE'
      }} className="input" autoFocus type="number" onChange={e => updateRow(target, index, 'value', e.target.value)} value={item.value || ''} />
        {buildDropdown(index, target, item.time_unit ? {
        name: item.time_unit
      } : null, 'time_unit', 'unit', units)}
        {buildDropdown(index, target, item.time_type ? {
        name: item.time_type
      } : null, 'time_type', 'type', types)}
      </Stack>;
  };
  const buildBetweenDates = (item, index, target, isDateTime) => {
    const currentValues = item.value ? item.value : [null, null];
    let firstDate = currentValues[0];
    if (firstDate) {
      firstDate = DateTime.fromISO(firstDate);
    }
    let secondDate = currentValues[1];
    if (secondDate) {
      secondDate = DateTime.fromISO(secondDate);
    }
    return <>
        <Dropdown size={250} allowContentClicks horizontal="west" fixedContent trigger={() => <Box sx={{
        px: '8px',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        cursor: 'pointer',
        height: '28px',
        backgroundColor: '#EAEAEA',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: '#DAEFFF'
        }
      }}>
              <Typography sx={{
          fontSize: 14,
          textTransform: 'none',
          color: firstDate ? '#1D252D' : '#1D252D80'
        }}>
                {' '}
                {firstDate ? firstDate.toFormat('LL/dd/yy') : 'Choose Date'}{' '}
              </Typography>
            </Box>} content={({
        close
      }) => <Scoped css={styles}>
              <div className="p-sm">
                <Calendar minDate={new Date('jan 1 1970')} onChange={date => {
            const newDate = DateTime.fromJSDate(date).set({
              hour: firstDate ? firstDate.hour : 12,
              minute: firstDate ? firstDate.minute : 0
            });
            updateRow(target, index, 'value', [newDate.toUTC().toISO(), secondDate && secondDate.toUTC().toISO()]);
            close();
          }} value={firstDate ? firstDate.toJSDate() : null} />
              </div>
            </Scoped>} />
        {/** TODO needs fixing, sets value to undefined rather than maintaining structure... */}
        {/** TODO should this update state within the function only???  This is getting too complex... */}
        {firstDate && isDateTime && buildDropdown(index, target, firstDate ? {
        name: getTime(firstDate.hour, firstDate.minute),
        firstDate: [firstDate, secondDate]
      } : null, 'value', 'value', timeSlots)}
        <GetItem item={item} />
        <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}>
          and
        </Typography>
        <Dropdown size={250} allowContentClicks horizontal="west" fixedContent trigger={() => <Box sx={{
        px: '8px',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        cursor: 'pointer',
        height: '28px',
        backgroundColor: '#EAEAEA',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: '#DAEFFF'
        }
      }}>
              <Typography sx={{
          fontSize: 14,
          textTransform: 'none',
          color: secondDate ? '#1D252D' : '#1D252D80'
        }}>
                {' '}
                {secondDate ? secondDate.toFormat('LL/dd/yy') : 'Choose Date'}{' '}
              </Typography>
            </Box>} content={({
        close
      }) => <Scoped css={styles}>
              <div className="p-sm">
                <Calendar minDate={new Date('jan 1 1970')} onChange={date => {
            const newDate = DateTime.fromJSDate(date).set({
              hour: secondDate ? secondDate.hour : 12,
              minute: secondDate ? secondDate.minute : 0
            });
            updateRow(target, index, 'value', [firstDate && firstDate.toUTC().toISO(), newDate.toUTC().toISO()]);
            close();
          }} value={secondDate ? secondDate.toJSDate() : null} />
              </div>
            </Scoped>} />
        {/** TODO this needs to not set the data to undefined... */}
        {secondDate && isDateTime && buildDropdown(index, target, secondDate ? {
        name: getTime(secondDate.hour, secondDate.minute),
        secondDate: [firstDate, secondDate]
      } : null, 'value', 'value', timeSlots)}
        <GetItem item={item} />
      </>;
  };
  const buildBetweenRelativeTimes = (item, index, target) => {
    const units = [{
      name: 'days',
      id: 'days'
    }, {
      name: 'weeks',
      id: 'weeks'
    }, {
      name: 'months',
      id: 'months'
    }, {
      name: 'years',
      id: 'years'
    }];
    const types = [{
      name: 'ago',
      id: 'ago'
    }, {
      name: 'from now',
      id: 'from now'
    }];
    const currentValue = item.value ? item.value : [null, null];
    return <Stack direction="row" spacing="10px">
        <input style={{
        borderRadius: '8px',
        border: 'solid 1px #BEBEBE',
        pr: '8px'
      }} className="input" autoFocus type="number" onChange={e => {
        updateRow(target, index, 'value', [e.target.value, currentValue[1]]);
      }} value={item.value[0] || ''} />
        <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}>
          and
        </Typography>
        <input style={{
        borderRadius: '8px',
        border: 'solid 1px #BEBEBE'
      }} className="input" autoFocus type="number" onChange={e => {
        updateRow(target, index, 'value', [currentValue[0], e.target.value]);
      }} value={item.value[1] || ''} />
        {buildDropdown(index, target, item.time_unit ? {
        name: item.time_unit
      } : null, 'time_unit', 'unit', units)}
        {buildDropdown(index, target, item.time_type ? {
        name: item.time_type
      } : null, 'time_type', 'type', types)}
      </Stack>;
  };
  const buildValueText = (item, index, target) => {
    if (item.operator === 'in' || item.operator === 'not_in') {
      return <MultiSelect value={item.value} options={getTypeAheadItems(item).map(v => v.value)} onChange={options => updateRow(target, index, 'value', options ? options : null)} placeholder="Select options" zIndex={905} />;
    }
    return <StaticTypeAhead style={{
      paddingRight: '1rem'
    }} inputStyle={{
      width: '200px'
    }} className="input" type="text" value={item.value} displayProperty="value" keyProperty="value" items={getTypeAheadItems(item)} autoFocus onSearchChange={val => updateRow(target, index, 'value', val)} onChange={e => updateRow(target, index, 'value', e ? e.value : null)} />;
  };
  const buildValueUUID = (item, index, target, selectedField) => {
    const modelGetterMapper = {
      coupon: getCoupons,
      location: getLocations,
      transaction_calculator_results: getRTCRules
    };
    const getter = modelGetterMapper[selectedField.target] || getLocations;
    return <Box sx={{
      position: 'relative',
      top: '-5px'
    }}>
        <DynamicTypeAheadMui getItems={getter} getItemsFilters={{
        company: asCompany.id
      }} placeholder={'Choose ' + selectedField.name} size="small" displayProperty="name" keyProperty="id" value={item.value} onChange={val => updateRow(target, index, 'value', val ? val.id : null)} />
      </Box>;
  };
  const buildValueDate = (item, index, target, isDateTime) => {
    let dateValue;
    if (item.value) {
      dateValue = DateTime.fromISO(item.value);
    }
    return <>
        <Dropdown size={250} allowContentClicks horizontal="west" fixedContent trigger={() => <Box sx={{
        px: '8px',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        cursor: 'pointer',
        height: '28px',
        backgroundColor: '#EAEAEA',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: '#DAEFFF'
        }
      }}>
              <Typography sx={{
          fontSize: 14,
          textTransform: 'none',
          color: dateValue ? '#1D252D' : '#1D252D80'
        }}>
                {' '}
                {dateValue ? dateValue.toFormat('LL/dd/yy') : 'Choose Date'}{' '}
              </Typography>
            </Box>} content={({
        close
      }) => <Scoped css={styles}>
              <div className="p-sm">
                <Calendar minDate={new Date('jan 1 1970')} onChange={date => {
            const newDate = DateTime.fromJSDate(date).set({
              hour: dateValue ? dateValue.hour : 12,
              minute: dateValue ? dateValue.minute : 0
            });
            updateRow(target, index, 'value', newDate.toUTC().toISO());
            close();
          }} value={dateValue ? dateValue.toJSDate() : null} />
              </div>
            </Scoped>} />

        {!!dateValue && isDateTime && buildDropdown(index, target, dateValue ? {
        name: getTime(dateValue.hour, dateValue.minute),
        dateValue: dateValue
      } : null, 'value', 'value', timeSlots)}

        <GetItem item={item} />
      </>;
  };
  const buildValueNumber = (item, index, target) => {
    return <>
        <input type="number" style={{
        borderRadius: '8px',
        border: 'solid 1px #BEBEBE'
      }} className="form-control inline input" value={item.value} autoFocus onChange={e => updateRow(target, index, 'value', numeral(e.target.value).value())} />
        <GetItem item={item} />
      </>;
  };
  const buildValueBoolean = (item, index, target) => {}; //TODO: Is this in use?

  const handleDropdownMenuClose = () => {
    setDropdownMenuData(null);
    setDropdownMenuAnchorEl(null);
  };
  const handleDropdownClick = (event, data) => {
    setDropdownMenuAnchorEl(!!dropdownMenuData ? null : event.currentTarget);
    setDropdownMenuData(data);
  };
  return <>
      {!props.query ? <></> : <>
          <CssBaseline />
          <Scoped css={styles}>
            <div className="data-field">
              {buildGroup(props.query.and || props.query.or, 0, props.query.and ? 'and' : 'or', 0, [], '#FFFFFF')}
            </div>
          </Scoped>

          <Popper anchorEl={dropdownMenuAnchorEl} sx={{
        zIndex: 1000
      }} open={!!dropdownMenuData} onClose={handleDropdownMenuClose} placement="bottom-start">
            <ClickAwayListener onClickAway={handleDropdownMenuClose}>
              <Paper elevation={0} sx={{
            borderRadius: '16px',
            overflowY: 'scroll',
            maxHeight: '50vh',
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
              marginTop: '20px',
              marginBottom: '20px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            },
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 1000
            }
          }}>
                <Box sx={{
              py: 1
            }}>
                  <Box display="flex" flexDirection="column">
                    {dropdownMenuData?.data?.map(opt => {
                  return <Box key={opt.id || opt.time} sx={{
                    borderLeft: '2px solid',
                    borderColor: opt.id == dropdownMenuData?.selectedModel?.id ? '#53A6D6' : '#FFFFFF'
                  }}>
                          <Button variant="text" fullWidth sx={{
                      cursor: 'pointer',
                      height: '32px',
                      color: '#1D252D',
                      textTransform: 'none',
                      justifyContent: 'flex-start'
                      //px: '8px',
                    }} onClick={() => {
                      // TODO refactor maybe? complex / hard to follow all the ways this
                      // can be used (and how it handles one offs like firstDate / secondDate)

                      if (dropdownMenuData?.selectedModel?.dateValue | dropdownMenuData?.selectedModel?.lastDate) {
                        const newDate = dropdownMenuData.selectedModel.dateValue.set({
                          hour: opt.hour,
                          minute: opt.minute
                        });
                        updateRow(dropdownMenuData.target, dropdownMenuData.index, dropdownMenuData.type, newDate.toUTC().toISO());
                      } else if (dropdownMenuData?.selectedModel?.firstDate) {
                        // sorry for naming firstDate (array) firstDate, needed a way to distinguishing
                        // that it comes from the firstDate change logic even though it contains first / second dates
                        const firstDate = dropdownMenuData.selectedModel.firstDate[0];
                        const secondDate = dropdownMenuData.selectedModel.firstDate[1];
                        const newDate = firstDate.set({
                          hour: opt.hour,
                          minute: opt.minute
                        });
                        updateRow(dropdownMenuData.target, dropdownMenuData.index, dropdownMenuData.type, [newDate.toUTC().toISO(), secondDate ? secondDate.toUTC().toISO() : secondDate]);
                      } else if (dropdownMenuData?.selectedModel?.secondDate) {
                        const firstDate = dropdownMenuData.selectedModel.secondDate[0];
                        const secondDate = dropdownMenuData.selectedModel.secondDate[1];
                        const newDate = secondDate.set({
                          hour: opt.hour,
                          minute: opt.minute
                        });
                        updateRow(dropdownMenuData.target, dropdownMenuData.index, dropdownMenuData.type, [firstDate ? firstDate.toUTC().toISO() : firstDate, newDate.toUTC().toISO()]);
                      } else {
                        updateRow(dropdownMenuData.target, dropdownMenuData.index, dropdownMenuData.type, opt.id);
                      }
                      handleDropdownMenuClose();
                    }}>
                            <Typography sx={{
                        pl: 1
                      }}> {opt.name || opt.time}</Typography>
                          </Button>
                        </Box>;
                })}
                  </Box>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </>}
    </>;
};