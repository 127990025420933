import React, { useState, useEffect, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { ModalContext } from 'context/modal-context';
import { deleteCredential as deleteCredentialsAPI, resyncCredential } from '../integrations.resource';
import { snackbarService, ModalDialog, ModalCompanySelect, DynamicTypeAhead } from 'components';
import { getLocations, getLocationRefs, editLocationRef } from 'shared/common.api';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, Checkbox, Stack, Typography, Button, Divider, CircularProgress } from '@mui/material';
import { CancelIcon, SyncIcon } from 'components/mui';
import { history } from '../../../root.component';
export const IntegrationGoogleBusinessUpdate = props => {
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const {
    confirm
  } = useContext(ModalContext);
  const [renderIteration, setRenderIteration] = useState(0);
  const [deleteCredential, setDeleteCredential] = useState();
  const [saving, setSaving] = useState(false);
  const [mapLocation, setMapLocation] = useState();
  const locationRefsWrapper = () => {
    const filters = {
      entity_source: 'google_business',
      entity_data__credential_id: props?.credential?.id
    };
    return params => getLocationRefs({
      ...params,
      filters
    });
  };
  const headCells = [{
    key: 'ref',
    label: 'Google Location',
    noSort: true,
    displayComponent: ({
      data
    }) => <Stack>
          <Box>
            <strong>{data.name}</strong>
          </Box>
          <Box>
            {data.address1}, {data.city}, {data.state}, {data.zip_code}
          </Box>
        </Stack>
  }, {
    key: 'location',
    label: 'Matched Location',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      // TODO on click allow them to edit it...
      if (!data.location) {
        return <i>No Match</i>;
      }
      return <Stack>
            <Box>
              <strong>{data.location_name}</strong>
            </Box>
            <Box>
              {data.location_address1}, {data.location_city}, {data.location_state}, {data.location_zip_code}
            </Box>
          </Stack>;
    }
  }, {
    key: 'is_active',
    label: 'Sync Reviews',
    noSort: true,
    displayComponent: ({
      data: row
    }) => <Checkbox color="primary" disabled={!hasPermission('integration.change_credential')} checked={row.is_active} onChange={() => {
      // Call API to update is_active
      let promise = Promise.resolve();
      if (!row.is_active && !row.location) {
        promise = confirm({
          title: 'Create New Location',
          message: `You are attempting to sync reviews for a location that is not mapped. Would you like to create a new location for this Google Business location?`,
          submitText: 'Confirm'
        });
      }
      promise.then(() => {
        setSaving(true);
        editLocationRef(row.id, {
          is_active: !row.is_active
        }).then(() => {
          snackbarService.popup({
            type: 'success',
            message: 'This change was saved'
          });
          // I was unable to figure out how to get the location information to update the UI without fetching from the API.
          // So we are updating via the API
          setRenderIteration(i => i + 1);
          setSaving(false);
        });
      }).catch(() => {});
    }} sx={{
      color: '#1D252D',
      '&.Mui-checked': {
        color: '#53A6D6'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 22
      }
    }} />
  }];
  const handleRowClick = (data, column) => {
    console.log('handle row click ran');
    // TODO if they cilck on the location match column then open up the ability to edit
    if (!hasPermission('integration.change_credential')) {
      snackbarService.popup({
        type: 'error',
        message: 'You do not have permission to edit this credential'
      });
      return;
    }
    if (column === 'is_active' && !data.location) {
      /*snackbarService.popup({
        type: 'error',
        message: 'The Google Business location needs to be mapped first.',
      });*/
    } else if (column == 'location' || column == 'ref') {
      setMapLocation(data);
    }
  };
  const resync = id => {
    resyncCredential(id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Locations and reviews will start syncing momentarily'
      });
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error occured trying ot resync credential'
      });
    });
  };
  const removeCredentials = credentialsId => {
    return deleteCredentialsAPI(credentialsId).then(() => snackbarService.popup({
      type: 'success',
      message: 'Successfully deleted credentials'
    })).then(() => {
      history.push(`/integrations/manage/${props?.install?.id}`);
    }).catch(() => snackbarService.popup({
      type: 'error',
      message: 'Unknown error to trying delete credentials. Please try again.'
    }));
  };
  useEffect(() => {
    if (!props?.credential && hasPermission('integration.add_credential')) {
      window.open(`${API_URL}/google-business/account/request`);
    }
  }, []);
  return <>
      {props.credential ? <Box>
          <Stack spacing="36px">
            <Typography variant="tableHeader">Review Locations</Typography>
            <Typography>
              Map your Google Business locations with your POS locations. Also enable and disable the locations you wish to sync reviews.
            </Typography>
            <Stack direction="row" spacing="16px">
              <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography>
                  If there are missing locations, click the resync button to fetch the latest locations from Google Business.
                </Typography>
              </Box>
              <Button disabled={!hasPermission('integration.change_credential')} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }} startIcon={<SyncIcon fill="#3898D9" />} onClick={e => {
            resync(props.credential.id);
          }}>
                <Box sx={{
              py: '5px',
              pr: '12px'
            }}>
                  <Typography sx={{
                color: '#3898D9'
              }}>Resync </Typography>
                </Box>
              </Button>
            </Stack>
            <EnhancedTable renderIteration={renderIteration} type="Locations" headCells={headCells} api={locationRefsWrapper()} handleRowClick={handleRowClick} defaultSort="name" />

            <Stack sx={{
          pt: '16px'
        }}>
              <Divider />
              <Box sx={{
            pr: '20px',
            pt: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
                <Stack direction="row" spacing="16px">
                  {hasPermission('integration.delete_credential') && <Button variant="contained" sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                setDeleteCredential(props.credential);
              }}>
                      <Box sx={{
                  py: '5px',
                  pr: '12px'
                }}>
                        <Typography sx={{
                    color: '#3898D9'
                  }}>Remove </Typography>
                      </Box>
                    </Button>}
                </Stack>
                <Stack direction="row" spacing="16px">
                  <Button variant="contained" sx={{
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }}>
                    <Box sx={{
                  py: '5px',
                  px: '12px'
                }}>
                      <Typography sx={{
                    color: '#3898D9'
                  }}>Cancel </Typography>
                    </Box>
                  </Button>
                  <Button disabled={saving} sx={{
                backgroundColor: '#3898D9',
                '&:hover': {
                  backgroundColor: '#3898D9'
                }
              }} variant="contained" endIcon={saving && <CircularProgress sx={{
                color: '#FFF'
              }} size={'20px'} />} onClick={e => {
                history.push(`/integrations/manage/${props?.install?.id}`);
              }}>
                    <Box sx={{
                  py: '6px',
                  px: '12px'
                }}>
                      <Typography sx={{
                    color: '#fff'
                  }}>{saving ? 'Working...' : 'Done'}</Typography>
                    </Box>
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Box> : <>
          {hasPermission('integration.add_credential') ? <Box>
              <Stack spacing="24px">
                <Stack direction="row" spacing="24px">
                  <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                    <Typography> If the popup did not appear, click the button below to link your Google My Business account. </Typography>
                  </Box>

                  <Button variant="contained" onClick={() => {
              window.open(`${API_URL}/google-business/account/request`);
            }}>
                    Link Google My Business Account
                  </Button>
                </Stack>
                <Stack direction="row" spacing="24px">
                  <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                    <Typography>
                      Once you have finished configuring this integration with Google, click the button to go back to the previous page and
                      select the credential you just added.
                    </Typography>
                  </Box>
                  <Button onClick={e => {
              history.push(`/integrations/manage/${props?.install?.id}`);
            }} sx={{
              width: '200px'
            }} variant="contained">
                    Go Back to Previous Page
                  </Button>
                </Stack>
              </Stack>
            </Box> : <>
              <Typography> You do not have permission to add a credential. </Typography>
            </>}
        </>}

      <ModalDialog open={!!mapLocation} title="Assign Location" submitText="Assign" onSubmit={() => {
      setSaving(true);
      editLocationRef(mapLocation.id, {
        location: mapLocation.location
      }).then(() => {
        snackbarService.popup({
          type: 'success',
          message: 'Changes were saved'
        });
        setSaving(false);
        setRenderIteration(i => i + 1);
      });
    }} onClose={() => setMapLocation()} allowBackdropClick level={2}>
        <p>Map the following Google Business:</p>
        <p>
          <strong>{mapLocation?.name}</strong>
          <br />
          {mapLocation?.address1}, {mapLocation?.city}, {mapLocation?.state}, {mapLocation?.zip_code}
        </p>

        <p>To the following Location:</p>
        <DynamicTypeAhead getItems={getLocations} getItemsFilters={{
        company: asCompany.id
      }} placeholder="Choose" displayProperty="name" displayComponent={data => <Stack style={{
        borderBottom: '1px solid #cccccc',
        paddingBottom: '5px'
      }}>
              <Box>
                <strong>{data?.name}</strong>
              </Box>
              <Box>
                {data?.address1}, {data?.city}, {data?.state}, {data?.zip_code}
              </Box>
            </Stack>} keyProperty="id" value={mapLocation?.location} onChange={val => {
        setMapLocation(mapLocation => ({
          ...mapLocation,
          location: val?.id || null,
          location_name: val?.name,
          location_address1: val?.address1,
          location_address2: val?.address2,
          location_city: val?.city,
          location_state: val?.state,
          location_zip_code: val?.zip_code
        }));
      }} />
      </ModalDialog>

      <ModalDialog open={!!deleteCredential && deleteCredential.id} title="Remove Credential" submitText="Remove" onSubmit={() => removeCredentials(deleteCredential.id)} onClose={() => setDeleteCredential(null)} allowBackdropClick>
        <p>
          <strong>{deleteCredential && deleteCredential.identifier}</strong>
        </p>
        <p>Are you sure you want to remove this credential?</p>
      </ModalDialog>
    </>;
};