import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { getInstall, getInstalls, deleteCredential as deleteCredentialsAPI, deleteInstalls, deleteCallToolsToken, resyncCredential, syncAdwordsAudiences, deleteAdwordsAccount, deleteCourse, deleteShopifyShop } from '../integrations/integrations.resource';
import { Icon, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, Tooltip, Typography, Divider, Skeleton, Popper, ClickAwayListener, DialogTitle, Dialog, DialogActions, DialogContent, CircularProgress } from '@mui/material';
import { AddIcon, CancelIcon, CarrotDownIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DeleteIcon, EditIcon, MoreVertIcon, ShieldPersonIcon, SyncIcon } from 'components/mui';
import { history } from '../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import validator from 'validator';

//This skips the manage page for the following integrations
const overrideForwardList = ['jiffylube', 'google', 'linkpos', 'mindbody', 'newstore', 'oilchange', 'pestroutes', 'oxifresh', 'contact', 'woocommerce', 'carfax_reviews'];
const integrationsWithCustomCredentialName = {
  calltools: 'Account',
  adwords: 'Account',
  google_business: 'Account',
  foreup: 'Course',
  shopify: 'Store'
};
const integrationsWithAutoStatusThatStillHaveDisplayComponents = ['zapier', 'contact'];
export const MarketplaceManage = props => {
  const {
    user,
    asCompany,
    hasPermission,
    flags
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [install, setInstall] = useState(null);
  const [editExistingCredentialMenuAnchorEl, setEditExistingCredentialMenuAnchorEl] = React.useState(null);
  const [editExistingCredentialMenu, setEditExistingCredentialMenu] = useState(null);
  const [showDeleteCredentialModal, setShowDeleteCredentialModal] = useState(null);
  const [openConfirmDeleteInstallModal, setOpenConfirmDeleteInstallModal] = useState(null);
  const [renderIteration, setRenderIteration] = useState(0);
  const [optionsMenu, setOptionsMenu] = useState([]);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setLoading(true);
    if (!validator.isUUID(props.match.params.id)) {
      //if no uuid is passed, try and guess the install from url

      getInstalls({
        company: asCompany.id,
        limit: 1000
      }).then(installs => {
        const install = installs?.results.find(install => install.integration === props.match.params.id);
        if (install) {
          history.push(`/integrations/manage/${install.id}`);
          loadInstall(install.id);
        } else {
          history.push(`/integrations`);
        }
      });
    } else {
      loadInstall(props.match.params.id);
    }
  }, [hasPermission, asCompany?.id, renderIteration, props.match.params.id]);
  const loadInstall = uuid => {
    getInstall(uuid).then(install => {
      if (overrideForwardList.includes(install.data.integration.key)) {
        //if we came back from the configuration page, we need to redirect to the list page instead
        if (history?.action == 'POP') {
          history.push(`/integrations`);
        } else {
          history.push(`/integrations/configure/${install.data.id}`);
        }
      }
      const menu = [];
      if (install.data.integration.key == 'google_business' && hasPermission('integration.change_credential')) {
        menu.push({
          label: 'Resync credential',
          onClick: (credential, install) => {
            handleSyncCredential(credential);
          },
          icon: <SyncIcon />,
          key: 'resync'
        });
      }
      if (install.data.integration.key == 'adwords' && hasPermission('adwords.change_audience')) {
        menu.push({
          label: 'Sync Audiences',
          onClick: (credential, install) => {
            handleSyncAudiences(credential);
          },
          icon: <SyncIcon />,
          key: 'resync'
        });
      }
      if (install.data.integration.key == 'shopify' && hasPermission('shopify.change_shop')) {
        menu.push({
          label: 'Sync store',
          onClick: (credential, install) => {
            if (credential?.version !== '2020-04') {
              window.open(`${API_URL}/shopify/shops/request?shop=${credential.domain}.myshopify.com`, '_blank');
            } else {
              snackbarService.popup({
                type: 'error',
                message: 'Shopify store version is not supported for syncing. Please contact support.'
              });
            }
          },
          icon: <SyncIcon />,
          key: 'resync'
        });
      }
      if (flags?.pestpack_upload_csv && install.data.integration.key == 'pestpac' && hasPermission('integration.change_credential')) {
        menu.push({
          label: 'CSV Upload',
          onClick: (credential, install) => {
            history.push(`/integrations/manage/pestpac/csv-upload/${install?.id}?credential=${credential?.id}`);
          },
          icon: <SyncIcon />,
          key: 'resync'
        });
      }
      if (hasPermission('integration.change_credential')) {
        menu.push({
          label: 'Edit credential',
          onClick: (credential, install) => {
            history.push(`/integrations/configure/${install?.id}?credential=${credential?.id}`);
          },
          icon: <EditIcon />,
          key: 'edit'
        });
      }
      if (hasPermission('integration.delete_credential')) {
        menu.push({
          label: integrationsWithCustomCredentialName[install?.data?.integration?.key] ? 'Remove ' + integrationsWithCustomCredentialName[install?.data?.integration?.key] : 'Remove credential',
          onClick: (credential, install) => {
            setShowDeleteCredentialModal(credential);
          },
          icon: <DeleteIcon />,
          key: 'delete'
        });
      }
      setOptionsMenu(menu);
      setInstall(install.data);
      setLoading(false);
      if (history?.location?.state?.sucessfulCredential) {
        snackbarService.popup({
          type: 'success',
          message: `${history?.location?.state?.sucessfulCredential}`
        });
        const newState = {
          ...history.location.state
        };
        delete newState.sucessfulCredential;
        history.replace({
          ...history.location,
          state: newState
        });
      }
      if (history?.location?.state?.removedCredential) {
        snackbarService.popup({
          type: 'success',
          message: `${history?.location?.state?.removedCredential}`
        });
        const newState = {
          ...history.location.state
        };
        delete newState.removedCredential;
        history.replace({
          ...history.location,
          state: newState
        });
      }
    }).catch(error => {
      history.push(`/integrations`);
      snackbarService.popup({
        type: 'error',
        message: 'There was an error fetching the integration'
      });
    });
  };
  const handleCredentialMenuClick = (e, credential) => {
    if (editExistingCredentialMenuAnchorEl) {
      handleEditExistingCredentialMenuClose();
      return;
    }
    setEditExistingCredentialMenu(credential);
    setEditExistingCredentialMenuAnchorEl(e.currentTarget);
  };
  const handleEditExistingCredentialMenuClose = () => {
    setEditExistingCredentialMenu(null);
    setEditExistingCredentialMenuAnchorEl(null);
  };
  const deleteCredentialPostAction = result => {
    setRenderIteration(i => i + 1);
    if (result) {
      snackbarService.popup({
        type: 'success',
        message: `Credential has been removed successfully!`
      });
    } else {
      snackbarService.popup({
        type: 'error',
        message: `There was an error removing the credential`
      });
    }
  };
  const handleDeleteCredential = credential => {
    //if the integration has a custom delete endpoint
    const deleteMap = {
      calltools: deleteCallToolsToken,
      adwords: deleteAdwordsAccount,
      foreup: deleteCourse,
      shopify: deleteShopifyShop
    };
    if (deleteMap[install.integration.key]) {
      deleteMap[install.integration.key](credential?.id).then(() => {
        deleteCredentialPostAction(true);
      }).catch(() => {
        deleteCredentialPostAction();
      });
    } else {
      deleteCredentialsAPI(credential.id).then(response => {
        deleteCredentialPostAction(true);
      }).catch(() => {
        deleteCredentialPostAction();
      });
    }
  };
  const handleDeleteInstall = () => {
    deleteInstalls(install.id).then(() => {
      history.push('/integrations', {
        removedIntegration: install?.integration?.name
      });
    }).catch(error => {
      snackbarService.popup({
        type: 'error',
        message: 'There was an error removing the integration.'
      });
    });
  };
  const handleSyncCredential = credential => {
    resyncCredential(credential?.id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Locations and reviews will start syncing momentarily'
      });
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error occured trying to resync credential'
      });
    });
  };
  const handleSyncAudiences = account => {
    syncAdwordsAudiences(account?.id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Queued audiences for syncing.'
      });
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying delete account. Please try again.'
      });
    });
  };
  const generateCredentialName = (credential, index) => {
    if (credential?.entity_data && install?.integration?.identifier_key && credential?.entity_data[install?.integration?.identifier_key]) {
      return credential.entity_data[install?.integration?.identifier_key];
    }
    if (credential?.entity_data && credential?.identifier && credential.entity_data[credential?.identifier]) {
      return credential.entity_data[credential?.identifier];
    }
    if (credential?.identifier) {
      return credential.identifier;
    }
    if (credential?.email && credential?.client_customer_id) {
      return credential.email + ' (' + credential.client_customer_id + ')';
    }
    if (credential?.email) {
      return credential.email;
    }
    if (credential?.client_customer_id) {
      return credential.client_customer_id;
    }
    if (credential?.location_name) {
      return credential.location_name;
    }
    if (credential?.name) {
      return credential.name;
    }
    return 'Credential: ' + (index + 1);
  };
  return <>
      <div className="wrapper" style={{
      height: loading ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Marketplace" name={<>
              {install?.integration?.name && install?.integration?.icon && <Stack direction="row" spacing={1} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
                  <Box sx={{
            pl: '8px'
          }}>
                    <img src={install?.integration?.icon} height="40" width="40" alt={install?.integration?.name} />
                  </Box>
                  <Box>{install?.integration?.name || ''}</Box>
                </Stack>}
            </>} icon={<Icon name="custom-call_merge" size={34} />} />

        <Box sx={{
        p: '20px',
        display: 'flex',
        justifyContent: 'center'
      }}>
          <Paper sx={{
          borderRadius: '14px',
          maxWidth: '1080px',
          width: '800px',
          minHeight: '60vh'
        }}>
            {loading ? <Box sx={{
            width: '100%',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
                  <CircularProgress />
                </Box>
              </Box> : <Stack sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
                <Stack sx={{
              py: '10px'
            }}>
                  <Stack>
                    <Box sx={{
                  px: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pb: '10px'
                }}>
                      <Stack direction="row" spacing="20px">
                        <Box>
                          {install?.integration?.icon ? <img src={install?.integration?.icon} height="40" width="40" alt={install?.integration?.name} /> : <DefaultIntegrationIcon />}
                        </Box>

                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                          <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '300',
                        lineHeight: '28px'
                      }}>
                            {'Manage ' + install?.integration?.name}
                          </Typography>
                        </Box>

                        <Stack direction={'row'} spacing={'24px'} sx={{
                      display: 'flex'
                    }}>
                          {user?.is_superuser && install?.integration?.status == 'private' && <Tooltip title="This integration is only shown for super users only">
                              <Box sx={{
                          py: '7px',
                          px: '10px',
                          borderRadius: '8px',
                          backgroundColor: '#C2E7FF',
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                <ShieldPersonIcon size="sm" fill="#3898D9" />
                              </Box>
                            </Tooltip>}
                        </Stack>
                      </Stack>

                      <Box>
                        <IconButton onClick={e => {
                      history.push(`/integrations`);
                    }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>

                  <Stack sx={{
                pt: !!install?.integration?.description || !!install?.integration?.credential_schema?.description ? '20px' : '0px'
              }}>
                    <Box>
                      <Stack>
                        {(!!install?.integration?.description || !!install?.integration?.credential_schema?.description) && <Box sx={{
                      px: '20px'
                    }}>
                            <Typography sx={{
                        fontSize: '18px',
                        fontWeight: '300',
                        lineHeight: '26px'
                      }}>Description</Typography>
                          </Box>}

                        {!!install?.integration?.description && <Box sx={{
                      pt: '20px',
                      pb: '30px',
                      px: '20px'
                    }}>
                            <Typography sx={{}}>{install?.integration?.description}</Typography>
                          </Box>}

                        {!install?.integration?.description && !!install?.integration?.credential_schema?.description && <Box sx={{
                      pt: '20px',
                      pb: '30px',
                      px: '20px'
                    }}>
                            <Typography sx={{}}>{install?.integration?.credential_schema?.description}</Typography>
                          </Box>}

                        {(!!install?.integration?.description || !!install?.integration?.credential_schema?.description) && <Divider />}
                        {install?.credentials.map((credential, index) => <Box key={credential?.id} sx={{
                      border: '1px solid #E2E2E2',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#F3F3F4',
                        boxShadow: 'none'
                      }
                    }} onClick={() => {
                      if (hasPermission('integration.view_credential')) {
                        history.push(`/integrations/configure/${install?.id}?credential=${credential?.id}`);
                      } else {
                        snackbarService.popup({
                          type: 'error',
                          message: 'You do not have permission to view credentials'
                        });
                      }
                    }}>
                            <Stack sx={{
                        px: '20px',
                        py: '17px'
                      }} direction="row" spacing="20px">
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                <Typography sx={{
                            fontSize: '18px'
                          }}>{generateCredentialName(credential, index)}</Typography>
                              </Box>
                              <Tooltip title="You have configured this credential. Click to edit it">
                                <Stack direction="row" spacing="8px" sx={{
                            p: '7px',
                            borderRadius: '8px',
                            backgroundColor: '#C5EAD7'
                          }}>
                                  <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                    <CheckCircleIcon size="sm" fill="#3EB87B" />
                                  </Box>
                                  <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              pr: '4px'
                            }}>
                                    <Typography sx={{
                                color: '#3EB87B'
                              }}>Configured</Typography>
                                  </Box>
                                </Stack>
                              </Tooltip>
                            </Stack>
                            <Box sx={{
                        pr: '20px'
                      }}>
                              {optionsMenu.length > 0 && <IconButton onClick={e => {
                          e.stopPropagation();
                          handleCredentialMenuClick(e, credential);
                        }}>
                                  <MoreVertIcon />
                                </IconButton>}
                            </Box>
                          </Box>)}
                        {!integrationsWithAutoStatusThatStillHaveDisplayComponents.includes(install?.integration?.key) && (install?.integration?.type == 'feature' || install?.status == 'auto') ? <Stack>
                            <Box sx={{
                        border: '1px solid #E2E2E2',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                              <Stack sx={{
                          px: '20px',
                          py: '25px'
                        }} direction="row" spacing="20px">
                                <Box>
                                  <Typography sx={{
                              fontSize: '14px'
                            }}>
                                    This integration was automatically configured and cannot be modified here. It is ready for use or may
                                    require configuration elsewhere.
                                  </Typography>
                                </Box>
                              </Stack>
                              <Box sx={{
                          pr: '20px'
                        }}></Box>
                            </Box>
                          </Stack> : <>
                            {hasPermission('integration.add_credential') ? <Stack>
                                <Box sx={{
                          border: '1px solid #E2E2E2',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: '#F3F3F4',
                            boxShadow: 'none'
                          }
                        }} onClick={() => {
                          history.push(`/integrations/configure/${install?.id}`);
                        }}>
                                  <Stack sx={{
                            px: '20px',
                            py: '25px'
                          }} direction="row" spacing="20px">
                                    <Box>
                                      <Typography sx={{
                                fontSize: '18px'
                              }}>
                                        {integrationsWithCustomCredentialName[install?.integration?.key] ? 'Add New ' + integrationsWithCustomCredentialName[install?.integration?.key] : 'Add New Credential'}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                  <Box sx={{
                            pr: '20px'
                          }}>
                                    <Tooltip title={integrationsWithCustomCredentialName[install?.integration?.key] ? 'Add New ' + integrationsWithCustomCredentialName[install?.integration?.key] : 'Add New Credential'}>
                                      <IconButton>
                                        <AddIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </Stack> : <Box sx={{
                        p: 2
                      }}>
                                <Typography> You do not have permission to add new credentials</Typography>
                              </Box>}
                          </>}
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
                <Stack>
                  <Divider />
                  <Box sx={{
                p: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                    {hasPermission('integration.delete_credential') ? <Button sx={{
                  backgroundColor: '#F3F3F4',
                  '&:hover': {
                    backgroundColor: '#F3F3F4'
                  }
                }} variant="contained" startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                  setOpenConfirmDeleteInstallModal(true);
                }}>
                        <Box sx={{
                    py: '5px',
                    pr: '12px'
                  }}>
                          <Typography sx={{
                      color: '#3898D9'
                    }}>Uninstall </Typography>
                        </Box>
                      </Button> : <Box></Box>}

                    <Stack direction="row" spacing="16px">
                      <Button sx={{
                    backgroundColor: '#F3F3F4',
                    '&:hover': {
                      backgroundColor: '#F3F3F4'
                    }
                  }} variant="contained" onClick={e => {
                    history.push(`/integrations`);
                  }}>
                        <Box sx={{
                      py: '5px',
                      px: '12px'
                    }}>
                          <Typography sx={{
                        color: '#3898D9'
                      }}>Cancel </Typography>
                        </Box>
                      </Button>
                      <Button sx={{
                    backgroundColor: '#3898D9',
                    '&:hover': {
                      backgroundColor: '#3898D9'
                    }
                  }} variant="contained" onClick={e => {
                    history.push(`/integrations`);
                  }}>
                        <Box sx={{
                      py: '5px',
                      px: '12px'
                    }}>
                          <Typography sx={{
                        color: '#fff'
                      }}> Done</Typography>
                        </Box>
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>}
          </Paper>
        </Box>
      </div>

      <Popper anchorEl={editExistingCredentialMenuAnchorEl} open={!!editExistingCredentialMenu} onClose={handleEditExistingCredentialMenuClose} placement="right-start" sx={{
      zIndex: 200
    }}>
        <ClickAwayListener onClickAway={handleEditExistingCredentialMenuClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          // width: '312px',
        }}>
            <Stack sx={{
            py: '8px'
          }}>
              {optionsMenu.map(item => <Button key={item.key} sx={{
              width: '100%',
              height: '48px',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '0 16px',
              ':hover': {
                backgroundColor: '#DAEFFF',
                color: '#53A6D6'
              }
            }} onClick={() => {
              item.onClick(editExistingCredentialMenu, install);
              handleEditExistingCredentialMenuClose();
            }}>
                  {item.icon}
                  <Typography sx={{
                textTransform: 'none',
                ml: '16px'
              }}>{item.label}</Typography>
                </Button>)}
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!showDeleteCredentialModal} onClose={(e, r) => {
      setShowDeleteCredentialModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Credential
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setShowDeleteCredentialModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            {integrationsWithCustomCredentialName[showDeleteCredentialModal?.integration] ? 'Are you sure you want to remove this ' + integrationsWithCustomCredentialName[showDeleteCredentialModal?.integration] + '?' : 'Are you sure you want to remove this credential?'}
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setShowDeleteCredentialModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteCredential(showDeleteCredentialModal);
            setShowDeleteCredentialModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteInstallModal} onClose={(e, r) => {
      setOpenConfirmDeleteInstallModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Uninstall Integration
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteInstallModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>{'Are you sure you want to uninstall ' + install?.integration?.name + '?'} </Box>
            {install?.credentials?.length > 0 && <Box>
                <div>
                  This will delete the credentials you have added for this integration and stop Cinch from receiving data on this
                  integration for your company
                </div>
              </Box>}
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteInstallModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteInstall();
            setOpenConfirmDeleteInstallModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Uninstall</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};