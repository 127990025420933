import { Container, Graphics, Rectangle } from 'pixi.js';
import { updateNode, createNode } from 'shared/common.api';
import { NodeDelete } from './node/node-delete';
import { NodeDuplicate } from './node/node-duplicate';
import { cloneDeep } from 'lodash';
import { Node } from './node/node';
import { MODES } from './node/node-utils';
let sx, sy;
export class MultiSelect {
  box = null;
  boxDuplicate = null;
  // boundingRect
  container = null;
  eventData = null;
  sizing = false;
  active = false;
  dragging = false;
  snapToGrid = true;
  trigger = () => {};
  appRef;
  textIntersections = [];
  nodeIntersections = [];
  x = 0;
  y = 0;
  width = 0;
  height = 0;
  nodeLibrary = [];
  constructor(trigger = () => {}, snapToGrid = true, appRef) {
    this.trigger = trigger;
    this.appRef = appRef;
    this.snapToGrid = snapToGrid;
    this.container = new Container();
    this.container.interactive = true;
    this.container.on('pointerdown', this.onDragStart).on('pointerup', this.onDragEnd).on('pointerupoutside', this.onDragEnd).on('pointermove', this.onDragMove);
  }
  setNodeLibrary(nodeLibrary) {
    this.nodeLibrary = nodeLibrary;
  }
  setSnapToGrid(snapToGrid) {
    this.snapToGrid = snapToGrid;
  }
  setTextIntersections(textIntersections) {
    this.textIntersections = textIntersections;
  }
  setNodeIntersections(nodeIntersections) {
    this.nodeIntersections = nodeIntersections;
  }
  onDragStart = event => {
    this.dragging = true;
    this.eventData = event.data;
    const position = this.eventData.getLocalPosition(this.container);
    sx = position.x - this.x;
    sy = position.y - this.y;
    this.trigger('MULTI_SELECT_START');
  };
  onDragEnd = event => {
    if (this.dragging) {
      this.dragging = false;
      if (this.textIntersections.length) {
        this.trigger('SAVE_TEXT', this);
      }
      this.nodeIntersections.forEach(i => {
        if (this.snapToGrid) {
          const containerInX = Math.round(i.container.x / 25) * 25;
          const containerInY = Math.round(i.container.y / 25) * 25;
          i.container.x = containerInX;
          i.container.y = containerInY;
          this.resizeToIntersections(true);
        }
        if (i.id) {
          updateNode(i.id, {
            x: i.container.x,
            y: i.container.y
          });
        }
      });
    }
  };
  onDragMove = () => {
    if (this.dragging) {
      const newPosition = this.eventData.getLocalPosition(this.container);
      let newX = newPosition.x - sx;
      let newY = newPosition.y - sy;
      let diffX = this.x - newX;
      let diffY = this.y - newY;
      if (newX === 0 && newPosition.x > 0) {
        sx = newPosition.x;
      }
      if (newY === 0 && newPosition.y > 0) {
        sy = newPosition.y;
      }
      this.x = newX;
      this.y = newY;
      const update = i => {
        i.container.x -= diffX;
        i.container.y -= diffY;
        this.trigger('UPDATE_NODE_LINKS', i);
      };
      this.textIntersections.forEach(update);
      this.nodeIntersections.forEach(update);
      this.delete.container.x = this.box.hitArea.x - 24;
      this.delete.container.y = this.box.hitArea.y - 24;
      this.duplicate.container.x = this.box.hitArea.x - 24;
      this.duplicate.container.y = this.box.hitArea.y + this.height;
      this.draw();
    }
  };
  selectEnd = () => {
    if (this.textIntersections.length + this.nodeIntersections.length <= 1) {
      // We didn't find 2 or more intersections so nothing to do
      this.stop();
    } else if (this.sizing) {
      // We dragged so we're done sizing and we can now start moving.
      this.sizing = false;
      this.resizeToIntersections(false);
    }
  };
  start = (x, y) => {
    this.active = true;
    this.sizing = true;
    this.x = x;
    this.y = y;
  };
  setSize = (width, height) => {
    this.width = width;
    this.height = height;
    this.draw();
  };
  stop = () => {
    this.active = false;
    this.sizing = false;
    this.dragging = false;
    this.textIntersections = [];
    this.nodeIntersections = [];
    this.x = 0;
    this.y = 0;
    this.width = 0;
    this.height = 0;
    this.clear();
  };
  clear = () => {
    if (this.box) {
      this.box.destroy();
      this.box = null;
    }
    if (this.delete) {
      this.delete.container.destroy();
      this.delete = null;
    }
    if (this.duplicate) {
      this.duplicate.container.destroy();
      this.duplicate = null;
    }
    this.container.removeChildren();
  };
  onDelete = () => {
    this.nodeIntersections.forEach(node => {
      this.trigger('REMOVE_NODE', node);
      if (node.nodeValid.container.alpha === 1) {
        // Node was invalid but we're deleting it so bye bye
        this.trigger('VALID_NODE', node);
      }
    });
    this.textIntersections.forEach(text => this.trigger('REMOVE_TEXT', text));
    this.stop();
  };
  onDuplicate = async () => {
    let newNodes = await Promise.all(this.nodeIntersections.map(async n => {
      const position = {
        x: n.container.x + 125,
        y: n.container.y
      };
      const item = cloneDeep(this.nodeLibrary.find(node => !n.templateId && node.subType === n.subType || n.templateId && node.templateId === n.templateId) || {});
      item.defaultParameters = cloneDeep(item.parameters);
      item.parameters = n.parameters || (item.initParams ? item.initParams() : item.parameters);
      const node = new Node(item, position, false, MODES.CANVAS, this.snapToGrid, this.trigger);
      this.appRef.nodesContainer.addChild(node.container);
      let newNode = await createNode(node.type, node.subType, node.templateId, node.container.x, node.container.y, this.appRef.revision.id, node.parameters);
      node.id = newNode.data.id;
      node.duplicateFrom = n.id;
      this.appRef.nodes.push(node);
      return node;
    }));
    this.appRef.nodes.forEach(node => {
      node.shape.children[0].alpha = 1;
    });
    this.appRef.links.forEach(link => {
      let fromNode;
      let toNode;
      let initNode;
      newNodes.forEach(n => {
        initNode = n;
        if (n.duplicateFrom === link.nodeFrom.id) {
          fromNode = n;
        }
        if (n.duplicateFrom === link.nodeTo.id) {
          toNode = n;
        }
      });
      if (fromNode && toNode) {
        this.appRef.addLink(fromNode, toNode, null, link.onEvent, initNode.onEventList, initNode.getEvent, initNode.getEventList);
        fromNode = null;
        toNode = null;
      }
    });
    const newTextIntersections = [];
    this.textIntersections.forEach(text => {
      const newText = this.appRef.addText(text.parameters.text, {
        x: text.container.x + 150,
        y: text.container.y
      });
      newTextIntersections.push(newText);
    });
    this.nodeIntersections = newNodes;
    this.textIntersections = newTextIntersections;
    this.resizeToIntersections();
    this.trigger('SAVE_TEXT', this);
  };
  draw = () => {
    this.clear();
    this.box = new Graphics();
    this.box.lineStyle(1, 0x333333);
    this.box.drawRect(this.x, this.y, this.width, this.height);
    this.box.hitArea = new Rectangle(this.x, this.y, this.width, this.height);
    if (!this.sizing) {
      this.delete = new NodeDelete(() => this.onDelete());
      this.delete.container.x = this.box.hitArea.x - 24;
      this.delete.container.y = this.box.hitArea.y - 24;
      this.delete.container.alpha = 1;
      this.container.addChild(this.delete.container);
      this.container.addChild(this.box);
      this.duplicate = new NodeDuplicate(() => this.onDuplicate());
      this.duplicate.container.x = this.box.hitArea.x - 24;
      this.duplicate.container.y = this.box.hitArea.y + this.height;
      this.duplicate.container.alpha = 1;
      this.container.addChild(this.duplicate.container);
      this.container.addChild(this.box);
    } else {
      this.container.addChild(this.box);
    }
  };
  resizeToIntersections = isDragEnd => {
    const buffer = 10;
    let x1, y1, x2, y2;
    const offset = {
      x: this.container.parent.x,
      y: this.container.parent.y
    };
    const scale = this.container.parent.scale;
    offset.x /= scale.x;
    offset.y /= scale.y;
    const resize = intersection => {
      let bounds = intersection.container.getBounds();
      bounds.x /= scale.x;
      bounds.y /= scale.y;
      bounds.width /= scale.x;
      bounds.height /= scale.y;
      let ix1 = bounds.x - offset.x - buffer;
      let iy1 = bounds.y - offset.y - buffer;
      let ix2 = bounds.x - offset.x + bounds.width + buffer;
      let iy2 = bounds.y - offset.y + bounds.height + buffer;
      if (x1 === undefined || ix1 < x1) x1 = ix1;
      if (y1 === undefined || iy1 < y1) y1 = iy1;
      if (x2 === undefined || ix2 > x2) x2 = ix2;
      if (y2 === undefined || iy2 > y2) y2 = iy2;
    };
    this.textIntersections.forEach(resize);
    this.nodeIntersections.forEach(resize);
    this.x = x1;
    this.y = y1;
    this.width = x2 - x1;
    this.height = y2 - y1;
    this.draw();
  };
}