import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * A custom MUI Button Component that is used to confirm/cancel an action.
 *
 * @param {string} size - The size of the button. Default is "medium"; options are "small", "medium", "large".
 *
 * @param {function} onClick - The action that the button will perform on click.
 *
 * @param {object} beforeIcon - The icon that will appear before the text.
 *
 * @param {string} text - The text displayed on the button.
 *
 * @param {object} afterIcon - The icon that will appear after the text.
 *
 * @param {boolean} disabled - If the button is disabled.
 *
 * @param {boolean} isCancel - The type of button. Default is confirm, pass true for cancel.
 *
 *@param {function} onClick - The action that the button will perform on click.
 *
 */
export const CinchActionButton = props => {
  const [hoverActive, setHoverActive] = useState(false);
  useEffect(() => {
    setHoverActive(false);
  }, [props]);
  return <>
      <Button variant={'contained'} size={props.size || 'medium'} sx={{
      backgroundColor: props.isCancel ? '#F3F3F4' : '#53A6D6',
      borderRadius: '28px'
    }} disabled={props.disabled || false} onMouseEnter={() => setHoverActive(true)} onMouseLeave={() => setHoverActive(false)} onClick={e => props.onClick(e)}>
        <Typography sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: props.isCancel ? '#53A6D6' : '#F3F3F4',
        ':hover': {
          color: props.isCancel ? '#53A6D6' : '#F3F3F4'
        },
        textTransform: 'none'
      }}>
          {props.beforeIcon && <Box sx={{
          pr: 1
        }}>{props.beforeIcon}</Box>}
          <Box>{props.text || 'Confirm'}</Box>
          {props.afterIcon && <Box sx={{
          pl: 1
        }}> {props.afterIcon}</Box>}
        </Typography>
      </Button>
    </>;
};

// storybook stuff
CinchActionButton.propTypes = {
  /** The size of the button. Default is "medium"; options are "small", "medium", "large". */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** The action that the button will perform on click. */
  onClick: PropTypes.func,
  /** The icon that will appear before the text. */
  beforeIcon: PropTypes.element,
  /** The text displayed on the button. */
  text: PropTypes.string,
  /** The icon that will appear after the text. */
  afterIcon: PropTypes.element,
  /** If the button is disabled. True to disable */
  disabled: PropTypes.bool,
  /** The style is cancel type. Default is false */
  isCancel: PropTypes.oneOf([true, false])
};
CinchActionButton.defaultProps = {
  size: 'medium',
  beforeIcon: null,
  afterIcon: null,
  disabled: false,
  isCancel: null
};