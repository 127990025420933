import React, { Component } from 'react';
import { func, object, shape, number } from 'prop-types';
import { isInteger } from 'lodash';
import Button from '@mui/material/Button';
import { Scoped, k } from 'kremling';
import { v4 as uuid } from 'uuid';
import { DeleteIcon } from 'components/mui';
import { IconButton } from '@mui/material';
export class ActionABTesting extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      groupCounter: 'C' // letter for each new group A,B,C etc.
    };
  }
  removeGroup = group => {
    if (this.props.data.groups.length < 3) {
      return;
    }
    const groups = this.props.data.groups.filter(g => g !== group);
    this.props.update({
      groups
    });
  };
  updateName = (group, newName) => {
    group.name = newName.toUpperCase().replace(/[\W_]+/g, ' ');
    this.props.update({
      groups: this.props.data.groups
    });
  };
  updatePercentage = (group, newPercentage) => {
    newPercentage = parseInt(newPercentage);
    if (!isInteger(newPercentage)) {
      newPercentage = 0;
    } else if (newPercentage < 0 || newPercentage > 100) {
      return;
    }
    group.percentage = newPercentage;
    this.props.update({
      groups: this.props.data.groups
    });
  };
  handleCreateClick = () => {
    const groups = this.props.data.groups || [];
    groups.push({
      id: uuid(),
      name: groups.length === 0 ? 'GROUP A' : groups.length === 1 ? 'GROUP B' : 'GROUP ' + this.state.groupCounter,
      percentage: groups.length < 2 ? 50 : 0
    });
    this.props.update({
      groups
    });
    if (groups.length > 2) {
      this.setState({
        groupCounter: this.incrementGroupCounter(this.state.groupCounter)
      });
    }
  };
  incrementGroupCounter = counter => {
    // start from single letter alphabet, then double and etc.
    // if they close the modal and then reopen, currently it will start over from counter 'C'
    // idk why anybody would want this many groups but the option is there :)
    let charCodes = counter.split('').map(char => char.charCodeAt(0));
    for (let i = charCodes.length - 1; i >= 0; i--) {
      if (charCodes[i] < 90) {
        charCodes[i]++;
        break;
      } else {
        charCodes[i] = 65;
        if (i === 0) {
          charCodes.unshift(65);
        }
      }
    }
    return String.fromCharCode(...charCodes);
  };
  checkError = () => {
    const groups = this.props.data.groups || [];
    if (groups.length < 2) {
      return <div className="text-danger" style={{
        marginBottom: '10px'
      }}>
          You must have at least 2 groups
        </div>;
    }
    let groupNames = [];
    for (let i in groups) {
      if (groupNames.includes(groups[i].name)) {
        return <div className="text-danger" style={{
          marginBottom: '10px'
        }}>
            Group names must be unique
          </div>;
      }
      groupNames.push(groups[i].name);
    }
    const sum = groups.reduce((s, group) => s + group.percentage, 0);
    if (sum !== 100) {
      return <div className="text-danger" style={{
        marginBottom: '10px'
      }}>
          Group percentages must add up to 100%
        </div>;
    }
    return null;
  };
  render() {
    return <Scoped css={css}>
        <table style={{
        width: '100%',
        marginBottom: '10px'
      }}>
          <thead>
            <tr>
              <th>Group</th>
              <th>%</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {!!this.props.data.groups && this.props.data.groups.map(group => <tr key={group.id}>
                  <td>
                    <input type="text" className="form-control" value={group.name} onChange={e => this.updateName(group, e.target.value)} />
                  </td>
                  <td>
                    <input type="text" className="form-control" value={group.percentage} onChange={e => this.updatePercentage(group, e.target.value)} />
                  </td>
                  <td>
                    <IconButton onClick={e => this.removeGroup(group)}>
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>)}
          </tbody>
        </table>

        {this.checkError()}

        <Button variant="text" fullWidth sx={{
        cursor: 'pointer',
        height: '48px',
        textTransform: 'none',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }} disabled={this.props.data.groups && this.props.data.groups.length >= 26} onClick={() => {
        this.handleCreateClick();
      }}>
          + Add Additional Group
        </Button>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i29"] body,body[kremling="i29"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i29"] .ab-close-group,[kremling="i29"].ab-close-group {
  display: flex;
  width: 200px;
  margin: 0 auto;
}

[kremling="i29"] .ab-close-group>div,[kremling="i29"].ab-close-group>div {
  margin: 14px;
}

[kremling="i29"] .ab-close-group__inner,[kremling="i29"].ab-close-group__inner {
  display: flex;
  align-items: center;
}

[kremling="i29"] .ab-close-group__inner input,[kremling="i29"].ab-close-group__inner input {
  margin-right: 8px;
  text-align: right;
}`,
  id: 'i29',
  namespace: 'kremling'
};