import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * A custom MUI Button Component that is used to confirm/cancel an action.
 *
 * @param {string} size - The size of the button. Default is "medium"; options are "small", "medium", "large". .
 *
 * @param {function} onClick - The action that the button will perform on click.
 *
 * @param {string} text - The text displayed on the button.
 *
 * @param {boolean} disabled - If the button is disabled.
 *
 *
 */
export const CinchActionButtonText = props => {
  const [hoverActive, setHoverActive] = useState(false);
  useEffect(() => {
    setHoverActive(false);
  }, [props]);
  return <>
      <Button variant={'text'} size={props.size || 'medium'} sx={{
      borderRadius: '28px',
      ':hover': {
        backgroundColor: '#53A6D6'
      },
      textTransform: 'none'
    }} disabled={props.disabled || false} onClick={e => props.onClick(e)} onMouseEnter={() => setHoverActive(true)} onMouseLeave={() => setHoverActive(false)}>
        <Typography sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
        color: hoverActive ? '#fff' : '#53A6D6',
        textTransform: 'none'
      }}>
          <Box>{props.text || 'Confirm'}</Box>
        </Typography>
      </Button>
    </>;
};

// storybook stuff
CinchActionButtonText.propTypes = {
  /** The size of the button. Default is "medium"; options are "small", "medium", "large". */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** The action that the button will perform on click. */
  onClick: PropTypes.func,
  /** The text displayed on the button. */
  text: PropTypes.string.isRequired,
  /** If the button is disabled. True to disable */
  disabled: PropTypes.bool
};
CinchActionButtonText.defaultProps = {
  size: 'medium',
  disabled: false
};