import React, { useContext, useState, useEffect, createElement } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { getInstall } from '../integrations.resource';
import { Icon, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton, CircularProgress } from '@mui/material';
import { CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, ShieldPersonIcon } from 'components/mui';
import { history } from '../../../root.component';
import { IntegrationZapier } from '../integration-zapier.component';
import { IntegrationOilChangeCalculator } from '../integration-oilchange-calculator.component';
import { IntegrationContact } from '../integration-contact.component';
import { IntegrationIsi } from '../integration-isi.component';
import { IntegrationSchemaForm } from './integrations-schema-form.component';
import { useLocation } from 'react-router-dom';
import { IntegrationCallToolsMui } from './integration-calltools-mui.component';
import { IntegrationJiffyLubeUpdate } from './integration-jiffylube-update.component';
import { IntegrationForeUPUpdate } from './integration-foreup-update.component';
import { IntegrationAdwordsUpdate } from './integration-adwords-update.component';
import { IntegrationGoogleBusinessUpdate } from './integration-google-business-update.component';
import { IntegrationGoogleFormsUpdate } from './integration-google-forms-update.component';
import { IntegrationLinkPOSUpdate } from './integration-linkpos-update.component';
import { IntegrationMindBodyUpdate } from './integration-mindbody-update.component';
import { IntegrationNewStoreUpdate } from './integration-newstore-update.component';
import { IntegrationOxiFreshUpdate } from './integration-oxifresh-update.component';
import { IntegrationPestRoutesUpdate } from './integration-pestroutes-update.component';
import { IntegrationShopifyUpdate } from './integration-shopify-update.component';
import { IntegrationWoocommerceUpdate } from './integration-woocommerce-update.component';
import { IntegrationLoyaltyMui } from './integration-loyalty-mui.component';
import { IntegrationAutoOpsUpdate } from './integration-autoops-update.component';
import { IntegrationCarfaxReviews } from './integration-carfax-reviews.component';
const OLD_INTEGRATION_MAP = {
  google: IntegrationGoogleFormsUpdate,
  isi: IntegrationIsi,
  jiffylube: IntegrationJiffyLubeUpdate,
  linkpos: IntegrationLinkPOSUpdate,
  mindbody: IntegrationMindBodyUpdate,
  newstore: IntegrationNewStoreUpdate,
  oilchange: IntegrationOilChangeCalculator,
  oxifresh: IntegrationOxiFreshUpdate,
  pestroutes: IntegrationPestRoutesUpdate,
  woocommerce: IntegrationWoocommerceUpdate,
  zapier: IntegrationZapier,
  contact: IntegrationContact
};

//new custom integrations
const INTEGRATION_MAP = {
  calltools: IntegrationCallToolsMui,
  loyalty: IntegrationLoyaltyMui,
  google_business: IntegrationGoogleBusinessUpdate,
  carfax_reviews: IntegrationCarfaxReviews,
  adwords: IntegrationAdwordsUpdate,
  foreup: IntegrationForeUPUpdate,
  shopify: IntegrationShopifyUpdate,
  autoops: IntegrationAutoOpsUpdate
};
const largeModalIntegrations = ['google_business'];
export const IntegrationsConfigureCredential = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [install, setInstall] = useState(null);
  const [credential, setCredential] = useState(null);
  const loc = useLocation();
  const urlParams = new URLSearchParams(loc.search);
  const [renderIteration, setRenderIteration] = useState(0);
  useEffect(() => {
    if (!user || !asCompany?.id || !props.match.params.id) {
      return;
    }
    if (props.match.params.id == 'facebook') {
      setInstall({
        integration: {
          key: 'facebook',
          name: 'Facebook'
        },
        integration: {
          name: 'Facebook',
          key: 'facebook',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII='
        },
        credentials: []
      });
      setLoading(false);
      return;
    }
    if (props.match.params.id == 'facebook_reviews') {
      setInstall({
        integration: {
          key: 'facebook_reviews',
          name: 'Facebook Reviews'
        },
        integration: {
          name: 'Facebook Reviews',
          key: 'facebook_reviews',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII='
        },
        credentials: []
      });
      setLoading(false);
      return;
    }
    setLoading(true);
    getInstall(props.match.params.id).then(install => {
      if (urlParams.has('credential')) {
        setCredential(install.data.credentials.find(c => c.id == urlParams.get('credential')));
      }
      setInstall(install.data);
      setLoading(false);
    });
  }, [hasPermission, asCompany?.id, renderIteration]);
  const generateCredentialName = () => {
    if (props.match.params.id == 'facebook') {
      return 'Configure Facebook';
    }
    if (OLD_INTEGRATION_MAP[install?.integration.key]) {
      return 'Configure ' + install.integration.name;
    }
    if (credential?.entity_data && install?.integration?.identifier_key && credential?.entity_data[install?.integration?.identifier_key]) {
      return 'Configure ' + install?.integration?.name + ': ' + credential.entity_data[install?.integration?.identifier_key];
    }
    if (credential?.entity_data && credential?.identifier && credential.entity_data[credential?.identifier]) {
      return 'Configure ' + install?.integration?.name + ': ' + credential.entity_data[credential?.identifier];
    }
    if (credential?.identifier) {
      return 'Configure ' + install.integration.name + ' : ' + credential.identifier;
    }
    if (credential?.email && credential?.client_customer_id) {
      return 'Configre ' + credential.email + ' (' + credential.client_customer_id + ')';
    }
    if (credential?.email) {
      return 'Configre ' + credential.email;
    }
    if (credential?.client_customer_id) {
      return 'Configre ' + credential.client_customer_id;
    }
    if (credential?.location_name) {
      return 'Configure ' + credential.location_name;
    }
    if (credential) {
      let pos = install.credentials.map(e => e.id).indexOf(credential.id);
      return 'Configure ' + install?.integration?.name + ': Credential ' + (pos + 1);
    }
    return 'Configure ' + install?.integration?.name + ': New Credential';
  };
  return <>
      <div className="wrapper" style={{
      height: loading ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Integrations and Apps" icon={<Icon name="custom-call_merge" size={34} />} />

        <Box sx={{
        p: '20px',
        display: 'flex',
        justifyContent: 'center'
      }}>
          <Paper sx={{
          borderRadius: '14px',
          maxWidth: install?.integration?.key == 'oilchange' ? null : '1080px',
          minWidth: '600px',
          width: install?.integration?.key == 'oilchange' ? null : largeModalIntegrations.includes(install?.integration?.key) ? '1000px' : '800px',
          minHeight: loading ? '60vh' : 'none'
        }}>
            {loading ? <Box sx={{
            width: '100%',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
                  <CircularProgress />
                </Box>
              </Box> : <Stack sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
                <Stack sx={{
              py: '10px'
            }}>
                  <Stack>
                    <Box sx={{
                  px: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pb: '10px'
                }}>
                      <Stack direction="row" spacing="20px">
                        <Box>
                          {install?.integration?.icon ? <img src={install?.integration?.icon} height="40" width="40" alt={install?.integration?.name} /> : <DefaultIntegrationIcon />}
                        </Box>

                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                          <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '300',
                        lineHeight: '28px'
                      }}>
                            {generateCredentialName()}
                          </Typography>
                        </Box>

                        {credential && <Tooltip title="You have configured this credential">
                            <Stack direction="row" spacing="8px" sx={{
                        p: '7px',
                        borderRadius: '8px',
                        backgroundColor: '#C5EAD7'
                      }}>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                <CheckCircleIcon size="sm" fill="#3EB87B" />
                              </Box>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pr: '4px'
                        }}>
                                <Typography sx={{
                            color: '#3EB87B'
                          }}>Configured</Typography>
                              </Box>
                            </Stack>
                          </Tooltip>}

                        <Stack direction={'row'} spacing={'24px'} sx={{
                      display: 'flex'
                    }}>
                          {user?.is_superuser && install?.integration?.status == 'private' && <Tooltip title="This integration is only shown for super users only">
                              <Box sx={{
                          py: '7px',
                          px: '10px',
                          borderRadius: '8px',
                          backgroundColor: '#C2E7FF',
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                <ShieldPersonIcon size="sm" fill="#3898D9" />
                              </Box>
                            </Tooltip>}
                        </Stack>
                      </Stack>

                      <Box>
                        <IconButton onClick={e => {
                      history.push(`/integrations`);
                    }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>

                  <Stack sx={{
                px: '20px',
                pt: '20px',
                pb: '10px'
              }}>
                    {!!install?.integration?.credential_schema ? <>
                        {!!credential ? <>
                            <IntegrationSchemaForm schema={install.integration.credential_schema} formData={credential.entity_data} install={install} credential={credential} />
                          </> : <>
                            <IntegrationSchemaForm schema={install.integration.credential_schema} install={install} />
                          </>}
                      </> : <Stack>
                        <Box>
                          {/* New Old style integrations with custom tables */}
                          {install?.integration?.key && INTEGRATION_MAP[install.integration.key] && createElement(INTEGRATION_MAP[install.integration.key], {
                      credential: credential,
                      install: install,
                      match: props.match,
                      location: props.location,
                      history: history
                    })}
                        </Box>

                        <Box>
                          {/* Old style integrations with custom tables */}
                          {install?.integration?.key && OLD_INTEGRATION_MAP[install.integration.key] && createElement(OLD_INTEGRATION_MAP[install.integration.key], {
                      credentials: install.credentials,
                      key: install.integration,
                      match: props.match,
                      location: props.location,
                      history: history,
                      reload: () => {
                        setRenderIteration(r => r + 1);
                      }
                    })}
                        </Box>
                        {/*
                         {install.integration.key == 'facebook' && (
                          <Box>
                            <IntegrationFacebookUpdate match={props.match} location={props.location} history={history} />
                          </Box>
                         )} */}
                      </Stack>}
                  </Stack>
                </Stack>
              </Stack>}
          </Paper>
        </Box>
      </div>
    </>;
};