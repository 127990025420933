import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

/**
 * Leverages MUI-x and works in tandem with formik.
 *
 * If you prefer the component to render empty, provide it with any of the default values:
 * ```js
 * '', null, undefined
 * ```
 *
 * If you prefer the component render with a pre-selected value, pass it in a typical ISO-8601 string.  It can
 * have any parts and should be flexible on what it can parse.
 *
 * `YYYY-MM-DDTHH:mm:ss` Is preferred and probably easiest to work with.
 * This component (and the Mui-x library) mostly functions with dayjs, so it's easiest to instantiate a new
 * dayjs object and pass it in as a default to keep things smooth.
 *
 * If you want to set a default value to the date, the datepicker was designed around using dayjs
 * which (to my understanding) is a minimized version of moment.
 *
 * The format returned as a formik value will be in form: `YYYY-MM-DDTHH:mm:ss` datetime naive so
 * it plays nicely with our other components
 */
export function FormikDateTimePicker({
  name,
  validate,
  label,
  onChange,
  disablePast = false,
  helperText
}) {
  const [dateValue, setDateValue] = useState();
  const {
    initialValues
  } = useFormikContext();
  useEffect(() => {
    const keyExists = initialValues.hasOwnProperty(name);
    const startingValue = initialValues[name];
    if (!keyExists) {
      console.warn('FormikDateTimePicker is misconfigured, you must provide the correct name prop');
      setDateValue(null);
    } else if (!startingValue) {
      setDateValue(null);
    } else {
      setDateValue(dayjs(startingValue));
    }
    if (onChange) {
      onChange(startingValue);
    }
  }, []);
  const updateValue = async (date, form) => {
    setDateValue(date);
    await form.setFieldValue(name, date, true);
    if (onChange) {
      onChange(date);
    }
  };
  return <Field name={name} validate={validate}>
      {({
      field,
      // { name, value, onChange, onBlur }
      form,
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      meta,
      isSubmitting
    }) => <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker label={label} value={dateValue} onChange={async value => {
          if (!value) {
            await updateValue(value, form);
            return;
          }
          await updateValue(value, form);
        }} slotProps={{
          textField: {
            helperText: meta.touched && meta.error ? meta.error : helperText,
            error: meta.touched && meta.error ? true : false
          }
        }} disablePast={disablePast} disabled={isSubmitting} />
          </LocalizationProvider>
        </>}
    </Field>;
}